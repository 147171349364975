/* .player-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  margin-bottom: 50px;
} */

.center-heading-tutorials {
  padding-top: 4rem;
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  color: #222726;
  text-align: center;
  margin-bottom: 1em;
}
.player-container {
  margin: auto;
  border-radius: 20px;
  overflow: hidden;
  width: 90%;
  height: auto;
  margin-bottom: 80px;
  max-width: 1080px;
}

.react-player {
  padding-top: 56.25%;
  position: relative;
}
.react-player > div {
  position: absolute;
  top: 0;
}

.youtube-grid {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  width: 95%;
  list-style: none;
  padding: 0;
  margin: auto;
  margin-bottom: 40px;
}

.youtube-grid img {
  max-width: 90%;
}

.youtube-grid > .card {
  margin: 1rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: none;
  background-color: white;
  margin: 0;
  padding: 0;
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.youtube-grid > .card > .card-title {
  color: #222726;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #222726;
  transition: color 0.5s;
}

.youtube-grid > .card:hover,
.youtube-grid > .card:focus,
.youtube-grid > .card:active {
  background-color: lightgray;
  transition: background-color 0.3s ease;
}
