
.termsCondTop> .navbar{
    /* background: linear-gradient(0deg,#00A0A3,#D15F5B); */
    background-color:  #222726;

}

@media (max-width:991px) {
    .termsCondTop> .navbar{
        /* background: linear-gradient(0deg,#00A0A3,#D15F5B); */
        background-color:  #222726 !important;
        background:red;
    
    }
    .termsCondTop >.navbar > .navbar-collapse  {
    background-color:  #222726 !important;
    background: red;

    }  
    
}