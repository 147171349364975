.btnOrderNowTab {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
}

.btnOrderNowTab:hover {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  color: #ffffff;
}

.tabMonoContainer {
  background-color: #222726;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.tabMonoFixed {
  position: fixed;
  top: 0;
  z-index: 9;
}

.tabTxtMonoTitle {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 20px;
  margin-right: 40px;
  /* identical to box height, or 150% */

  /* CTA (orange) */

  color: #ffffff !important;
  margin-bottom: 0px;
}

.rightIconPartMono {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0px;
  padding-left: 25px;
  align-items: flex-end;
  padding-right: 30px;
}

.tabTxtMono {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 12px;
  margin-right: 12px;
  text-decoration: none;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 0 !important;
  /* identical to box height, or 150% */

  /* CTA (orange) */

  color: #ffffff !important;
}
.txtTabMonoMain {
  display: flex;

  padding-left: 62px;
  justify-content: space-between;
  /* width: 1121px; */
  width: 64%;
}
.buttonMainOrderNow {
  padding-right: 30px;
}
.tabTxtMono.color {
  color: #ff4203 !important;
}

@media (max-width: 1200px) {
  .tabTxtMono {
    font-size: 13px;
  }
}

@media (max-width: 1080px) {
  .tabTxtMono {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .tabMonoFixed {
    top: auto;
    bottom: 0;
    z-index: 9;
    background-color: transparent;
    box-shadow: none;
    backdrop-filter: none;
  }

  .rightIconPartMono {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0px;
    padding-left: 25px;
    align-items: flex-end;
    padding-right: 30px;
    margin: auto;
  }
  .btnOrderNowTab {
    box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  }
}
