/* @import '~video-react/styles/scss/video-react';
@import "~video-react/styles/scss/video-react";  */

.mainVideoApplyMod > .video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  width: 611px !important;
  /* width: 800%; */
  height: 422px !important;
  padding: 0px !important;
  border-radius: 20px;
}

.mainVideoApplyMod > .video-react-icon-play-arrow:before,
.video-react .video-react-bezel .video-react-bezel-icon-play:before,
.video-react .video-react-play-control:before,
.video-react .video-react-big-play-button:before {
  background-color: #ff4203;
  /* height: 110px;
    width: 110px; */
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainVideoApplyMod > .video-react:hover .video-react-big-play-button,
.video-react .video-react-big-play-button:focus {
  border: none;
  background: transparent;
}

.paraApplyModTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 1.2em;
  /* or 107% */

  /* Light mode (dark grey) */

  color: #222726;
}

.txtPartApplyMod {
  width: 406px;
  height: 288px;
  margin-right: 8vw;
}

.mainContainerApplyingMod {
  display: flex;
  justify-content: flex-end;
  background-color: #ffffff;
  width: 85%;
  max-width: 1700px;
  height: 520px;
  padding-top: 50px;
  margin-bottom: 20px;
}
.picPartUserAppMod {
  display: flex;
}
.nameApplyModHolder {
  padding-left: 25px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Light mode (dark grey) */

  color: #222726;
}
.spCountryAppMod {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Light mode (dark grey) */

  color: #222726;
}
.mainVideoApplyMod {
  position: relative;
  left: 130px;
}
.mainApplyModule {
  width: 100%;
}
.video-react-button {
  border: none !important;
  background: none !important;
}

.video-react .video-react-control:before,
.video-react .video-react-big-play-button:before {
  width: 108%;
  height: 167%;
}
.video-react .video-react-control-bar {
  /* bottom: 20px; */
  padding-bottom: 10px;
  border-radius: 20px;
}
.video-react .video-react-big-play-button.video-react-big-play-button-center {
  top: 41%;
  left: 50%;
}

.video-js .vjs-big-play-button {
  width: 100px !important;
  height: 100px !important;
  border: none !important;
  background-color: #ff4203 !important;
  border-radius: 100% !important;
}
.vjs-icon-play:before,
.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before {
  /* margin-top: 25px; */

  /* font-size: 55px; */
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.vjs-button > .vjs-icon-placeholder:before {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}
.vjs-big-play-centered .vjs-big-play-button {
  top: 43% !important;
}
.video-js {
  border-radius: 20px !important;
}
.video-js .vjs-tech {
  border-radius: 20px !important;
}
.vjs-poster {
  border-radius: 20px;
}
.vjs-has-started .vjs-control-bar {
  border-radius: 20px;
}

@media (max-width: 1360px) {
  .txtPartApplyMod {
    margin-right: -60px;
  }
  .mainVideoApplyMod > .video-js > .vjs-poster {
    width: 100%;
  }
}

@media (max-width: 1295px) {
  .paraApplyModTxt {
    font-size: 30px;
  }
  .txtPartApplyMod {
    margin-left: 30px;
    height: auto;
  }
}

@media (max-width: 1083px) {
  .paraApplyModTxt {
    font-size: 30px;
    line-height: 39px;
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  .mainContainerApplyingMod {
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    padding-bottom: 40px;
  }
  .mainVideoApplyMod {
    position: unset;
  }
  .mainVideoApplyMod {
    width: 90%;
  }
  .mainVideoApplyMod > .video-react.video-react-fluid,
  .video-react.video-react-16-9,
  .video-react.video-react-4-3 {
    width: 100% !important;
  }
  .txtPartApplyMod {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .mainVideoApplyMod > .video-js {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .txtPartApplyMod {
    margin-right: 0px;
  }
}
@media (max-width: 450px) {
  .txtPartApplyMod {
    width: auto;
  }
  .paraApplyModTxt {
    padding-right: 10px;
  }
}
