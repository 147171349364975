.ThreeDMorphicContainer {
  /* background-image: url("./../../images/3DBackground.png");
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat; */
  /* padding-bottom: 40px; */
  position: relative;
}

.leftImg3MorPro {
  position: relative;
  width: 80%;
  max-width: 1200px;
}
.leftImg3Mor {
  width: 100%;
  max-width: 600px;
  object-fit: contain;
  margin-right: auto;
  margin-left: auto;
}
.innerDataContainer3D {
  padding-top: 119px;
  padding-left: 35px;
}

.d3MorphingTxt {
  font-family: "Chillax", sans-serif;
  line-height: 2em;
  font-weight: 400;
  font-size: 25px;
  color: #ffffff;
  padding-left: 10px;
  margin-bottom: 50px;
}
.tmTxt {
  position: absolute;
  margin-top: -13px;
  margin-top: -10px;
  font-size: 10px;
  margin-left: -20px;
}

.rightPartMorph {
  padding-top: 25px;
  font-size: 115px;
  color: #ffffff;
  font-family: "Chillax", sans-serif;
  font-style: normal;
  line-height: 0.4em;
  margin-bottom: 50px;
}

.txtNorand {
  font-weight: 200;
}
.txtMorphic {
  font-weight: 600;
}

.btnDisMor {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border: none;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5em;
  margin-top: 3em;
}

.btnDisMor:hover {
  background-color: #ff4203;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  color: #ffffff;
}

.buttonTopDiv {
  position: relative;
}

.leftPic3dMor {
  display: flex;
}

.newPicMorphos {
  position: relative;
  margin-top: -0.33em;
  height: 0.33em;
  top: 0.2em;
  left: 3.5em;
}

.newPic {
  position: relative;
  margin-top: -0.33em;
  height: 0.33em;
  top: 0em;
  left: 4.2em;
}

@media (max-width: 1320px) {
  .rightPartMorph {
    margin-left: 0px;
    font-size: 75px;
  }
}

@media (max-width: 991px) {
  .ThreeDMorphicContainer {
    height: auto;
  }
  .innerDataContainer3D {
    padding-left: 0px;
    padding-top: 80px;
    flex-direction: column-reverse;
  }
  .rightPartMorph {
    padding-top: 32px;
    line-height: 0.8em;
  }
  .rightPartMorph {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .newPic {
    margin-top: -0.33em;
    height: 0.33em;
    top: 0.1em;
    left: 2.5em;
  }
}

@media (max-width: 991px) {
  .ThreeDMorphicContainer {
    height: auto;
  }
}

@media (max-width: 481px) {
  .d3MorphingTxt {
    font-size: 15px;
  }
  .rightPartMorph {
    font-size: 60px;
  }
}
@media (max-width: 420px) {
  .rightPartMorph {
    font-size: 37px;
  }

  .rightPartMorph {
    padding-top: 0px;
  }
}
