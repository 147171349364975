.mainContainer {
  display: flex;

  align-items: center;
  flex-direction: column;
}
.unlockTxtRandom {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
  padding-top: 90px;
}
.randomAnyTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 300;
  font-size: 125px;
  line-height: 1em;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Light mode (dark grey) */

  color: #222726;
  padding-bottom: 60px;
}
.spAnythingTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Light mode (dark grey) */

  color: #222726;
}
.mainParaTxtRandAny {
  width: 498px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
  padding-bottom: 120px;
}
.spMainParaRandomAny {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
}
.plusRandomImg {
  margin-top: -132px;
}

@media (max-width: 870px) {
  .mainParaTxtRandAny {
    padding-bottom: 80px;
  }

  .unlockTxtRandom {
    padding-top: 60px;
  }
}
@media (max-width: 821px) {
  .randomAnyTxt {
    font-size: 70px;
  }
  .spAnythingTxt {
    font-size: 70px;
  }
}
@media (max-width: 515px) {
  .mainParaTxtRandAny {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    font-size: 13px;
  }
  .randomAnyTxt {
    font-size: 50px;
  }
  .spAnythingTxt {
    font-size: 50px;
  }
}
