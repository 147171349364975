.accept-cookie-btn {
  background-color: white;
  margin-left: 50px;
  min-width: 140px;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #222726;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

.accept-cookie-btn:hover {
  background-color: white;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  color: #ff4203;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

#cookie-mask {
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: color 1s ease-in-out, background-color 1s ease-in-out,
    border-color 1s ease-in-out, box-shadow 1s ease-in-out !important;
}
