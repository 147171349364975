.innovative-main {
  background-image: url(../../images/Gradient-2.png);
  background-size: cover;
  padding-top: 10vw;
  padding-bottom: 10vw;
}

.mainInnovative {
  max-width: 800px;
  margin: auto;
}
.norand-phiolosphy {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  text-transform: capitalize;
  color: #222726;
}
.innovative-heading {
  padding-top: 2.5rem;
  font-family: "Chillax", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 125px;
  line-height: 1em;
  color: #222726;
}
.inspire-heading {
  font-family: "Chillax", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  font-weight: bold;
}
.inno-btn-div {
  margin-top: 2rem;
  margin-left: 189px;
  display: flex;
}

.inno-about-btn {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  border: none;
  justify-content: space-around;
  align-items: center;
  display: flex;
  font-family: "Archia";
  font-weight: 400;
  color: white;
}
.inno-about-btn:hover {
  background-color: #ff4203;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  color: white;
}

.inno-join-btn {
  background-color: white;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  color: #222726;
  border: none;
  justify-content: space-around;
  align-items: center;
  display: flex;
  font-family: "Archia";
  font-weight: 400;
  margin-left: 40px;
}

.inno-join-btn:hover {
  background-color: white;
  color: #8b9291;
}

@media only screen and (max-width: 1024px) {
  .norand-phiolosphy {
    padding-left: 100px !important;
  }
  .innovative-heading {
    padding-left: 100px !important;
    font-size: 100px !important;
  }
  .inno-btn-div {
    margin-left: 100px !important;
  }
  .inspire-heading {
    font-size: 100px !important;
  }
}
@media only screen and (max-width: 991px) {
  .inspire-heading {
    font-size: 80px !important;
  }
  .innovative-heading {
    font-size: 80px !important;
  }
}
@media only screen and (max-width: 767px) {
  .inspire-heading {
    font-size: 50px !important;
  }
  .innovative-heading {
    font-size: 50px !important;
    padding-left: 50px !important;
  }
  .norand-phiolosphy {
    padding-left: 50px !important;
  }
  .inno-btn-div {
    margin-left: 50px !important;
  }
}

@media (max-width: 660px) {
  .mainInnovative {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .norand-phiolosphy {
    padding-left: 0px !important;
  }

  .innovative-heading {
    padding-left: 0px !important;
    font-size: 40px !important;
  }
  .inspire-heading {
    font-size: 40px !important;
  }
  .inno-btn-div {
    margin-left: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
