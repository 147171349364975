.mainContainerAudioMonoB {
  background-color: #222726;
  display: flex;
  width: 85%;
  justify-content: flex-end;
  height: 650px;
}
.audioTopMainMBl {
  width: 612px;
  height: 272px;
  background-color: #ff4203;
  border-radius: 20px;
  margin-top: 194px;
  position: relative;
  left: 130px;
}
.surEverBurTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}
.surEverSpTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}
.topParaTxtAudioMB {
  padding-left: 60px;
  padding-top: 23px;
}
.mainParaAudioMonoB {
  /* Heading 2 semibold */

  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  /* Dark mode (white) */

  color: #ffffff;
  margin-right: -20px;
}
.surgenTxt {
  /* Body regular */

  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}
.ukTxtAudioMono {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}
.mainTopTextPartAudio {
  width: 406px;
  padding-top: 168px;
}

.picTopAudioSur {
  display: flex;
}
.txtSUAudio {
  padding-left: 25px;
}
@media (max-width: 1200px) {
  .mainParaAudioMonoB {
    font-size: 30px;
  }
  .mainTopTextPartAudio {
    padding-left: 30px;
  }
}
@media (max-width: 991px) {
  .mainContainerAudioMonoB {
    flex-direction: column;
    height: auto;
    width: 100%;
    align-items: center;
  }

  .mainContainerAudioMonoB {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .audioTopMainMBl {
    margin-top: 0px;
  }
  .audioTopMainMBl {
    left: 0px;
  }
  .mainTopTextPartAudio {
    padding-left: 0px;
    padding-top: 85px;
    padding-bottom: 85px;
  }
  .mainParaAudioMonoB {
    margin-right: 0px;
  }
  .audioTopMainMBl {
    width: 90%;
  }
}
@media (max-width: 427px) {
  .mainTopTextPartAudio {
    padding-left: 25px;
    padding-right: 25px;
    width: auto;
  }
}
