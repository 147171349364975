.retailers-section-1 {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.retailers-text {
  width: 498px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #222726;
  padding-bottom: 20px;
}

.retailers-section-2 {
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  max-width: 1280px;
  margin-bottom: 100px;
}

.retailers-section-2 > .retailers-col > a {
  text-decoration: none;
  color: #222726;
}

.retailers-section-2 > .retailers-col > a:hover {
  text-decoration: none;
  color: #ff4203;
}

@media screen and (max-width: 767px) {
  .retailers-section-2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
