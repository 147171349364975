.contextual-home-main {
  min-height: 560px;
  background: #222726;
  background-size: cover;
  align-content: center;
  padding-bottom: 60px;
}

.norand-context-text {
  text-align: center;
}

.norand-context-1 {
  color: #ffffff;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: auto;
  max-width: 450px;
  padding-top: 80px;
}

.txtContextual {
  font-size: 115px;
  line-height: 95px;
  font-weight: 600;
  color: #ffffff;
  font-family: "Chillax", sans-serif;
  font-style: normal;
  margin-top: 50px;
}

.txtModulation {
  font-size: 115px;
  line-height: 95px;
  font-weight: 200;
  color: #ffffff;
  font-family: "Chillax", sans-serif;
  font-style: normal;
}

.context-video-player {
  width: 80%;
  height: 45vw;
  margin: auto;
  margin-top: 50px;
  max-width: 1280px;
  max-height: 725px;
  padding-bottom: 40px;
}

@media (max-width: 991px) {
  .txtContextual {
    font-size: 60px;
    line-height: 40px;
  }
  .txtModulation {
    font-size: 60px;
    line-height: 40px;
  }
}

@media (max-width: 480px) {
  .txtContextual {
    font-size: 36px;
    line-height: 24px;
  }
  .txtModulation {
    font-size: 36px;
    line-height: 24px;
  }
}
