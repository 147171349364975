@font-face {
  font-family: "Chillax";
  src: url("./fonts/chillax/Chillax-Extralight.woff2") format("woff2"),
    url("./fonts/chillax/Chillax-Extralight.woff") format("woff"),
    url("./fonts/chillax/Chillax-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Chillax";
  src: url("./fonts/chillax/Chillax-Light.woff2") format("woff2"),
    url("./fonts/chillax/Chillax-Light.woff") format("woff"),
    url("./fonts/chillax/Chillax-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Chillax";
  src: url("./fonts/chillax/Chillax-Regular.woff2") format("woff2"),
    url("./fonts/chillax/Chillax-Regular.woff") format("woff"),
    url("./fonts/chillax/Chillax-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Chillax";
  src: url("./fonts/chillax/Chillax-Medium.woff2") format("woff2"),
    url("./fonts/chillax/Chillax-Medium.woff") format("woff"),
    url("./fonts/chillax/Chillax-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Chillax";
  src: url("./fonts/chillax/Chillax-Semibold.woff2") format("woff2"),
    url("./fonts/chillax/Chillax-Semibold.woff") format("woff"),
    url("./fonts/chillax/Chillax-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Chillax";
  src: url("./fonts/chillax/Chillax-Bold.woff2") format("woff2"),
    url("./fonts/chillax/Chillax-Bold.woff") format("woff"),
    url("./fonts/chillax/Chillax-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url("./fonts/archia/archia-thin-webfont.eot");
  src: url("./fonts/archia/archia-thin-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/archia/archia-thin-webfont.woff2") format("woff2"),
    url("./fonts/archia/archia-thin-webfont.woff") format("woff"),
    url("./fonts/archia/archia-thin-webfont.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url("./fonts/archia/archia-light-webfont.eot");
  src: url("./fonts/archia/archia-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/archia/archia-light-webfont.woff2") format("woff2"),
    url("./fonts/archia/archia-light-webfont.woff") format("woff"),
    url("./fonts/archia/archia-light-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url("./fonts/archia/archia-regular-webfont.eot");
  src: url("./fonts/archia/archia-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/archia/archia-regular-webfont.woff2") format("woff2"),
    url("./fonts/archia/archia-regular-webfont.woff") format("woff"),
    url("./fonts/archia/archia-regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url("./fonts/archia/archia-medium-webfont.eot");
  src: url("./fonts/archia/archia-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/archia/archia-medium-webfont.woff2") format("woff2"),
    url("./fonts/archia/archia-medium-webfont.woff") format("woff"),
    url("./fonts/archia/archia-medium-webfont.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url("./fonts/archia/archia-semibold-webfont.eot");
  src: url("./fonts/archia/archia-semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/archia/archia-semibold-webfont.woff2") format("woff2"),
    url("./fonts/archia/archia-semibold-webfont.woff") format("woff"),
    url("./fonts/archia/archia-semibold-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url("./fonts/archia/archia-bold-webfont.eot");
  src: url("./fonts/archia/archia-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/archia/archia-bold-webfont.woff2") format("woff2"),
    url("./fonts/archia/archia-bold-webfont.woff") format("woff"),
    url("./fonts/archia/archia-bold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}

textarea:focus,
input:focus {
  outline: none;
}
a {
  text-decoration: none !important;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

a:hover {
  text-decoration: none !important;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

.my-alert-success {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  background-color: #ff4203 !important;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4) !important;
  color: white !important;
  border: none !important;
  border-radius: 25px !important;
  font-family: "Archia" !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #ffffff !important;
  min-width: 250px !important;
  width: 50% !important;
}

.my-alert-chill {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  background-color: #ff4203 !important;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4) !important;
  color: white !important;
  border: none !important;
  border-radius: 25px !important;
  font-family: "Archia" !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #ffffff !important;
  width: 50% !important;
  max-width: 600px;
}
@media (max-width: 1080px) {
  .my-alert-chill {
    text-align: center;
    width: 90% !important;
    flex-direction: column;
  }
}

.imgArrow {
  margin-left: 20px;
}

textarea {
  resize: none;
}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 150px;
  height: 150px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  margin: auto;
}

.backdrop-blur {
  display: flex;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  padding: 0;
  background: linear-gradient(
      217deg,
      rgba(255, 56, 3, 0.8),
      rgba(255, 56, 3, 0) 70.71%
    ),
    linear-gradient(
      127deg,
      rgba(32, 234, 214, 0.8),
      rgba(33, 234, 214, 0) 70.71%
    ),
    linear-gradient(336deg, rgba(34, 39, 38, 0.8), rgba(34, 39, 38, 0) 70.71%);
}

.anim-bg {
  filter: blur(50px);
  -webkit-filter: blur(50px);
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.anim-form-1 {
  position: absolute;
  z-index: -1;
  clip-path: polygon(
    88% 18%,
    60% 30%,
    66% 62%,
    80% 90%,
    39% 85%,
    21% 47%,
    26% 17%,
    64% 5%
  );
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ed1313;
  animation: move-around-1 12s ease-in-out infinite;
  -webkit-animation: move-around-1 12s ease-in-out infinite;
}

@keyframes move-around-1 {
  from {
    transform: translate(0, 0) rotate(0deg);
  }
  20% {
    transform: translate(33%, 0) rotate(30deg);
  }
  40% {
    transform: translate(25%, 50%) rotate(-10deg);
  }
  60% {
    transform: translate(55%, 20%) rotate(-22deg);
  }
  80% {
    transform: translate(20%, 50%) rotate(-21deg);
  }
  to {
    transform: translate(0, 0) rotate(0deg);
  }
}

.anim-form-2 {
  position: absolute;
  z-index: -1;
  clip-path: polygon(
    85% 14%,
    44% 13%,
    15% 49%,
    34% 79%,
    45% 42%,
    57% 20%,
    74% 44%
  );
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #13b3ed;
  animation: move-around-2 11s ease-in-out infinite;
  -webkit-animation: move-around-2 11s ease-in-out infinite;
}

@keyframes move-around-2 {
  from {
    transform: translate(0, 0) rotate(90deg);
  }
  20% {
    transform: translate(10%, 30%) rotate(122deg);
  }
  40% {
    transform: translate(-10%, 50%) rotate(76deg);
  }
  60% {
    transform: translate(18%, 33%) rotate(60deg);
  }
  80% {
    transform: translate(12%, 12%) rotate(45deg);
  }
  to {
    transform: translate(0, 0) rotate(90deg);
  }
}

.anim-form-3 {
  position: absolute;
  z-index: -1;
  clip-path: polygon(70% 11%, 17% 11%, 17% 38%, 18% 79%, 65% 56%, 58% 29%);
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #13edba;
  animation: move-around-3 14s ease-in-out infinite;
  -webkit-animation: move-around-3 14s ease-in-out infinite;
}

@keyframes move-around-3 {
  from {
    transform: translate(0, 0) rotate(90deg);
  }
  20% {
    transform: translate(-15%, 22%) rotate(42deg);
  }
  40% {
    transform: translate(-24%, 17%) rotate(76deg);
  }
  60% {
    transform: translate(18%, 33%) rotate(22deg);
  }
  80% {
    transform: translate(33%, -12%) rotate(45deg);
  }
  to {
    transform: translate(0, 0) rotate(90deg);
  }
}

.anim-form-4 {
  position: absolute;
  z-index: -1;
  clip-path: polygon(
    23% 23%,
    20% 84%,
    75% 63%,
    42% 55%,
    45% 21%,
    85% 10%,
    59% 6%,
    20% 6%,
    6% 26%
  );
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ed4d13;
  animation: move-around-4 15s ease-in-out infinite;
  -webkit-animation: move-around-4 15s ease-in-out infinite;
}

@keyframes move-around-4 {
  from {
    transform: translate(0, 0) rotate(0deg);
  }
  20% {
    transform: translate(-11%, -22%) rotate(-42deg);
  }
  40% {
    transform: translate(-18%, 5%) rotate(-21deg);
  }
  60% {
    transform: translate(-4%, 25%) rotate(17deg);
  }
  80% {
    transform: translate(33%, -12%) rotate(45deg);
  }
  to {
    transform: translate(0, 0) rotate(0deg);
  }
}

.anim-form-5 {
  position: absolute;
  z-index: -1;
  clip-path: polygon(
    82% 18%,
    63% 69%,
    69% 83%,
    36% 84%,
    20% 46%,
    42% 53%,
    40% 23%
  );
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #32c1a6;
  animation: move-around-5 12.5s ease-in-out infinite;
  -webkit-animation: move-around-5 12.5s ease-in-out infinite;
}

@keyframes move-around-5 {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  20% {
    transform: translate(-11%, -22%) rotate(32deg);
  }
  40% {
    transform: translate(-18%, -35%) rotate(17deg);
  }
  60% {
    transform: translate(-42%, -12%) rotate(17deg);
  }
  80% {
    transform: translate(-33%, -24%) rotate(45deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

.anim-form-6 {
  position: absolute;
  z-index: -1;
  clip-path: polygon(
    85% 14%,
    44% 13%,
    15% 49%,
    34% 79%,
    45% 42%,
    57% 20%,
    74% 44%
  );
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3283c1;
  animation: move-around-6 15s ease-in-out infinite;
  -webkit-animation: move-around-6 15s ease-in-out infinite;
}

@keyframes move-around-6 {
  from {
    transform: translate(0, 0) rotate(0deg);
  }
  20% {
    transform: translate(0%, -22%) rotate(32deg);
  }
  40% {
    transform: translate(-18%, -35%) rotate(17deg);
  }
  60% {
    transform: translate(-42%, -12%) rotate(17deg);
  }
  80% {
    transform: translate(-33%, -24%) rotate(45deg);
  }
  to {
    transform: translate(0, 0) rotate(0deg);
  }
}

.anim-form-7 {
  position: absolute;
  z-index: -1;
  clip-path: polygon(
    88% 18%,
    60% 30%,
    66% 62%,
    80% 90%,
    39% 85%,
    21% 47%,
    26% 17%,
    64% 5%
  );
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c13c32;
  animation: move-around-7 11.5s ease-in-out infinite;
  -webkit-animation: move-around-7 11.5s ease-in-out infinite;
}

@keyframes move-around-7 {
  from {
    transform: translate(0, 0) rotate(90deg);
  }
  20% {
    transform: translate(18%, 33%) rotate(22deg);
  }
  40% {
    transform: translate(-24%, 17%) rotate(76deg);
  }
  60% {
    transform: translate(-15%, 22%) rotate(42deg);
  }
  80% {
    transform: translate(33%, -12%) rotate(45deg);
  }
  to {
    transform: translate(0, 0) rotate(90deg);
  }
}
