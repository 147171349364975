.norand-mono-main {
}

.first-col {
  background-color: #ff4203;
  background-blend-mode: color;
  padding-bottom: 60px;
}
.second-col {
  background-image: url(../../images/monobg.png);
  background-size: cover;
  background-position: 25% 100%;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
}
.news-ptag {
  padding-top: 103px;
  padding-left: 85px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.new-norand {
  padding-left: 85px;
  padding-top: 10rem;
  font-family: "Chillax", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  text-transform: capitalize;
  color: #ffffff;
}
.mono-heading {
  padding-left: 75px;
  padding-top: 2rem;
  font-family: "Chillax", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 125px;
  line-height: 1em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 40px;
}
.btn-div {
  margin-top: auto;
  margin-bottom: 40px;
  margin-left: 80px;
}
.update-btn {
  background-color: white;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #222726;
  border: none;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
}

.update-btn:hover {
  background-color: white;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  color: #ff4203;
}

.arrow-icon {
  font-size: 20px !important;
  margin-left: 10px;
}
.norand-mono {
  color: #ffffff;
  padding-top: 60px;
  padding-left: 85px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
}
.norand-para {
  padding-left: 85px;
  padding-top: 30px;
  padding-bottom: 200px;
  color: #ffffff;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 60%;
}
.discover-btn {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #222726;
  border: none;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
  color: white;
}

.discover-btn:hover {
  background-color: #ff4203;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.8);
  color: white;
}

@media only screen and (max-width: 767px) {
  .mono-heading {
    font-size: 80px !important;
  }
}

@media (max-width: 600px) {
  .news-ptag {
    padding-left: 0px;
    padding-top: 31px;
  }
  .new-norand {
    padding-left: 0px;
  }
  .mono-heading {
    padding-left: 0px;
  }
  .first-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .new-norand {
    text-align: center;
    padding-top: 70px;
  }
  .mono-heading {
    text-align: center;
    font-size: 60px !important;
  }
  .btn-div {
    padding-left: 0px;
    margin-left: 0px;
  }
  .second-col {
    display: flex;
    flex-direction: column;

    align-items: center;
  }
  .norand-mono {
    padding-left: 0px;
    text-align: center;
  }
  .norand-para {
    padding-left: 0px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .btn-div {
    padding-left: 0px;
  }
}
