.mostStableMK2Txt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}

.mainHeadingMK2 {
  font-family: "Chillax";
  padding-top: 60px;
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Light mode (dark grey) */

  color: #222726;
}
.spMainHeadingMK2 {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 200;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Light mode (dark grey) */

  color: #222726;
}
.mainParaMK2 {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  padding-top: 60px;

  /* Light mode (dark grey) */

  color: #222726;
  max-width: 610px;
  margin: auto;
}
.mainContainerMK2 {
  /* display: flex;
    flex-direction: column;
    align-items: center;
     */
  padding-top: 50px;
  background-color: white;
}

.extemlyStableTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  /* Light mode (dark grey) */

  color: #222726;
}
.pageRandMK2 {
  /* Body regular */

  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Light mode (dark grey) */

  color: #222726;
  padding-top: 20px;
}
.moreTxtMK2 {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Light mode (dark grey) */

  color: #222726;
  padding-top: 20px;
}
.secondRowTxtPartMK2 {
  max-width: 407px;
}
.moreAboutTop {
  display: flex;

  align-items: center;
}
.picArrow {
  margin-left: 10px;
  margin-top: 5px;
}

.MK2SecondRow {
  display: flex;
  padding-top: 50px;
  justify-content: center;
}

.MK2VideoTop > .video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  /* width: 800%; */
  height: 422px;
  padding: 0px !important;
  border-radius: 20px;
  width: 611px;
}
.picPortionMainMK2 {
  display: flex;

  max-width: 1200px;
  margin: auto;
}
.txtMorphosStudioTZMF {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  text-align: right;

  /* Light mode (dark grey) */

  color: #222726;
}
.topMorphosArrowTZMF {
  display: flex;
  justify-content: flex-end;
}
.dropArrowMK2 {
  padding-left: 50px;
}
.topMorphosArrowTZMF {
  padding-right: 177px;
  padding-top: 225px;
  padding-bottom: 283px;
}
.learnTxtTop {
  display: flex;
  justify-content: center;
}

@media (max-width: 1453px) {
  .topMorphosArrowTZMF {
    padding-right: 110px;
  }
}

@media (max-width: 1163px) {
  .MK2VideoTop {
    margin-left: 20px;
  }
}

@media (max-width: 991px) {
  .MK2VideoTop > .video-js {
    width: 95%;
  }

  .topMorphosArrowTZMF {
    padding-bottom: 328px;
  }
  .pageRandMK2 {
    text-align: center;
  }
  .extemlyStableTxt {
    text-align: center;
    font-size: 30px;
  }
  .MK2VideoTop {
    margin-left: 0px;
  }
  .MK2SecondRow {
    flex-direction: column;
    align-items: center;
  }
  .MK2VideoTop > .video-react.video-react-fluid,
  .video-react.video-react-16-9,
  .video-react.video-react-4-3 {
    width: 85%;
  }
  .MK2VideoTop {
    width: 100%;
    display: flex;

    justify-content: center;
    padding-top: 90px;
  }

  .mainHeadingMK2 {
    font-size: 70px;
    line-height: 90px;
  }
  .spMainHeadingMK2 {
    font-size: 70px;
    line-height: 90px;
  }
  .monoMK2 {
    width: 100%;
  }
  .topMorphosArrowTZMF {
    padding-right: 0px;
    justify-content: center;
  }
  .txtMorphosStudioTZMF {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .mainHeadingMK2 {
    font-size: 45px;
    line-height: 70px;
  }
  .spMainHeadingMK2 {
    font-size: 45px;
    line-height: 70px;
  }

  .mainParaMK2 {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .MK2SecondRow {
    padding-top: 10px;
  }
  .MK2VideoTop > .video-react.video-react-fluid,
  .video-react.video-react-16-9,
  .video-react.video-react-4-3 {
    width: 95%;
  }
  .extemlyStableTxt {
    line-height: 35px;
    font-size: 30px;
  }
}

@media (max-width: 455px) {
  .dropArrowMK2 {
    padding-left: 2px;
  }
  .txtMorphosStudioTZMF {
    font-size: 25px;
  }
}

.MK2mainContainerSimplePowerful {
  width: 100%;
  height: auto;
  background-color: #222726;
  display: flex;
  padding-bottom: 204px;
  height: auto;

  flex-direction: column;
  align-items: center;
}

ul {
  padding-left: 0;
  list-style-type: none;
}

.MK2doItAllTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;
  padding-bottom: 57px;

  /* Dark mode (white) */

  color: #222726;
  padding-top: 80px;
}
.MK2simplePowerfulTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 300;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Dark mode (white) */

  color: #222726;
  padding-bottom: 065px;
  max-width: 699px;
}

.MK2firstInputSimpPow2 {
  max-width: 80%;
}

.MK2firstInputSimpPow3 {
  max-width: 80%;
  min-width: 300px;
}

.MK2firstInputSimpPow4 {
  max-width: 400px;
  min-width: 300px;
  padding-right: 10px;
}

.MK2mainParaSiimplePower {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #222726;
  width: 90%;
  max-width: 600px;
}

.MK2txtPartSimplPow {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MK2spYetPowerTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Dark mode (white) */

  color: #222726;
}
.MK2mainParaSpTxtSimpPow {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #222726;
}
.MK2mainPicSimplePower {
  padding-top: 169px;
  padding-bottom: 306px;
  width: 75%;
}
.MK2mainPicPart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 80px;
}

.MK2txtSimpPowFirstRow {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  /* Dark mode (white) */

  color: #222726;
  max-width: 500px;
}
.MK2subTxtSimpPowFirstRow {
  /* Body regular */

  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #222726;
  max-width: 300px;
}
.MK2subTxtSimpPowFirstRow > ul {
  padding-left: 0;
}

.MK2subTxtSimpPowFirstRow > ul > li {
  list-style-type: none;
}

.MK2imgageLeftSimp {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

#MK2undoRedo {
  margin-right: 20px;
}
.MK2txtParaRightSimp {
  padding-left: 48px;
  width: 500px;
}
.MK2lineCopy1 {
  position: absolute;
  bottom: -151px;
}
.MK2learnMoreTxtSimp {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Dark mode (white) */

  color: #222726;
  margin-left: 16px;
}
.MK2step1Img2 {
  position: absolute;
  left: 14px;
  z-index: 35;
  filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
.MK2step1Img3 {
  position: absolute;
  left: 28px;
  z-index: 2;
  filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
.step1Img4 {
  position: absolute;
  left: 22px;
  z-index: 0;
  filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}

.MK2step1Img {
  position: relative;
  z-index: 999;
  filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
.MK2group64Img {
  position: absolute;
  left: 70px;
  filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
#MK2cirlceLinePow1 {
  margin-left: 61px;
}
.MK2lineCopySecond {
  position: absolute;
  bottom: -151px;
  z-index: 999;
  right: 64px;
}
#MK2mainPicPart2 {
  margin-left: -46px;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}
#MK2mainPicPart3 {
  margin-top: -40px;
  display: flex;
  justify-content: center;
  padding-right: 120px;
}
.MK2mainPicPart2 {
  margin-top: -62px;
}
#MK2lineCopy3 {
  position: absolute;
  bottom: -125px;
}
#MK2mainPicPart5 {
  margin-top: 40px;
}
#MK2mainPicPart6 {
  margin-top: 60px;
}
#MK2mainPicPart7 {
  margin-top: 75px;
}
#MK2mainPicPart6 {
}
.MK2lowerDivSim {
  width: 80%;

  display: flex;

  flex-direction: column;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
  padding-bottom: 80px;
}
.MK2upperDivSim {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.MK2undoRedoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 429px;
}
@media (max-width: 1450px) {
  .MK2lowerDivSim {
    width: 100%;
  }
}

@media (max-width: 1166px) {
  #MK2lineCopy3 {
    top: 100px;
  }
  .MK2lineCopy1 {
    position: absolute;
    bottom: -151px;
    height: 150px;
  }
  .MK2firstInputSimpPow {
    width: 100px;
  }
  .MK2step1Img {
    width: 100px;
  }
  .MK2group64Img {
    width: 100px;
    left: 45px;
  }
  .MK2step1Img3 {
    width: 100px;
  }
  .MK2txtSimpPowFirstRow {
    font-size: 30px;
    line-height: 35px;
  }
  .MK2subTxtSimpPowFirstRow {
    font-size: 12px;
  }
  .MK2undoImg {
    width: 100px;
  }
  .MK2redoImg {
    width: 100px;
  }
  .MK2step1Img2 {
    width: 100px;
  }
  .MK2cirlceLinePow1 {
    width: 72px;
  }
  .MK2mainPicPart {
    padding-top: 50px;
    width: 100%;
  }
  #MK2mainPicPart3 {
    margin-top: 20px;
    justify-content: center;
    padding-right: 0px;
  }
  #MK2lineCopy1Id {
    height: 140px;
    bottom: -135px;
  }

  #MK2endLineCopyID {
    height: 120px;
    bottom: -120px;
  }

  #MK2lineCopy3 {
    height: 120px;
    bottom: -135px;
  }
  .MK2undoRedoContainer {
    width: 251px;
  }

  #MK2txtParaRightSimpId1 {
    padding-left: 50px;
  }
  #MK2mainPicPart2 {
    margin-left: 0px;
  }
  .MK2lineCopySecond {
    right: 20px;
    top: 100px;
  }
  .MK2txtParaRightSimp {
    padding-left: 100px;
    width: 600px;
  }
  #MK2txtParaRightSimp {
    padding-left: 24px;
  }
}

@media (max-width: 823px) {
  .MK2simplePowerfulTxt {
    font-size: 70px;
    line-height: 90px;
  }
  .MK2spYetPowerTxt {
    font-size: 70px;
    line-height: 90px;
  }
  .MK2mainPicSimplePower {
    width: 95%;
  }

  .MK2lowerDivSim {
    width: 80%;
  }
  #MK2txtParaRightSimp {
    padding-left: 0px;
  }

  .MK2upperDivSim {
    justify-content: center;
  }
  #MK2mainPicPart3 {
    margin-left: -150px;
  }
  #MK2mainPicPart2 {
    margin-left: -53px;
  }
}

@media (max-width: 751px) {
  .MK2txtParaRightSimp {
    text-align: center;
    width: 100%;
    padding-left: 0;
  }
  .MK2subTxtSimpPowFirstRow {
    max-width: 100%;
  }
  .MK2txtSimpPowFirstRow {
    max-width: 100%;
  }
  .MK2mainPicPart {
    flex-direction: column;
    padding-top: 0px;
  }
  #MK2mainPicPart3 {
    margin-left: 0px;
  }
  .MK2lowerDivSim {
    width: 80%;
  }
  .MK2txtSimpPowFirstRow {
    font-size: 14px;
    line-height: 18px;
  }
  .MK2subTxtSimpPowFirstRow {
    font-size: 10px;
    line-height: 16px;
  }

  #MK2lineCopy1Id {
    height: 80px;
    bottom: -80px;
  }
  .MK2learnMoreTxtSimp {
    font-size: 10px;
    line-height: 16px;
  }

  .MK2upperDivSim {
    justify-content: center;
  }

  .MK2lineCopy1 {
    bottom: -192px;
    height: 210px;
  }
  .MK2lineCopySecond {
    bottom: -249px;
    height: 150px;
  }
  #MK2lineCopy3 {
    bottom: -195px;
  }
  .MK2cirlceLinePow1 {
    visibility: hidden;
  }
  #MK2mainPicPart3 {
    margin-left: 0px;
  }
  #MK2mainPicPart2 {
    margin-left: 0px;
  }
  #MK2txtParaRightSimpId1 {
    padding-left: 0px;
  }
}

@media (max-width: 500px) {
  .MK2simplePowerfulTxt {
    font-size: 50px;
    line-height: 65px;
  }
  .MK2spYetPowerTxt {
    font-size: 50px;
    line-height: 65px;
  }
}
