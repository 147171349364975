.freeShippingContainer {
  height: 270px;
  width: 100%;
  background-color: #ff4203;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.headingFreeShipping {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;

  color: #ffffff;
}

.btnArrowFreeShip {
  /* Body regular */

  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Dark mode (white) */

  color: #ffffff;
  background: none;
  border: none;
}

.btnArrowFreeShip:hover {
  color: #ffffff;
}

@media (max-width: 566px) {
  .headingFreeShipping {
    font-size: 30px;
  }
  .headingFreeShipping {
    text-align: center;
  }
}
