.manual-main-content {
  margin-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.manual-nav {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  flex-wrap: nowrap;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  margin-top: 60px;
  padding-top: 10px;
  box-shadow: 1px 0px 8px -2px rgba(0, 0, 0, 0.5);
  width: 100%;
  max-width: 300px;
}

.manual-rm-padding {
  padding-left: 0;
  padding-right: 0;
}

#manual-nav-highlight {
  font-weight: 700;
}
.manual-img {
  width: 100%;
}
.download-link-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.download-link {
  font-family: "Chillax";
  color: #222726;
  font-weight: 400;
  font-size: 24px;
  padding-top: 25px;
  float: right;
  transition: color 0.3s;
}

.download-link:hover {
  transition: color 0.3s;
  color: #ff4203;
}

.row {
  margin: 0;
}
h2 {
  font-family: "Chillax";
  color: #ff4203;
  font-weight: 500;
  font-size: 48px;
  padding: 40px;
  margin-top: 40px;
}
h3 {
  font-family: "Chillax";
  color: #222726;
  font-weight: 400;
  font-size: 32px;
  padding-top: 40px;
  padding-bottom: 20px;
}
h4 {
  font-family: "Chillax";
  color: #ff4203;
  font-weight: 400;
  font-size: 20px;
  padding: 2px 0;
}
.manual-main-content > p {
  width: 80%;
  font-family: "Archia";
  font-weight: 400;
  font-size: 16px;
}

.manual-main-content > ol > li {
  padding-bottom: 8px;
}
.manual-main-content > ol {
  list-style: none;
  counter-reset: li;
  width: 80%;
  max-width: 700px;
}
.manual-main-content > ol > li::before {
  content: counter(li);
  color: #222726;
  display: inline-block;
  width: 20px;
  margin-left: -20px;
  font-family: "Archia";
  color: #222726;
  font-weight: 500;
  margin-left: -1.5em;
  margin-right: 0.5em;
  text-align: right;
  direction: rtl;
}

ol#panel-layout > li::before {
  content: counter(li, upper-latin);
  color: #222726;
  display: inline-block;
  width: 20px;
  margin-left: -20px;
}

ol#connector-list > li::before {
  content: counter(li);
  color: black;
  display: inline-block;
  width: 20px;
  margin-left: -20px;
}

.manual-main-content > ol > li {
  counter-increment: li;
}

.manual-main-content > ul {
  list-style: disc;
  padding-left: 10px;
  width: 80%;
  max-width: 700px;
}

.strong-1 {
  color: #222726;
  font-weight: 600;
}

.strong-2 {
  color: black;
  font-weight: 500;
}

.ps {
  background-color: white;
  height: calc(100vh - 60px);
  position: sticky;
  top: 60px;
  overflow-y: auto;
  padding-top: 10px;
  box-shadow: 1px 0px 8px -2px rgba(0, 0, 0, 0.5);
  max-width: 250px;
}
nav {
  width: 100%;
}

.nav-item {
  font-family: "Chillax";
  color: rgba(53, 80, 75, 0.4);
  font-weight: 400;
  font-size: 14px;
}
.nav-link {
  padding: 0;
  color: black;
  line-height: 20px;
  padding-bottom: 3px;
}
.nav-link:hover {
  color: rgba(34, 39, 38, 0.4);
}

.manual-nav > .active {
  background-color: inherit;
  font-weight: 700;
}
.title-1 {
  font-size: 16px;
  color: #ff4203;
  font-weight: 500;
  padding-left: 0px;
  margin-top: 1px;
  margin-bottom: 1px;
  transition: color 0.3s;
}
.title-1:hover {
  transition: color 0.3s;
  color: rgba(255, 66, 3, 0.75);
}
.title-2 {
  padding-left: 10px;
  color: rgba(34, 39, 38, 1);
}
.title-2:hover {
  transition: color 0.3s;
  color: rgba(34, 39, 38, 0.75);
}
.title-3 {
  color: rgba(34, 39, 38, 0.8);
  padding-left: 20px;
  font-size: 14px;
  line-height: 18px;
}
.title-3:hover {
  transition: color 0.3s;
  color: rgba(34, 39, 38, 0.55);
}
.row-bubble {
  margin: 60px 0;
  align-items: stretch;
  justify-content: space-around;
}
.card-col {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}
.emphasis {
  font-style: italic;
}
.row-settings {
  justify-content: space-around;
}

.settings-col {
  min-width: 200px;
}
.param-enum {
  background-color: #222726;
  font-weight: 600;
  color: white;
  display: inline-block;
  min-height: 24;
  min-width: 24px;
  text-align: center;
  border-radius: 12px;
  margin: 3px;
}
.card {
  background-color: #222726;
  height: 100%;
  max-width: 500px;
  padding: 20px;
  border-radius: 20px;
}
.card-title {
  text-align: center;
  color: white;
  font-family: "Chillax";
  font-weight: 400;
  font-size: 32px;
}

.card-img {
  margin: 20px auto;
  max-width: 300px;
  display: block;
}

.card-text {
  font-family: "Archia";
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 0;
  padding-top: 10px;
  text-align: justify;
}
ul {
  list-style: disc;
  padding-left: 20px;
  width: 100%;
}
li {
  line-height: 1.5;
  margin-bottom: 5px;
  font-family: "Archia";
}
