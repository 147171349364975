.main-bg {
  background-image: url(../../images/learnmainbg.png);
  background-size: cover;
}
.discover-heading {
  text-align: center;
  padding-top: 120px;
  padding-bottom: 60px;
  font-family: "Chillax";
  font-style: normal;
  font-weight: 200;
  font-size: 115px;
  line-height: 1em;
  color: #ffffff;
}

.discover-heading-bold {
  font-weight: 600;
}

.learn-first-col {
  background-color: #20ead6;
  padding-left: 0px;
  padding-right: 0px;
}
.learn-second-col {
  background-color: #ff4203;
  padding-left: 0px;
  padding-right: 0px;
}
.desktop-inst {
  padding-left: 10%;
  padding-top: 5rem;
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #222726;
}
.complex-heading {
  padding-left: 10%;
  padding-top: 2rem;
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  color: #222726;
}
.learn-btn-div {
  display: flex;

  margin-left: 45px;
  margin-top: 5rem;
  margin-bottom: -130px;
  position: relative;
  z-index: 1;
}
.learn-discover-btn {
  background-color: white;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #222726;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
  margin: 15px;
  margin-top: 60px;
  transition: box-shadow 0.5s;
}
.learn-discover-btn:hover {
  background-color: white;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.6);
  color: #222726;
}
.learn-manual-btn {
  background-color: inherit;
  padding: 13px 31px 13px 31px;
  width: auto;
  margin-left: 30px;
  height: 50px;
  border-radius: 25px;
  color: #222726;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
  margin-top: 60px;
  transition: box-shadow 0.5s;
}

.learn-manual-btn:hover {
  color: darkgrey;
}

.cutmono-img {
  object-position: center 100px;
  object-fit: cover;
  width: 100%;
  height: 542px;
}

.eurorack {
  padding-left: 90px;
  padding-top: 4rem;
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.ArrowFooter {
  padding-left: 10px;
}

.morphing-heading {
  padding-left: 90px;
  padding-top: 3rem;
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  color: #ffffff;
}
.morphos-btn-div {
  margin-left: 75px;
  margin-top: 5rem;
  display: flex;
  position: relative;
  z-index: 1;
}

.morphos-btn {
  background-color: white;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #222726;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
  margin: 15px;
  margin-top: 60px;
  transition: box-shadow 0.5s;
}

.morphos-btn:hover {
  background-color: white;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.6);
  color: #222726;
}

.face-cutimg {
  width: 100%;
  object-position: center 100px;
  object-fit: cover;
  margin-top: -130px;
  height: 542px;
}
@media only screen and (max-width: 1129px) {
}
@media only screen and (max-width: 991px) {
  .discover-heading {
    font-size: 80px;
    line-height: 60px;
  }
}

@media (max-width: 600px) {
  .discover-heading {
    font-size: 45px;
  }
  .desktop-inst {
    padding-left: 0px;
    text-align: center;
  }
  .complex-heading {
    padding-left: 0px;
    text-align: center;
  }
  .learn-btn-div {
    padding-left: 0px;
  }
  .learn-btn-div {
    margin-left: 0px;
  }
  .learn-first-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .morphing-heading {
    padding-left: 0px;
    text-align: center;
    font-size: 33px;
  }
  .morphos-btn-div {
    margin-left: 0px;

    justify-content: center;
  }

  .eurorack {
    text-align: center;
    padding-left: 0px;
  }
  .learn-first-col {
    height: 773px;
  }
}
