.mainMorTab {
  background-color: #222726;
}

.randAnimEleMorpos {
  position: relative;
  overflow: hidden;
  width: 500px;
  left: 50px;
}

.mainMorTab > .txtMainTabRand > .tabDivLineTxt {
  width: 20%;
}

.mainMorTab > .txtMainTabRand > .tabDivLineTxt > .tabTxtRand {
  color: #ffffff;
}

.mainMorTab > .txtMainTabRand > .tabDivLineTxt > .bottomTxtRand {
  background: #ffffff;
}

.mainMorTab > .pageRandDetail > .randLeftElements > .oneTxt {
  color: #ffffff;
}

.mainMorTab
  > .pageRandDetail
  > .randLeftElements
  > .topDetailOnClick
  > .pageRandTxtHeading {
  color: #ffffff;
}

.mainMorTab
  > .pageRandDetail
  > .randLeftElements
  > .topDetailOnClick
  > .mainParaPagRand {
  color: #ffffff;
}
.mainMorTab
  > .pageRandDetail
  > .randLeftElements
  > .topDetailOnClick
  > .txtLearnPageRand {
  color: #ffffff;
}

.mainMorTab > .pageRandDetail > .randRightElements > .randAnimContainer {
  border: 1px solid #ffffff;
}

.mainMorTab > .howToWorkMainTabRand > .howSWorkTxt {
  color: #ffffff;
}

.mainMorTab > .letsMakePart > .letTxtRandTab {
  color: #ffffff;
}

@media (max-width: 870px) {
  .mainMorTab > .txtMainTabRand > .tabDivLineTxt {
    width: 90%;
  }
}

@media (max-width: 620px) {
  .randAnimEleMorpos {
    width: 350px;
    left: 0px;
  }
}
