.mainIntroContextModule {
  width: 100%;
  background-color: #222726;
  display: flex;

  flex-direction: column;
  align-items: center;
  margin-bottom: -260px;
}

.monoAnimContainer {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 50vh;
  transform: translateY(-30%);
  display: flex;
  margin-top: 400px;
  width: 100%;
  height: 100vh;
  max-height: 800px;
}

.svgContainer {
  position: absolute;
  right: 50vw;
  overflow: hidden;
}

.animComponent {
  position: absolute;
  left: -108px;
  top: -173px;
  width: 2363px;
}

.animComponentOsc {
  position: absolute;
  top: -320px;
  left: -350px;
  width: 800px;
}

.animText {
  text-align: left;
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  width: 480px;
  margin-left: 20px;
  color: #ffffff;
}

.animNumber {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  margin-left: 5vw;
  text-align: left;
  text-transform: capitalize;
  color: #ffffff;
}

.hideOverflow {
  overflow: hidden;
  margin-left: 50%;
  width: 100%;
  padding-top: 200px;
}

.slideInOut {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-right: 20px;
  transform: translateX(120%);
  -webkit-transform: translateX(120%);
}

.slideIn {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slideOut {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  0% {
    transform: translateX(120%);
  }
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateX(120%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(120%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(120%);
  }
}

.animTriggerFirst {
  height: 100px;
}
.animTrigger {
  height: 800px;
}
.lastAnimTrigger {
  height: 800px;
}

.introTxtModule {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Dark mode (white) */

  color: #ffffff;
  padding-top: 80px;
}
.contextualIntroHeadTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  line-height: 1em;

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  padding-top: 40px;
}
.monoIntroParaTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #ffffff;
  padding-top: 40px;
  width: 505px;
}
.spModuleTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 300;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Dark mode (white) */

  color: #ffffff;
}
.spMonoIntroPara {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #ffffff;
  padding-top: 60px;
}
.seeInActionTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  text-align: right;
  padding-right: 50px;

  /* Dark mode (white) */

  color: #ffffff;
}
.seeArrowDiv {
  display: flex;
  margin-left: auto;
  margin-right: 10vw;
  transform: translateY(-300%);
}

@media (max-width: 1080px) {
  .monoAnimContainer {
    transform: translateY(-40%);
    margin-top: 400px;
  }
  .animNumber {
    margin-top: 10px;
    margin-right: 10px;
  }
  .hideOverflow {
    padding-top: 0;
    margin-left: 0;
  }
  .animComponent {
    overflow: hidden;
  }
  .svgContainer {
    position: absolute;
    right: 25vw;
    overflow: hidden;
    margin-top: 150px;
  }
  .slideInOut {
    align-items: flex-start;
  }
  .animText {
    padding-left: 200px;
    width: 80%;
    line-height: 36px;
    font-size: 24px;
  }
  .animComponentOsc {
    top: -120px;
    left: -550px;
    width: 800px;
  }
  .seeArrowDiv {
    bottom: 315px;
    right: 11%;
  }
}
@media (max-width: 991px) {
  .contextualIntroHeadTxt {
    font-size: 75px;
  }
  .spModuleTxt {
    font-size: 75px;
  }
}
@media (max-width: 700px) {
  .animNumber {
    margin-top: 10px;
    margin-right: 10px;
  }
  .hideOverflow {
    margin-left: 0;
  }
  .animComponent {
    overflow: hidden;
    width: 1033px;
    left: -46px;
    top: -75px;
  }
  .monoAnimContainer {
    transform: translateY(-20%);
    margin-top: 400px;
  }
  .svgContainer {
    position: absolute;
    right: 25vw;
    overflow: hidden;
    margin-top: 120px;
  }
  .slideInOut {
    align-items: flex-start;
  }
  .monoSVG {
    width: 500px;
  }
  .animText {
    padding-left: 100px;
    width: 80%;
    line-height: 24px;
    font-size: 14px;
  }
  .animComponentOsc {
    top: -75px;
    left: -355px;
    width: 500px;
  }
}
@media (max-width: 600px) {
  .seeInActionTxt {
    font-size: 30px;
  }
  .seeArrowDiv {
    display: flex;
    right: 0px;
    left: 0px;

    justify-content: center;
  }
}
@media (max-width: 550px) {
  .monoAnimContainer {
    margin-top: 200px;
  }
  .contextualIntroHeadTxt {
    font-size: 50px;
  }
  .spModuleTxt {
    font-size: 50px;
  }
  .monoIntroParaTxt {
    width: 340px;
  }
  .animTrigger {
    height: 500px;
  }
  .lastAnimTrigger {
    height: 500px;
  }
}
