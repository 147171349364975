.expressiveCard > .mainApplyModule > .mainContainerApplyingMod{
    background-color:  #222726;;
}

.expressiveCard > .mainApplyModule > .mainContainerApplyingMod>.txtPartApplyMod > .paraApplyModTxt{
    color:#FFFFFF;

}

.expressiveCard > .mainApplyModule > .mainContainerApplyingMod>.txtPartApplyMod > .picPartUserAppMod > .nameApplyModHolder{
    color:#FFFFFF;

}
.expressiveCard > .mainApplyModule > .mainContainerApplyingMod>.txtPartApplyMod > .picPartUserAppMod > .nameApplyModHolder > .spCountryAppMod{
    color:#FFFFFF;

}