.mainContainerPureHarmonic {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.txtHowDoes {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
  padding-top: 170px;
}
.pureHarmonicTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 200;
  font-size: 125px;
  line-height: 1em;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Light mode (dark grey) */

  padding-top: 57px;
  color: #222726;
}
.underTheHoodTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
  width: 602px;
  padding-top: 65px;
}
.spTxtPureHar {
  font-weight: 600;
}
.purSynthSpe {
  padding-top: 162px;
}

.monoTxtStudio {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  /* Light mode (dark grey) */

  color: #222726;
}

.monoArrowDiv {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 120px;
  padding-right: 177px;
  padding-bottom: 50px;
}
.arrowLong {
  padding-left: 50px;
}
.purSynthSpe {
  width: 90% !important;
}

.subcontainerAnalogy {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.miniContainerAnaLogy {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.txtAnalogyPara {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
}
.mainContainerAnaLogMainPic {
  display: flex;

  padding-top: 150px;
  width: 100%;
  justify-content: space-evenly;
}
.learnMorePureTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Light mode (dark grey) */

  color: #222726;
}

.learnMorePureTxt:hover {
  color: #ff4203;
}

.analogyImg {
  font-size: 10px;
}
/* .mainContainerAnaLogMainPic{
    flex-direction: column;
} */
.top2OsciPics {
  position: relative;
}
#analogyImgId {
  position: absolute;
  left: -18px;
}
@media (max-width: 960px) {
  .mainContainerAnaLogMainPic {
    flex-direction: column;
  }

  .monoArrowDiv {
    padding-top: 130px;
  }
  .topMorphosArrowTZMF {
    padding-top: 130px;
  }
}
@media (max-width: 800px) {
  .pureHarmonicTxt {
    font-size: 70px;
  }
  .monoArrowDiv {
    padding-right: 0px;
    justify-content: center;
  }
  .monoTxtStudio {
    font-size: 30px;
  }
}

@media (max-width: 605px) {
  .underTheHoodTxt {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .purSynthSpe {
    width: 100% !important;
  }
}
@media (max-width: 415px) {
  .pureHarmonicTxt {
    font-size: 50px;
  }
  .arrowLong {
    padding-left: 10px;
  }
}
