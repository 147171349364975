.contactUsTopPage > .navbar {
  /* background: linear-gradient(0deg,#00A0A3,#D15F5B); */
  background-image: url("./../../images/headerBackgroudColor.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 991px) {
  .contactUsTopPage > .navbar {
    /* background: linear-gradient(0deg,#00A0A3,#D15F5B); */
    background-image: none;
    background-color: #222726 !important;
  }
  .contactUsTopPage > .navbar > .navbar-collapse {
    background-color: #222726 !important;
  }
}
