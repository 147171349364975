.mostStableTZFMTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}

.mainHeadingTZFM {
  font-family: "Chillax";
  padding-top: 60px;
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Light mode (dark grey) */

  color: #222726;
}
.spMainHeadingTZFM {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 200;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Light mode (dark grey) */

  color: #222726;
}
.mainParaTZFM {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  padding-top: 60px;

  /* Light mode (dark grey) */

  color: #222726;
  max-width: 610px;
  margin: auto;
}
.mainContainerTZFM {
  /* display: flex;
    flex-direction: column;
    align-items: center;
     */
}
.TZFMFirstRow {
  padding-top: 170px;
}

.extemlyStableTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  /* Light mode (dark grey) */

  color: #222726;
}
.pageRandTZFM {
  /* Body regular */

  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Light mode (dark grey) */

  color: #222726;
  padding-top: 20px;
}
.moreTxtTZFM {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Light mode (dark grey) */

  color: #222726;
  padding-top: 20px;
}
.secondRowTxtPartTZFM {
  max-width: 407px;
}
.moreAboutTop {
  display: flex;

  align-items: center;
}
.picArrow {
  margin-left: 10px;
  margin-top: 5px;
}

.TZFMVideoTop {
  margin-left: 100px;
}
.TZFMSecondRow {
  display: flex;
  padding-top: 174px;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}

.TZFMVideoTop > .video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  /* width: 800%; */
  height: 422px;
  padding: 0px !important;
  border-radius: 20px;
  width: 611px;
}
.picPortionMainTZFM {
  display: flex;

  max-width: 1200px;
  margin: auto;
}
.txtMorphosStudioTZMF {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  text-align: right;

  /* Light mode (dark grey) */

  color: #222726;
}
.topMorphosArrowTZMF {
  display: flex;
  justify-content: flex-end;
}
.dropArrowTZFM {
  padding-left: 50px;
}
.topMorphosArrowTZMF {
  padding-right: 177px;
  padding-top: 225px;
  padding-bottom: 283px;
}
.learnTxtTop {
  display: flex;
  justify-content: center;
}

@media (max-width: 1453px) {
  .topMorphosArrowTZMF {
    padding-right: 110px;
  }
}

@media (max-width: 1163px) {
  .TZFMVideoTop {
    margin-left: 20px;
  }
}

@media (max-width: 991px) {
  .TZFMVideoTop > .video-js {
    width: 95%;
  }

  .topMorphosArrowTZMF {
    padding-bottom: 328px;
  }
  .pageRandTZFM {
    text-align: center;
  }
  .extemlyStableTxt {
    text-align: center;
    font-size: 30px;
  }
  .TZFMVideoTop {
    margin-left: 0px;
  }
  .TZFMSecondRow {
    flex-direction: column;
    align-items: center;
  }
  .TZFMVideoTop > .video-react.video-react-fluid,
  .video-react.video-react-16-9,
  .video-react.video-react-4-3 {
    width: 85%;
  }
  .TZFMVideoTop {
    width: 100%;
    display: flex;

    justify-content: center;
    padding-top: 90px;
  }

  .mainHeadingTZFM {
    font-size: 70px;
    line-height: 90px;
  }
  .spMainHeadingTZFM {
    font-size: 70px;
    line-height: 90px;
  }
  .monoTZFM {
    width: 100%;
  }
  .topMorphosArrowTZMF {
    padding-right: 0px;
    justify-content: center;
  }
  .txtMorphosStudioTZMF {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .mainHeadingTZFM {
    font-size: 45px;
    line-height: 70px;
  }
  .spMainHeadingTZFM {
    font-size: 45px;
    line-height: 70px;
  }

  .mainParaTZFM {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .TZFMSecondRow {
    padding-top: 100px;
  }
  .TZFMVideoTop > .video-react.video-react-fluid,
  .video-react.video-react-16-9,
  .video-react.video-react-4-3 {
    width: 95%;
  }
  .extemlyStableTxt {
    line-height: 35px;
    font-size: 30px;
  }
}

@media (max-width: 455px) {
  .dropArrowTZFM {
    padding-left: 2px;
  }
  .txtMorphosStudioTZMF {
    font-size: 25px;
  }
}
