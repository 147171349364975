.mainFBANum {
  padding-top: 80px;
}
.frontBackBottomLine {
  height: 4px;
  width: 203px;
  background-color: #222726;
  transition: background-color 0.5s;
}
.frontBackTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 203px;
  /* identical to box height, or 150% */

  /* Light mode (dark grey) */

  color: #222726;
  transition: color 0.5s;
}
.frontBackAng {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 90%;
}
.numberFrontBack {
  display: flex;
  justify-content: center;
}
.imgMainFrontBack {
  display: flex;
  justify-content: center;
  max-width: 1480px;
  margin: auto;
}
.numberFrntTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}
.numberFrontBack {
  padding-top: 50px;
}
.imgMainFrontBack {
  padding-top: 50px;
}

.numberFrntTxt1 {
  display: none;
}
.frontBackTxt1 {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 203px;
  transition: color 0.5s;
  color: #ff4203;
}
.frontBackBottomLine1 {
  height: 4px;
  width: 203px;
  background-color: #ff4203;
  transition: background-color 0.5s;
}
.monoBack {
  width: 70%;
  padding-bottom: 100px;
}

.morphosBack {
  width: 40%;
  padding-bottom: 100px;
}

@media (max-width: 637px) {
  .frontBackAng {
    display: flex;

    flex-direction: column;
    justify-content: space-between;
  }
  .mainFBANum {
    display: flex;
    align-content: center;
    width: 100%;
    justify-content: space-around;
  }
  .monoBack {
    width: 95%;
  }
}
