.captureTacticMain {
  width: 100%;
  height: auto;
  background-image: url("./../../images/Gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.captureTxtMainPara {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  margin-top: 450px;
  /* or 107% */

  text-align: center;

  /* Dark mode (white) */

  color: #ffffff;
  width: 814px;
}
.spCaptureTact {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  text-align: center;

  /* Dark mode (white) */

  color: #20ead6;
}
.captureTacticMain {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.unionSmall {
  padding-top: 108px;
  padding-bottom: 130px;
}
.DSC04 {
  height: 397px;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 950px) {
  .captureTxtMainPara {
    padding-top: 250px;
  }
}
@media (max-width: 650px) {
  .captureTxtMainPara {
    padding-top: 200px;
  }

  .unionSmall {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}
@media (max-width: 832px) {
  .captureTxtMainPara {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 450px) {
  .spCaptureTact {
    font-size: 30px;
    line-height: 35px;
  }
  .captureTxtMainPara {
    font-size: 30px;
    line-height: 35px;
  }
}
