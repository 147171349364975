.txtTechSpec {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */
  padding-left: 87px;

  /* Dark mode (white) */

  color: #ffffff;
}

.technicalSpecHeader {
  display: flex;
  padding-top: 132px;
  padding-left: 146px;
  padding-bottom: 110px;
}
.mainTechSpec {
  background-color: #222726;
  width: 100%;
  height: auto;
  padding-bottom: 111px;
}
.topLineWhite {
  background: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.4;
  height: 2px;
  width: 85%;
}
.mainTechDetailPart {
  display: flex;
  align-items: center;
  flex-direction: column;

  height: auto;
}
.mediumTextTech {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */

  /* Dark mode (white) */

  color: #ffffff;

  mix-blend-mode: normal;
  opacity: 0.4;
}
.txtEndTechSpec {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  width: 407px;
  white-space: pre-wrap;
}
.firstTxtTechSpec {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */

  text-transform: capitalize;

  /* Dark mode (white) */

  color: #ffffff;
}

.textTechSpecDetailM {
  display: flex;
  justify-content: space-between;
  width: 85%;
  padding-top: 30px;
}
.pTopMediumTxt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pTopEndTxt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.w100Main {
  width: 58%;
}
.txtPad {
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
.none {
  display: none !important;
}

@media (max-width: 1141px) {
  .textTechSpecDetailM {
    width: 95%;
  }
  .topLineWhite {
    width: 95%;
  }
}
@media (max-width: 1003px) {
  .textTechSpecDetailM {
    width: 95%;
  }
  .topLineWhite {
    width: 95%;
  }
  .txtEndTechSpec {
    font-size: 12px;
  }
  .firstTxtTechSpec {
    font-size: 16px;
  }
  .mediumTextTech {
    font-size: 12px;
  }
  .w100Main {
    width: 72%;
  }
  .mainTechSpec {
    height: auto;
    padding-bottom: 100px;
  }
}
@media (max-width: 773px) {
  .textTechSpecDetailM {
    padding-top: 30px;
    flex-direction: column;
    align-items: center;
  }
  .txtPad {
    flex-direction: column;
    align-items: center;
  }
  .txtEndTechSpec {
    text-align: center;
  }
  .txtTechSpec {
    font-size: 25px;
  }
  .technicalSpecHeader {
    padding-left: 0px;
    justify-content: center;
  }
}

@media (max-width: 470px) {
  .txtTechSpec {
    font-size: 20px;
    padding-left: 20px;
  }
  .txtEndTechSpec {
    width: 100%;
  }
}
