.mainContainerSimplePowerful {
  width: 100%;
  height: auto;
  background-color: #222726;
  display: flex;
  padding-bottom: 204px;
  height: auto;

  flex-direction: column;
  align-items: center;
}

ul {
  padding-left: 0;
  list-style-type: none;
}

.doItAllTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;
  padding-bottom: 57px;

  /* Dark mode (white) */

  color: #ffffff;
  padding-top: 80px;
}
.simplePowerfulTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 300;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Dark mode (white) */

  color: #ffffff;
  padding-bottom: 065px;
  max-width: 699px;
}
.mainParaSiimplePower {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #ffffff;
  width: 90%;
  max-width: 600px;
}

.txtPartSimplPow {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spYetPowerTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Dark mode (white) */

  color: #ffffff;
}
.mainParaSpTxtSimpPow {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #ffffff;
}
.mainPicSimplePower {
  padding-top: 169px;
  padding-bottom: 306px;
  width: 75%;
}
.mainPicPart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 80px;
}

.txtSimpPowFirstRow {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  /* Dark mode (white) */

  color: #ffffff;
  max-width: 500px;
}
.subTxtSimpPowFirstRow {
  /* Body regular */

  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
  max-width: 300px;
}
.subTxtSimpPowFirstRow > ul {
  padding-left: 0;
}

.subTxtSimpPowFirstRow > ul > li {
  list-style-type: none;
}

.imgageLeftSimp {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

#undoRedo {
  margin-right: 20px;
}
.txtParaRightSimp {
  padding-left: 48px;
  width: 500px;
}
.lineCopy1 {
  position: absolute;
  bottom: -151px;
}
.learnMoreTxtSimp {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Dark mode (white) */

  color: #ffffff;
  margin-left: 16px;
}
.step1Img2 {
  position: absolute;
  left: 14px;
  z-index: 35;
  filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
.step1Img3 {
  position: absolute;
  left: 28px;
  z-index: 2;
  filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
.step1Img4 {
  position: absolute;
  left: 22px;
  z-index: 0;
  filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}

.step1Img {
  position: relative;
  z-index: 999;
  filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
.group64Img {
  position: absolute;
  left: 70px;
  filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
#cirlceLinePow1 {
  margin-left: 61px;
}
.lineCopySecond {
  position: absolute;
  bottom: -151px;
  z-index: 999;
  right: 64px;
}
#mainPicPart2 {
  margin-left: -46px;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}
#mainPicPart3 {
  margin-top: -40px;
  display: flex;
  justify-content: center;
  padding-right: 120px;
}
.mainPicPart2 {
  margin-top: -62px;
}
#lineCopy3 {
  position: absolute;
  bottom: -125px;
}
#mainPicPart5 {
  margin-top: 40px;
}
#mainPicPart6 {
  margin-top: 60px;
}
#mainPicPart7 {
  margin-top: 75px;
}
#mainPicPart6 {
}
.lowerDivSim {
  width: 80%;

  display: flex;

  flex-direction: column;
  align-items: center;
}
.upperDivSim {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.undoRedoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 429px;
}
@media (max-width: 1450px) {
  .lowerDivSim {
    width: 100%;
  }
}

@media (max-width: 1166px) {
  #lineCopy3 {
    top: 100px;
  }
  .lineCopy1 {
    position: absolute;
    bottom: -151px;
    height: 150px;
  }
  .firstInputSimpPow {
    width: 100px;
  }
  .step1Img {
    width: 100px;
  }
  .group64Img {
    width: 100px;
    left: 45px;
  }
  .step1Img3 {
    width: 100px;
  }
  .txtSimpPowFirstRow {
    font-size: 30px;
    line-height: 35px;
  }
  .subTxtSimpPowFirstRow {
    font-size: 12px;
  }
  .undoImg {
    width: 100px;
  }
  .redoImg {
    width: 100px;
  }
  .step1Img2 {
    width: 100px;
  }
  .cirlceLinePow1 {
    width: 72px;
  }
  .mainPicPart {
    padding-top: 50px;
    width: 100%;
  }
  #mainPicPart3 {
    margin-top: 20px;
    justify-content: center;
    padding-right: 0px;
  }
  #lineCopy1Id {
    height: 140px;
    bottom: -135px;
  }

  #endLineCopyID {
    height: 120px;
    bottom: -120px;
  }

  #lineCopy3 {
    height: 120px;
    bottom: -135px;
  }
  .undoRedoContainer {
    width: 251px;
  }

  #txtParaRightSimpId1 {
    padding-left: 50px;
  }
  #mainPicPart2 {
    margin-left: 0px;
  }
  .lineCopySecond {
    right: 20px;
    top: 100px;
  }
  .txtParaRightSimp {
    padding-left: 100px;
    width: 600px;
  }
  #txtParaRightSimp {
    padding-left: 24px;
  }
}

@media (max-width: 823px) {
  .simplePowerfulTxt {
    font-size: 70px;
    line-height: 90px;
  }
  .spYetPowerTxt {
    font-size: 70px;
    line-height: 90px;
  }
  .mainPicSimplePower {
    width: 95%;
  }

  .lowerDivSim {
    width: 80%;
  }
  #txtParaRightSimp {
    padding-left: 0px;
  }

  .upperDivSim {
    justify-content: center;
  }
  #mainPicPart3 {
    margin-left: -150px;
  }
  #mainPicPart2 {
    margin-left: -53px;
  }
}

@media (max-width: 751px) {
  .txtParaRightSimp {
    text-align: center;
    width: 100%;
    padding-left: 0;
  }
  .subTxtSimpPowFirstRow {
    max-width: 100%;
  }
  .txtSimpPowFirstRow {
    max-width: 100%;
  }
  .mainPicPart {
    flex-direction: column;
    padding-top: 0px;
  }
  #mainPicPart3 {
    margin-left: 0px;
  }
  .lowerDivSim {
    width: 80%;
  }
  .txtSimpPowFirstRow {
    font-size: 14px;
    line-height: 18px;
  }
  .subTxtSimpPowFirstRow {
    font-size: 10px;
    line-height: 16px;
  }

  #lineCopy1Id {
    height: 80px;
    bottom: -80px;
  }
  .learnMoreTxtSimp {
    font-size: 10px;
    line-height: 16px;
  }

  .upperDivSim {
    justify-content: center;
  }

  .lineCopy1 {
    bottom: -192px;
    height: 210px;
  }
  .lineCopySecond {
    bottom: -249px;
    height: 150px;
  }
  #lineCopy3 {
    bottom: -195px;
  }
  .cirlceLinePow1 {
    visibility: hidden;
  }
  #mainPicPart3 {
    margin-left: 0px;
  }
  #mainPicPart2 {
    margin-left: 0px;
  }
  #txtParaRightSimpId1 {
    padding-left: 0px;
  }
}

@media (max-width: 500px) {
  .simplePowerfulTxt {
    font-size: 50px;
    line-height: 65px;
  }
  .spYetPowerTxt {
    font-size: 50px;
    line-height: 65px;
  }
}
