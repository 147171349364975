.creativityMonoContainer {
  height: auto;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.mainCreativity {
  padding-top: 131px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: 125px;
}
.p1Creativity {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Dark mode (white) */

  color: #ffffff;
}

.headerCreativityNorand {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 200;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: -0.25em;
  width: auto;
}

.headerCreativityMono {
  font-weight: 600;
}

.headerCreativityMonoMK2 {
  font-weight: 300;
  color: #ffffff;
}

.btnOrderNow {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-size: 1rem;
  font-weight: 400;
  margin-top: 60px;
  margin-top: -70px;
  margin-bottom: 78px;

  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

.btnOrderNow:hover {
  background-color: #ff4203;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.6);
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

.cutMonoImg {
  width: 80%;
  max-width: 900px;
}

.cutMonoImgMK2 {
  width: 100%;
  max-width: 1200px;
}

.arrowButtonMonoProduct {
  margin-left: 20px;
}
.euroIcon {
  fill: #fff;
  margin-left: 5px;
  margin-top: -3px;
}

@media (max-width: 1038px) {
  .mainCreativity {
    font-size: 102px;
  }
}

@media (max-width: 850px) {
  .mainCreativity {
    font-size: 70px;
  }
}
@media (max-width: 749px) {
  .btnOrderNow {
    margin-top: -49px;
  }
}

@media (max-width: 630px) {
  .p1Creativity {
    font-size: 20px;
  }
  .mainCreativity {
    font-size: 50px;
  }
}
@media (max-width: 547px) {
  .btnOrderNow {
    margin-top: -32px;
  }
}
@media (max-width: 448px) {
  .p1Creativity {
    font-size: 15px;
  }
  .mainCreativity {
    font-size: 40px;
  }
}
