.ThreeDMorphicContainerPro {
  background-image: url("./../../images/3DBackground.png");

  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.innerDataContainer3DPro {
  padding-top: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
}
.d3MorphingTxtPro {
  font-family: "Chillax", sans-serif;
  line-height: 28px;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  color: #ffffff;
  text-align: center;
}
.tmTxtPro {
  position: absolute;
  margin-top: -13px;
  margin-top: -10px;
  font-size: 10px;
  margin-left: -20px;
}

.btnDisMorPro {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
}

.btnDisMorPro:hover {
  background-color: #ff4203;
  color: #ffffff;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.8);
}

.norMorHeadingTxt {
  text-align: center;
  font-size: 115px;
  line-height: 1em;
  color: #ffffff;
  font-family: "Chillax", sans-serif;
  font-style: normal;
  font-weight: 600;
}

.txtNorandPro {
  font-weight: 200;
}

.leftPic3dMorPro {
  display: flex;
  justify-content: center;
  margin-bottom: -50px;
}
.imgArrowPro {
  margin-left: -42px;
}
.buttonTopDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

@media (max-width: 1300px) {
  .norMorHeadingTxt {
    font-size: 75px;
  }
}

@media (max-width: 885px) {
  .norMorHeadingTxt {
    font-size: 62px;
  }
}
@media (max-width: 650px) {
  .norMorHeadingTxt {
    font-size: 48px;
  }
  .d3MorphingTxtPro {
    font-size: 18px;
  }
  .leftImg3MorPro {
    width: 90%;
  }
}
@media (max-width: 521px) {
  .norMorHeadingTxt {
    font-size: 40px;
  }
  .imgArrowPro {
    margin-left: 0px;
  }
}
@media (max-width: 439px) {
  .norMorHeadingTxt {
    font-size: 34px;
  }
}
