.about-section-1 {
  padding-top: 50px;
  display: flex;
  max-width: 1080px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #222726;
}

.about-section-1-left {
  display: flex;
}

.about-section-1-mid {
  margin-left: 100px;
  max-width: 70%;
}

.about-sectoin-primitive {
  max-width: 250px;
}

.about-section-1-right-txt {
  text-align: center;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.morphos-floating-img {
  position: relative;
  object-fit: contain;
  width: 350px;
  left: 10vw;
  transform: translateX(-75%);
  top: -100px;
}

.hide-overflow {
  margin-top: 200px;
  overflow: hidden;
  flex-basis: 25%;
}
.mono-floating-img {
  position: relative;
  width: 600px;
}

.about-section-2 {
  display: flex;
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 1em;
  margin-bottom: 50px;
  align-items: center;
  justify-content: space-between;
}

.about-section-2-mid {
  max-width: 35%;
}

.about-sectoin-biglive {
  max-width: 350px;
}

.about-section-3 {
  padding-top: 50px;
  display: flex;
  max-width: 1080px;
  margin: 0 auto;
  align-items: top;
  justify-content: space-between;
  margin-bottom: 50px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #222726;
}

.about-section-3-mid {
  margin-left: 60px;
  max-width: 60%;
}

.about-section-3-left {
  display: flex;
  margin-left: 50px;
}

.about-section-4 {
  background-image: url("./../../images/3DBackground.png");
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -150px;
  padding-top: 150px;
  padding-bottom: 50px;
  font-size: 125px;
}

.about-section-4-cards {
  max-width: 1350px;
  margin: auto;
  margin-top: 100px;
}

.about-section-4-card {
  max-width: 550px;
  background-color: white;
  border-radius: 25px;
  filter: drop-shadow(0px 6px 35px rgba(0, 0, 0, 0.25));
  display: flex;
  align-items: center;
  padding: 15px 40px;
  margin-bottom: 80px;
}

.about-section-4-2cards {
  margin: auto;
  justify-content: space-around;
  display: flex;
  max-width: 100%;
}
.card-pic {
  margin-right: 30px;
}
.about-section-4-card > .card-text {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #222726;
}

.card-text-title {
  font-weight: 600;
  font-size: 25px;
}

.about-section-5-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-text {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #222726;
  line-height: 1em;
  margin: 0;
  margin-top: 10px;
}

.about-section-5-cards {
  display: flex;
  margin: auto;
  justify-content: space-around;
  max-width: 1000px;
  margin-bottom: 100px;
}

.face-for-real {
  width: 200px;
}

.tiret-for-real {
  width: 10px;
}

.team-subtext {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 2em;
  color: #222726;
  margin: 0;
}

@media screen and (max-width: 1350px) {
  .card-text {
    font-size: 14px;
  }

  .about-section-4-card {
    max-width: 500px;
    margin-bottom: 40px;
  }
  .card-text-title {
    font-size: 18px;
  }
  .card-pic-for-real {
    width: 100px;
  }
}

@media screen and (max-width: 1080px) {
  .about-section-1 {
    flex-direction: column;
    align-items: center;
  }

  .about-section-2 {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .hide-overflow {
    display: none;
  }

  .about-section-2-mid {
    max-width: 75%;
    min-width: 250px;
  }

  .morphos-floating-img {
    display: none;
  }

  .about-section-1-left {
    max-width: 90%;
    justify-content: center;
    margin-bottom: 50px;
  }

  .about-section-1-mid {
    margin-left: 30px;
  }

  .about-section-1-right-txt {
    font-size: 14px;
  }

  .about-section-3 {
    flex-direction: column;
    align-items: center;
  }

  .about-section-3-left {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    text-align: center;
  }
  .about-section-3-mid {
    margin-left: 0;
  }
  .about-sectoin-biglive {
    display: none;
  }
  .about-section-4 {
    margin-top: 0px;
    padding-top: 80px;
  }
  .about-section-4-2cards {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .about-section-4-card {
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    padding: 15px 20px;
    max-width: 90%;
    text-align: center;
  }
  .card-pic {
    margin-right: 0px;
  }
  .card-text {
    text-align: center;
  }
  .about-section-5-cards {
    flex-direction: column;
  }
}
