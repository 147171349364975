.playButtonClau {
  background: white;
  color: white;
  border: none;
  width: 110px;
  height: 110px;
  border-radius: 100%;
  outline: none;
  text-transform: uppercase;
  transition: all 0.2s ease;
}

.playlist-item {
  color: #444;

  cursor: pointer;
  padding: 1em;
}

.playlist-item.selected {
  font-weight: bold;
}

.playlist-item:hover {
  background: rgba(0, 0, 0, 0.1);
}

.controls {
  /* padding: 1em; */
}

#waveform {
  width: 400px;
  margin-left: 15px;
  padding-top: 25px;
}
.playButtonClau svg path {
  stroke: #ff4203 !important;
}
.playIconReact {
  font-size: 37px;
  stroke: #ff4203 !important;
  fill: #ff4203;
}
.pauseIconReact {
  font-size: 37px;
  fill: #ff4203;
}
.mainContainerClaudeIns {
  max-height: 240px;
  width: 500px;
  background-color: #ff4203;
  border-radius: 20px;
  margin-top: 150px;
  position: relative;
  left: -200px;
  margin-left: 31px;
}

.musicAudioTop {
}

.mainAudioWave {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding-top: 46px;
  padding-left: 30px;
}
.paraTxtClauseIntro {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}
.birdRecTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}

.txtPartClaude {
  padding-top: 23px;
  padding-left: 60px;
}

.paraUsaClaude {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 1em;
  /* or 107% */

  /* Dark mode (white) */

  color: #ffffff;
}
.txtUsaClaude {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  padding-bottom: 120px;
  margin-left: -100px;
  margin-right: 50px;
}
.nameInsClaude {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}
.countryIns {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.picPartInsClaud {
  display: flex;
}
.imgClaude {
  margin-right: 25px;
  width: 80px;
}
.mainContainerClaudeInsTop {
  display: flex;
  justify-content: center;
  /* max-width: 1115px; */
  background-color: #222726;
  height: auto;
  width: 85%;
  max-width: 1800px;
  justify-content: flex-start;
  position: absolute;
  top: -280px;
}
.main {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.topClaudeMor > .main > .mainContainerClaudeInsTop {
  background: #ffffff;
}

.topClaudeMor
  > .main
  > .mainContainerClaudeInsTop
  > .txtUsaClaude
  > .paraUsaClaude {
  color: #222726;
}

.topClaudeMor
  > .main
  > .mainContainerClaudeInsTop
  > .txtUsaClaude
  > .picPartInsClaud
  > .nameInsClaude {
  color: #222726;
}

@media (min-width: 1800px) {
  .txtUsaClaude {
    margin-left: -40px;
  }
}

@media (max-width: 1277px) {
  .mainContainerClaudeIns {
    margin-left: 60px;
  }
}

@media (max-width: 1117px) {
  .mainContainerClaudeIns {
    margin-left: 80px;
  }
  .txtUsaClaude {
    margin-left: -133px;
  }
  .paraUsaClaude {
    font-size: 40px;
  }
}

@media (max-width: 950px) {
  .mainContainerClaudeInsTop {
    display: flex;
    justify-content: center;
    /* max-width: 1115px; */
    background-color: #222726;
    height: auto;
    width: 100%;
    justify-content: flex-start;
    position: absolute;
    /* top: -280px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mainContainerClaudeIns {
    margin-left: 0px;
    margin-top: 90px;
    left: 0px;
  }
  .txtUsaClaude {
    margin-left: 0px;
    margin-top: 90px;
  }
}
@media (max-width: 650px) {
  .mainContainerClaudeIns {
    width: 90%;
  }
  .mainAudioWave {
    padding-left: 25px;
  }
  .txtPartClaude {
    padding-left: 25px;
  }

  .playButtonClau {
    width: 80px;
    height: 80px;
  }
  #waveform {
    padding-top: 10px;
  }
  .txtUsaClaude {
    width: 90%;
    margin-left: 40px;
  }
  .paraUsaClaude {
    font-size: 30px;
    line-height: 30px;
  }
}
