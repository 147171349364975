.mainContainerContacts {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.headingContactUs {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 110px;

  color: #222726;
}

.iconcontact {
  fill: black;
  font-size: 24px;
  margin-right: -24px;
}

.inputTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  color: #222726;

  mix-blend-mode: normal;
  opacity: 0.4;
}
.inputFieldContact {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid;

  background-color: inherit;
  outline: none;
  padding-left: 10px;
  width: 600px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-left: 30px;
  color: #222726;
  resize: none;
}

.btnContactSubmit {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 78px;
  transition: box-shadow 0.5s;
}

.btnContactSubmit:hover {
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.5s;
}

/* .btnContactSubmit:hover {
    color: #733447;
} */

.topInputFieldContact {
  padding-top: 40px;
}

.arrowLeftBtnContact {
  margin-left: 20px;
}

.buttonTopContact {
  display: flex;
  justify-content: flex-end;
  /* width: 40%; */
  width: 600px;
}
@media (max-width: 650px) {
  .topInputFieldContact {
    width: 93%;
  }
  .inputFieldContact {
    width: 100%;
  }
  .buttonTopContact {
    width: 99%;
    padding-right: 10px;
  }
  .headingContactUs {
    font-size: 50px;
  }
}
