.mainContainerDigitBrain {
  background-repeat: no-repeat;

  background-size: cover;
  width: 100%;
  background-image: url("./../../images/digitaBrainMainPic.png");
}
.digiBrainPara {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */
  width: 800px;
  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
  text-align: center;
}
.spTxtDigi {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #20ead6;
}
.paraArrowPartDigi {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
}

.backPicDSC04771 {
  height: 397px;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 991px) {
  .paraArrowPartDigi {
    padding-top: 50px;
    padding-bottom: 80px;
  }
  .mainContainerDigitBrain {
    height: auto;
  }
}
@media (max-width: 825px) {
  .digiBrainPara {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 450px) {
  .digiBrainPara {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .digiBrainPara {
    font-size: 30px;
  }
  .spTxtDigi {
    font-size: 30px;
  }
}
