.mainContainerVco {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: white;
}
.morhposVCOPic {
  padding-top: 104px;
}

.firstPicM {
  width: 650px;
}
.mainParaVco {
  padding-top: 150px;
  /* Heading 2 semibold */

  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  text-align: center;
  width: 814px;
}
.dropArrowVco {
  padding-top: 60px;
  padding-bottom: 117px;
}

.disTxtVco {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #222726;
  margin-bottom: 0px;
}

.disTxtVco:hover {
  color: #ff4203;
}
.spVcoMainPara {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  text-align: center;
  width: 814px;
  color: #20ead6;
}
.mainPicVCO {
  padding-top: 100px;
  /* width: 100%;
     justify-content: center;
     flex-direction: column;
     align-items: center; */
}
.d3TxtVcoUp {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */

  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}
.d3TxtVcoUpSp {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */

  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}
.firstRowVcoTxtUp {
  display: flex;
  justify-content: space-between;
}
.imgTopMorPRoMain {
  display: flex;
  justify-content: center;
  position: relative;
  width: 1000px;
}
.contextModTop {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 650px;
}
.topPeepCircleIconVco {
  position: absolute;
  left: 450px;
  top: -20px;
}
.touchHereCircle {
  position: absolute;
  top: 45px;
  left: -214px;
}
.circledLine1 {
  position: absolute;
  top: -60px;
  left: -160px;
}
.firstRowLeftTxt {
  text-align: right;
  margin-left: 80px;
}
.topPeepCircleIconVco1 {
  position: absolute;
  right: 250px;
  top: -100px;
}
.touchHereCircle1 {
  position: absolute;
  top: 250px;
  left: -150px;
}
.circledLine11 {
  position: absolute;
  top: 20px;
  left: -100px;
}
.topPeepCircleIconVco2 {
  position: absolute;
  bottom: 140px;
  right: 400px;
}
.touchHereCircle2 {
  position: absolute;
  top: -88px;
  left: -47px;
}
.circledLine2 {
  position: absolute;
  top: 40px;
  left: 5px;
}

@media (max-width: 1080px) {
  .imgTopMorPRoMain {
    width: 800px;
  }
  .firstRowVcoTxtUp {
    width: 800px;
  }
  .contextModTop {
    margin-left: 450px;
  }
}
@media (max-width: 825px) {
  .firstPicM {
    width: 500px;
  }
  .mainParaVco {
    width: auto;
    font-size: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .spVcoMainPara {
    font-size: 30px;
  }
  .morhposVCOPic {
    width: 97%;
  }

  .d3TxtVcoUp {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
  }
  .d3TxtVcoUpSp {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
  }

  .firstRowVcoTxtUp {
    width: 600px;
  }
  .imgTopMorPRoMain {
    justify-content: center;
    width: auto;
  }
  .mainPicVCO {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contextModTop {
    width: auto;
    align-items: unset;
  }
  .tzfmAnalogOsciTop {
    padding-left: 50px;
  }

  .topPeepCircleIconVco {
    position: absolute;
    left: 490px;
    top: -5px;
  }

  .topPeepCircleIconVco1 {
    position: absolute;
    right: 240px;
    top: -88px;
  }
  .topPeepCircleIconVco2 {
    position: absolute;
    bottom: 140px;
    right: 160px;
  }
}

@media (max-width: 600px) {
  .mainParaVco {
    font-size: 25px;
    line-height: 30px;
  }
  .spVcoMainPara {
    font-size: 25px;
    line-height: 30px;
  }
  .mainParaVco {
    padding-top: 130px;
  }

  .dropArrowVco {
    padding-top: 50px;
    padding-bottom: 40px;
  }
}

@media (max-width: 506px) {
  .touchHereCircle {
    width: 66px;

    left: -185px;
    top: 40px;
  }
  .topPeepCircleIconVco {
    top: 30px;
    left: 220px;
  }

  .touchHereCircle1 {
    top: 152px;
    left: -130px;
    width: 66px;
  }

  .firstPicM {
    width: 350px;
    object-fit: cover;
  }
  .circledLine11 {
    height: 140px;
  }
  .touchHereCircle2 {
    top: -20px;
    left: -22px;
    width: 66px;
  }
  .topPeepCircleIconVco1 {
    right: -50px;
    top: -72px;
  }
  .topPeepCircleIconVco2 {
    bottom: 140px;
    right: 240px;
  }
  .firstRowVcoTxtUp {
    width: 350px;
  }
  .firstRowLeftTxt {
    margin-left: 0px;
  }
  .tzfmAnalogOsciTop {
    padding-left: 0px;
    padding-bottom: 30px;
  }
  .contextModTop {
    margin-left: 120px;
  }
}
