.footerMainContainer {
  width: 100%;
  height: 210px;
  background-color: #222726;
  display: flex;
  justify-content: space-between;
  padding-left: 35px;
}
.footerNorandcol1 {
  width: 34%;
  display: flex;
  justify-content: space-around;
  align-items: top;
}
.footerNorandCol11 {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.footerNorandcol2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding-right: 25px;
}
.stayLoopTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;

  mix-blend-mode: normal;
  opacity: 0.4;
}
.emailTxt {
  fill: white;
  font-size: 24px;
  margin-right: -24px;
}
.inputEmailFooter {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 50px;
  width: 420px;
  padding-left: 30px;
  border: none;
  border-bottom: 3px solid #ffffff;
  width: 100%;
  color: white;
  background-color: inherit;
}

.inputEmailFooter:focus {
  border: none;
  border-bottom: 3px solid #ffffff;
}

.btnSubsFooter {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background: none;
  border: none;
  color: #ffffff;
  position: absolute;
  margin-left: -110px;
  margin-top: 10px;
  transition: color 0.3s;
}

.btnSubsFooter:hover {
  color: #ff4203;
  transition: color 0.3s;
}

.txtDataProtFooter {
  color: #ffffff;

  mix-blend-mode: normal;
  opacity: 0.4;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-top: 5px;
  width: 100%;
}
.topFooterNorand {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  mix-blend-mode: normal;
  opacity: 0.4;
}
.bottomContFooterNorand {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  transition: color 0.3s;
}

.bottomContFooterNorand:hover {
  color: #adadad;
  transition: color 0.3s;
}
.secondRowFooter {
  height: 120px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #222726;
  align-items: center;
  padding-left: 35px;
}
.socialIconFooterBack {
  height: 35px;
  width: 35px;
  background-color: #ffffff;
  color: #222726;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s !important;
}
.socialIconFooterBack:hover {
  background-color: #ff4203;
  color: #222726;
  transition: background-color 0.3s !important;
}
.socialIconsContainerFooter {
  display: flex;
  justify-content: space-evenly;
  width: 200px;
}
.socialIconFooter {
  font-size: 20px;
}
.thirdRowFooter {
  height: 80px;
  width: 100%;
  background-color: #222726;
  display: flex;
  justify-content: space-between;
  padding-left: 31px;
  padding-top: 15px;
  padding-right: 20px;
}
.thirdRRight {
  display: flex;
  width: 580px;
  justify-content: space-between;
}
.thirdRowContent {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.thirdRowContent:hover {
  color: #ff4203;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.footerSelect {
  width: 120px;
}
.flagImg {
  height: 30px;
  width: 30px;
  border-radius: 60px;
}
.thirdRowCol1 {
  width: 13%;
  display: flex;
  justify-content: space-between;
  width: 128px;
}

.footerMainContainer {
  --bs-gutter-x: 0em;
}
.secondRowFooter {
  --bs-gutter-x: 0em;
}
.selectFooter {
  background-color: #222726;
  border: none;
  color: #ffff;
  height: 39px;
}
.mainTopFooterForback {
  background-color: #222726;
}
@media (max-width: 1400px) {
  .thirdRRight {
    width: 422px;
  }
  .footerNorandImg {
    max-width: 350px;
  }
}
@media (max-width: 768px) {
  .footerMainContainer {
    justify-content: center;
    height: auto;
  }
  .footerNorandcol1 {
    width: 85%;
  }
  .footerNorandcol2 {
    width: 85%;
    margin-top: 50px;
  }
  .secondRowFooter {
    display: flex;
    justify-content: center;
    height: auto;
    padding-top: 40px;
  }
  .footerLogo {
    display: flex;
    justify-content: center;
  }
  .footerNorandcol1 {
    padding-top: 45px;
  }
  .footerMainContainer {
    padding-left: 0px;
  }
  .socialIconsContainerFooter {
    margin-top: 32px;
  }
  .thirdRowFooter {
    padding-top: 80px;
    padding-bottom: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .thirdRRight {
    margin-top: 20px;
  }
  .secondRowFooter {
    padding-left: 0px;
  }
  .footerNorandImg {
    width: 360px;
  }
}

@media (max-width: 461px) {
  .thirdRRight {
    width: 100%;
  }
  .thirdRowContent {
    font-size: 12px;
  }
}
@media (max-width: 370px) {
  .thirdRowContent {
    font-size: 10px;
  }
}
