@font-face {
  font-family: "Chillax";
  src: url(/static/media/Chillax-Extralight.3b24ab06.woff2) format("woff2"),
    url(/static/media/Chillax-Extralight.71926bb9.woff) format("woff"),
    url(/static/media/Chillax-Extralight.90500598.ttf) format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Chillax";
  src: url(/static/media/Chillax-Light.f74b5fae.woff2) format("woff2"),
    url(/static/media/Chillax-Light.ab506642.woff) format("woff"),
    url(/static/media/Chillax-Light.3cb6abd5.ttf) format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Chillax";
  src: url(/static/media/Chillax-Regular.9066b316.woff2) format("woff2"),
    url(/static/media/Chillax-Regular.bc17b11e.woff) format("woff"),
    url(/static/media/Chillax-Regular.684a2f05.ttf) format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Chillax";
  src: url(/static/media/Chillax-Medium.a184bf67.woff2) format("woff2"),
    url(/static/media/Chillax-Medium.bf7afbe7.woff) format("woff"),
    url(/static/media/Chillax-Medium.1f167904.ttf) format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Chillax";
  src: url(/static/media/Chillax-Semibold.58e428ff.woff2) format("woff2"),
    url(/static/media/Chillax-Semibold.9fbcb2d9.woff) format("woff"),
    url(/static/media/Chillax-Semibold.61d5bc77.ttf) format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Chillax";
  src: url(/static/media/Chillax-Bold.10751889.woff2) format("woff2"),
    url(/static/media/Chillax-Bold.66ea641e.woff) format("woff"),
    url(/static/media/Chillax-Bold.e5a3d197.ttf) format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url(/static/media/archia-thin-webfont.47f5c030.eot);
  src: url(/static/media/archia-thin-webfont.47f5c030.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/archia-thin-webfont.e88f1cf3.woff2) format("woff2"),
    url(/static/media/archia-thin-webfont.0345166e.woff) format("woff"),
    url(/static/media/archia-thin-webfont.9a733c1c.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url(/static/media/archia-light-webfont.bd903ad6.eot);
  src: url(/static/media/archia-light-webfont.bd903ad6.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/archia-light-webfont.e1f0cf91.woff2) format("woff2"),
    url(/static/media/archia-light-webfont.119d7372.woff) format("woff"),
    url(/static/media/archia-light-webfont.a1a88c46.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url(/static/media/archia-regular-webfont.e0d9eab3.eot);
  src: url(/static/media/archia-regular-webfont.e0d9eab3.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/archia-regular-webfont.02155d96.woff2) format("woff2"),
    url(/static/media/archia-regular-webfont.571e4671.woff) format("woff"),
    url(/static/media/archia-regular-webfont.44b223ad.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url(/static/media/archia-medium-webfont.93462af3.eot);
  src: url(/static/media/archia-medium-webfont.93462af3.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/archia-medium-webfont.80da55a5.woff2) format("woff2"),
    url(/static/media/archia-medium-webfont.b325c0d3.woff) format("woff"),
    url(/static/media/archia-medium-webfont.0a3767d8.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url(/static/media/archia-semibold-webfont.90c5d848.eot);
  src: url(/static/media/archia-semibold-webfont.90c5d848.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/archia-semibold-webfont.890ee929.woff2) format("woff2"),
    url(/static/media/archia-semibold-webfont.21351f93.woff) format("woff"),
    url(/static/media/archia-semibold-webfont.6f45094f.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Archia";
  src: url(/static/media/archia-bold-webfont.86e07a42.eot);
  src: url(/static/media/archia-bold-webfont.86e07a42.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/archia-bold-webfont.ad8463d1.woff2) format("woff2"),
    url(/static/media/archia-bold-webfont.a22268c8.woff) format("woff"),
    url(/static/media/archia-bold-webfont.9bc3d640.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

textarea:focus,
input:focus {
  outline: none;
}
a {
  text-decoration: none !important;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

a:hover {
  text-decoration: none !important;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

.my-alert-success {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  background-color: #ff4203 !important;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4) !important;
  color: white !important;
  border: none !important;
  border-radius: 25px !important;
  font-family: "Archia" !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #ffffff !important;
  min-width: 250px !important;
  width: 50% !important;
}

.my-alert-chill {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  background-color: #ff4203 !important;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4) !important;
  color: white !important;
  border: none !important;
  border-radius: 25px !important;
  font-family: "Archia" !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #ffffff !important;
  width: 50% !important;
  max-width: 600px;
}
@media (max-width: 1080px) {
  .my-alert-chill {
    text-align: center;
    width: 90% !important;
    flex-direction: column;
  }
}

.imgArrow {
  margin-left: 20px;
}

textarea {
  resize: none;
}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 150px;
  height: 150px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  -webkit-animation: spinner 1.5s linear infinite;
          animation: spinner 1.5s linear infinite;
  margin: auto;
}

.backdrop-blur {
  display: flex;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  padding: 0;
  background: linear-gradient(
      217deg,
      rgba(255, 56, 3, 0.8),
      rgba(255, 56, 3, 0) 70.71%
    ),
    linear-gradient(
      127deg,
      rgba(32, 234, 214, 0.8),
      rgba(33, 234, 214, 0) 70.71%
    ),
    linear-gradient(336deg, rgba(34, 39, 38, 0.8), rgba(34, 39, 38, 0) 70.71%);
}

.anim-bg {
  filter: blur(50px);
  -webkit-filter: blur(50px);
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.anim-form-1 {
  position: absolute;
  z-index: -1;
  -webkit-clip-path: polygon(
    88% 18%,
    60% 30%,
    66% 62%,
    80% 90%,
    39% 85%,
    21% 47%,
    26% 17%,
    64% 5%
  );
          clip-path: polygon(
    88% 18%,
    60% 30%,
    66% 62%,
    80% 90%,
    39% 85%,
    21% 47%,
    26% 17%,
    64% 5%
  );
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ed1313;
  animation: move-around-1 12s ease-in-out infinite;
  -webkit-animation: move-around-1 12s ease-in-out infinite;
}

@-webkit-keyframes move-around-1 {
  from {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(33%, 0) rotate(30deg);
            transform: translate(33%, 0) rotate(30deg);
  }
  40% {
    -webkit-transform: translate(25%, 50%) rotate(-10deg);
            transform: translate(25%, 50%) rotate(-10deg);
  }
  60% {
    -webkit-transform: translate(55%, 20%) rotate(-22deg);
            transform: translate(55%, 20%) rotate(-22deg);
  }
  80% {
    -webkit-transform: translate(20%, 50%) rotate(-21deg);
            transform: translate(20%, 50%) rotate(-21deg);
  }
  to {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes move-around-1 {
  from {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(33%, 0) rotate(30deg);
            transform: translate(33%, 0) rotate(30deg);
  }
  40% {
    -webkit-transform: translate(25%, 50%) rotate(-10deg);
            transform: translate(25%, 50%) rotate(-10deg);
  }
  60% {
    -webkit-transform: translate(55%, 20%) rotate(-22deg);
            transform: translate(55%, 20%) rotate(-22deg);
  }
  80% {
    -webkit-transform: translate(20%, 50%) rotate(-21deg);
            transform: translate(20%, 50%) rotate(-21deg);
  }
  to {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg);
  }
}

.anim-form-2 {
  position: absolute;
  z-index: -1;
  -webkit-clip-path: polygon(
    85% 14%,
    44% 13%,
    15% 49%,
    34% 79%,
    45% 42%,
    57% 20%,
    74% 44%
  );
          clip-path: polygon(
    85% 14%,
    44% 13%,
    15% 49%,
    34% 79%,
    45% 42%,
    57% 20%,
    74% 44%
  );
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #13b3ed;
  animation: move-around-2 11s ease-in-out infinite;
  -webkit-animation: move-around-2 11s ease-in-out infinite;
}

@-webkit-keyframes move-around-2 {
  from {
    -webkit-transform: translate(0, 0) rotate(90deg);
            transform: translate(0, 0) rotate(90deg);
  }
  20% {
    -webkit-transform: translate(10%, 30%) rotate(122deg);
            transform: translate(10%, 30%) rotate(122deg);
  }
  40% {
    -webkit-transform: translate(-10%, 50%) rotate(76deg);
            transform: translate(-10%, 50%) rotate(76deg);
  }
  60% {
    -webkit-transform: translate(18%, 33%) rotate(60deg);
            transform: translate(18%, 33%) rotate(60deg);
  }
  80% {
    -webkit-transform: translate(12%, 12%) rotate(45deg);
            transform: translate(12%, 12%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(0, 0) rotate(90deg);
            transform: translate(0, 0) rotate(90deg);
  }
}

@keyframes move-around-2 {
  from {
    -webkit-transform: translate(0, 0) rotate(90deg);
            transform: translate(0, 0) rotate(90deg);
  }
  20% {
    -webkit-transform: translate(10%, 30%) rotate(122deg);
            transform: translate(10%, 30%) rotate(122deg);
  }
  40% {
    -webkit-transform: translate(-10%, 50%) rotate(76deg);
            transform: translate(-10%, 50%) rotate(76deg);
  }
  60% {
    -webkit-transform: translate(18%, 33%) rotate(60deg);
            transform: translate(18%, 33%) rotate(60deg);
  }
  80% {
    -webkit-transform: translate(12%, 12%) rotate(45deg);
            transform: translate(12%, 12%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(0, 0) rotate(90deg);
            transform: translate(0, 0) rotate(90deg);
  }
}

.anim-form-3 {
  position: absolute;
  z-index: -1;
  -webkit-clip-path: polygon(70% 11%, 17% 11%, 17% 38%, 18% 79%, 65% 56%, 58% 29%);
          clip-path: polygon(70% 11%, 17% 11%, 17% 38%, 18% 79%, 65% 56%, 58% 29%);
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #13edba;
  animation: move-around-3 14s ease-in-out infinite;
  -webkit-animation: move-around-3 14s ease-in-out infinite;
}

@-webkit-keyframes move-around-3 {
  from {
    -webkit-transform: translate(0, 0) rotate(90deg);
            transform: translate(0, 0) rotate(90deg);
  }
  20% {
    -webkit-transform: translate(-15%, 22%) rotate(42deg);
            transform: translate(-15%, 22%) rotate(42deg);
  }
  40% {
    -webkit-transform: translate(-24%, 17%) rotate(76deg);
            transform: translate(-24%, 17%) rotate(76deg);
  }
  60% {
    -webkit-transform: translate(18%, 33%) rotate(22deg);
            transform: translate(18%, 33%) rotate(22deg);
  }
  80% {
    -webkit-transform: translate(33%, -12%) rotate(45deg);
            transform: translate(33%, -12%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(0, 0) rotate(90deg);
            transform: translate(0, 0) rotate(90deg);
  }
}

@keyframes move-around-3 {
  from {
    -webkit-transform: translate(0, 0) rotate(90deg);
            transform: translate(0, 0) rotate(90deg);
  }
  20% {
    -webkit-transform: translate(-15%, 22%) rotate(42deg);
            transform: translate(-15%, 22%) rotate(42deg);
  }
  40% {
    -webkit-transform: translate(-24%, 17%) rotate(76deg);
            transform: translate(-24%, 17%) rotate(76deg);
  }
  60% {
    -webkit-transform: translate(18%, 33%) rotate(22deg);
            transform: translate(18%, 33%) rotate(22deg);
  }
  80% {
    -webkit-transform: translate(33%, -12%) rotate(45deg);
            transform: translate(33%, -12%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(0, 0) rotate(90deg);
            transform: translate(0, 0) rotate(90deg);
  }
}

.anim-form-4 {
  position: absolute;
  z-index: -1;
  -webkit-clip-path: polygon(
    23% 23%,
    20% 84%,
    75% 63%,
    42% 55%,
    45% 21%,
    85% 10%,
    59% 6%,
    20% 6%,
    6% 26%
  );
          clip-path: polygon(
    23% 23%,
    20% 84%,
    75% 63%,
    42% 55%,
    45% 21%,
    85% 10%,
    59% 6%,
    20% 6%,
    6% 26%
  );
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ed4d13;
  animation: move-around-4 15s ease-in-out infinite;
  -webkit-animation: move-around-4 15s ease-in-out infinite;
}

@-webkit-keyframes move-around-4 {
  from {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(-11%, -22%) rotate(-42deg);
            transform: translate(-11%, -22%) rotate(-42deg);
  }
  40% {
    -webkit-transform: translate(-18%, 5%) rotate(-21deg);
            transform: translate(-18%, 5%) rotate(-21deg);
  }
  60% {
    -webkit-transform: translate(-4%, 25%) rotate(17deg);
            transform: translate(-4%, 25%) rotate(17deg);
  }
  80% {
    -webkit-transform: translate(33%, -12%) rotate(45deg);
            transform: translate(33%, -12%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes move-around-4 {
  from {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(-11%, -22%) rotate(-42deg);
            transform: translate(-11%, -22%) rotate(-42deg);
  }
  40% {
    -webkit-transform: translate(-18%, 5%) rotate(-21deg);
            transform: translate(-18%, 5%) rotate(-21deg);
  }
  60% {
    -webkit-transform: translate(-4%, 25%) rotate(17deg);
            transform: translate(-4%, 25%) rotate(17deg);
  }
  80% {
    -webkit-transform: translate(33%, -12%) rotate(45deg);
            transform: translate(33%, -12%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg);
  }
}

.anim-form-5 {
  position: absolute;
  z-index: -1;
  -webkit-clip-path: polygon(
    82% 18%,
    63% 69%,
    69% 83%,
    36% 84%,
    20% 46%,
    42% 53%,
    40% 23%
  );
          clip-path: polygon(
    82% 18%,
    63% 69%,
    69% 83%,
    36% 84%,
    20% 46%,
    42% 53%,
    40% 23%
  );
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #32c1a6;
  animation: move-around-5 12.5s ease-in-out infinite;
  -webkit-animation: move-around-5 12.5s ease-in-out infinite;
}

@-webkit-keyframes move-around-5 {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(-11%, -22%) rotate(32deg);
            transform: translate(-11%, -22%) rotate(32deg);
  }
  40% {
    -webkit-transform: translate(-18%, -35%) rotate(17deg);
            transform: translate(-18%, -35%) rotate(17deg);
  }
  60% {
    -webkit-transform: translate(-42%, -12%) rotate(17deg);
            transform: translate(-42%, -12%) rotate(17deg);
  }
  80% {
    -webkit-transform: translate(-33%, -24%) rotate(45deg);
            transform: translate(-33%, -24%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
}

@keyframes move-around-5 {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(-11%, -22%) rotate(32deg);
            transform: translate(-11%, -22%) rotate(32deg);
  }
  40% {
    -webkit-transform: translate(-18%, -35%) rotate(17deg);
            transform: translate(-18%, -35%) rotate(17deg);
  }
  60% {
    -webkit-transform: translate(-42%, -12%) rotate(17deg);
            transform: translate(-42%, -12%) rotate(17deg);
  }
  80% {
    -webkit-transform: translate(-33%, -24%) rotate(45deg);
            transform: translate(-33%, -24%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
}

.anim-form-6 {
  position: absolute;
  z-index: -1;
  -webkit-clip-path: polygon(
    85% 14%,
    44% 13%,
    15% 49%,
    34% 79%,
    45% 42%,
    57% 20%,
    74% 44%
  );
          clip-path: polygon(
    85% 14%,
    44% 13%,
    15% 49%,
    34% 79%,
    45% 42%,
    57% 20%,
    74% 44%
  );
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3283c1;
  animation: move-around-6 15s ease-in-out infinite;
  -webkit-animation: move-around-6 15s ease-in-out infinite;
}

@-webkit-keyframes move-around-6 {
  from {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(0%, -22%) rotate(32deg);
            transform: translate(0%, -22%) rotate(32deg);
  }
  40% {
    -webkit-transform: translate(-18%, -35%) rotate(17deg);
            transform: translate(-18%, -35%) rotate(17deg);
  }
  60% {
    -webkit-transform: translate(-42%, -12%) rotate(17deg);
            transform: translate(-42%, -12%) rotate(17deg);
  }
  80% {
    -webkit-transform: translate(-33%, -24%) rotate(45deg);
            transform: translate(-33%, -24%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes move-around-6 {
  from {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(0%, -22%) rotate(32deg);
            transform: translate(0%, -22%) rotate(32deg);
  }
  40% {
    -webkit-transform: translate(-18%, -35%) rotate(17deg);
            transform: translate(-18%, -35%) rotate(17deg);
  }
  60% {
    -webkit-transform: translate(-42%, -12%) rotate(17deg);
            transform: translate(-42%, -12%) rotate(17deg);
  }
  80% {
    -webkit-transform: translate(-33%, -24%) rotate(45deg);
            transform: translate(-33%, -24%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg);
  }
}

.anim-form-7 {
  position: absolute;
  z-index: -1;
  -webkit-clip-path: polygon(
    88% 18%,
    60% 30%,
    66% 62%,
    80% 90%,
    39% 85%,
    21% 47%,
    26% 17%,
    64% 5%
  );
          clip-path: polygon(
    88% 18%,
    60% 30%,
    66% 62%,
    80% 90%,
    39% 85%,
    21% 47%,
    26% 17%,
    64% 5%
  );
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c13c32;
  animation: move-around-7 11.5s ease-in-out infinite;
  -webkit-animation: move-around-7 11.5s ease-in-out infinite;
}

@-webkit-keyframes move-around-7 {
  from {
    -webkit-transform: translate(0, 0) rotate(90deg);
            transform: translate(0, 0) rotate(90deg);
  }
  20% {
    -webkit-transform: translate(18%, 33%) rotate(22deg);
            transform: translate(18%, 33%) rotate(22deg);
  }
  40% {
    -webkit-transform: translate(-24%, 17%) rotate(76deg);
            transform: translate(-24%, 17%) rotate(76deg);
  }
  60% {
    -webkit-transform: translate(-15%, 22%) rotate(42deg);
            transform: translate(-15%, 22%) rotate(42deg);
  }
  80% {
    -webkit-transform: translate(33%, -12%) rotate(45deg);
            transform: translate(33%, -12%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(0, 0) rotate(90deg);
            transform: translate(0, 0) rotate(90deg);
  }
}

@keyframes move-around-7 {
  from {
    -webkit-transform: translate(0, 0) rotate(90deg);
            transform: translate(0, 0) rotate(90deg);
  }
  20% {
    -webkit-transform: translate(18%, 33%) rotate(22deg);
            transform: translate(18%, 33%) rotate(22deg);
  }
  40% {
    -webkit-transform: translate(-24%, 17%) rotate(76deg);
            transform: translate(-24%, 17%) rotate(76deg);
  }
  60% {
    -webkit-transform: translate(-15%, 22%) rotate(42deg);
            transform: translate(-15%, 22%) rotate(42deg);
  }
  80% {
    -webkit-transform: translate(33%, -12%) rotate(45deg);
            transform: translate(33%, -12%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(0, 0) rotate(90deg);
            transform: translate(0, 0) rotate(90deg);
  }
}


.accept-cookie-btn {
  background-color: white;
  margin-left: 50px;
  min-width: 140px;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #222726;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

.accept-cookie-btn:hover {
  background-color: white;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  color: #ff4203;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

#cookie-mask {
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: color 1s ease-in-out, background-color 1s ease-in-out,
    border-color 1s ease-in-out, box-shadow 1s ease-in-out !important;
}

#navbarCenterContent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  align-items: flex-end;
  padding-right: 160px;
}

.navbar-backdrop {
  min-height: 60px;
}
.navbar-no-backdrop {
  min-height: 60px;
}

.hide-header {
  display: none;
}

.newPicHeader {
  position: absolute;
  margin-left: -10px;
}
.centerContentTxtHeader {
  font-weight: 400 !important;
  font-style: normal !important;
  line-height: 30px !important;
  font-size: 16px !important;
  color: #ffffff !important;
  padding-left: 30px !important;
  margin-top: 5px;
  margin-bottom: 5px;

  font-family: "Archia";
  transition: color 0.3s !important;
}
.centerContentTxtHeader:hover {
  color: #ff4203 !important;
  transition: color 0.3s !important;
}

.headerLogo {
  padding-left: 25px;
  padding-bottom: 5px;
}
.rightIconPartHeader {
  padding-right: 20px;
}
.navbar-dark .navbar-toggler {
  margin-right: 20px;
}

.navbar-no-backdrop {
  background-color: rgba(34, 39, 38, 0);
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  transition: all 0.5s ease-in-out;
}

.navbar-backdrop {
  background-color: rgba(34, 39, 38, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transition: all 0.5s ease-in-out;
}

@media (max-width: 991px) {
  .rightIconPartHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0px;
    padding-left: 25px;
    align-items: flex-end;
    padding-right: 77px;
  }
  .responsive-navbar-nav {
    background-color: rgba(34, 39, 38, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  .navbar {
    background-color: rgba(34, 39, 38, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  .hide-header {
    display: flex;
  }
}

.contextual-home-main {
  min-height: 560px;
  background: #222726;
  background-size: cover;
  align-content: center;
  padding-bottom: 60px;
}

.norand-context-text {
  text-align: center;
}

.norand-context-1 {
  color: #ffffff;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: auto;
  max-width: 450px;
  padding-top: 80px;
}

.txtContextual {
  font-size: 115px;
  line-height: 95px;
  font-weight: 600;
  color: #ffffff;
  font-family: "Chillax", sans-serif;
  font-style: normal;
  margin-top: 50px;
}

.txtModulation {
  font-size: 115px;
  line-height: 95px;
  font-weight: 200;
  color: #ffffff;
  font-family: "Chillax", sans-serif;
  font-style: normal;
}

.context-video-player {
  width: 80%;
  height: 45vw;
  margin: auto;
  margin-top: 50px;
  max-width: 1280px;
  max-height: 725px;
  padding-bottom: 40px;
}

@media (max-width: 991px) {
  .txtContextual {
    font-size: 60px;
    line-height: 40px;
  }
  .txtModulation {
    font-size: 60px;
    line-height: 40px;
  }
}

@media (max-width: 480px) {
  .txtContextual {
    font-size: 36px;
    line-height: 24px;
  }
  .txtModulation {
    font-size: 36px;
    line-height: 24px;
  }
}

.innovative-main {
  background-image: url(/static/media/Gradient-2.07cd18f8.png);
  background-size: cover;
  padding-top: 10vw;
  padding-bottom: 10vw;
}

.mainInnovative {
  max-width: 800px;
  margin: auto;
}
.norand-phiolosphy {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  text-transform: capitalize;
  color: #222726;
}
.innovative-heading {
  padding-top: 2.5rem;
  font-family: "Chillax", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 125px;
  line-height: 1em;
  color: #222726;
}
.inspire-heading {
  font-family: "Chillax", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  font-weight: bold;
}
.inno-btn-div {
  margin-top: 2rem;
  margin-left: 189px;
  display: flex;
}

.inno-about-btn {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  border: none;
  justify-content: space-around;
  align-items: center;
  display: flex;
  font-family: "Archia";
  font-weight: 400;
  color: white;
}
.inno-about-btn:hover {
  background-color: #ff4203;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  color: white;
}

.inno-join-btn {
  background-color: white;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  color: #222726;
  border: none;
  justify-content: space-around;
  align-items: center;
  display: flex;
  font-family: "Archia";
  font-weight: 400;
  margin-left: 40px;
}

.inno-join-btn:hover {
  background-color: white;
  color: #8b9291;
}

@media only screen and (max-width: 1024px) {
  .norand-phiolosphy {
    padding-left: 100px !important;
  }
  .innovative-heading {
    padding-left: 100px !important;
    font-size: 100px !important;
  }
  .inno-btn-div {
    margin-left: 100px !important;
  }
  .inspire-heading {
    font-size: 100px !important;
  }
}
@media only screen and (max-width: 991px) {
  .inspire-heading {
    font-size: 80px !important;
  }
  .innovative-heading {
    font-size: 80px !important;
  }
}
@media only screen and (max-width: 767px) {
  .inspire-heading {
    font-size: 50px !important;
  }
  .innovative-heading {
    font-size: 50px !important;
    padding-left: 50px !important;
  }
  .norand-phiolosphy {
    padding-left: 50px !important;
  }
  .inno-btn-div {
    margin-left: 50px !important;
  }
}

@media (max-width: 660px) {
  .mainInnovative {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .norand-phiolosphy {
    padding-left: 0px !important;
  }

  .innovative-heading {
    padding-left: 0px !important;
    font-size: 40px !important;
  }
  .inspire-heading {
    font-size: 40px !important;
  }
  .inno-btn-div {
    margin-left: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.norand-mono-main {
}

.first-col {
  background-color: #ff4203;
  background-blend-mode: color;
  padding-bottom: 60px;
}
.second-col {
  background-image: url(/static/media/monobg.71ded982.png);
  background-size: cover;
  background-position: 25% 100%;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
}
.news-ptag {
  padding-top: 103px;
  padding-left: 85px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.new-norand {
  padding-left: 85px;
  padding-top: 10rem;
  font-family: "Chillax", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  text-transform: capitalize;
  color: #ffffff;
}
.mono-heading {
  padding-left: 75px;
  padding-top: 2rem;
  font-family: "Chillax", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 125px;
  line-height: 1em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 40px;
}
.btn-div {
  margin-top: auto;
  margin-bottom: 40px;
  margin-left: 80px;
}
.update-btn {
  background-color: white;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #222726;
  border: none;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
}

.update-btn:hover {
  background-color: white;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  color: #ff4203;
}

.arrow-icon {
  font-size: 20px !important;
  margin-left: 10px;
}
.norand-mono {
  color: #ffffff;
  padding-top: 60px;
  padding-left: 85px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
}
.norand-para {
  padding-left: 85px;
  padding-top: 30px;
  padding-bottom: 200px;
  color: #ffffff;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 60%;
}
.discover-btn {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #222726;
  border: none;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
  color: white;
}

.discover-btn:hover {
  background-color: #ff4203;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.8);
  color: white;
}

@media only screen and (max-width: 767px) {
  .mono-heading {
    font-size: 80px !important;
  }
}

@media (max-width: 600px) {
  .news-ptag {
    padding-left: 0px;
    padding-top: 31px;
  }
  .new-norand {
    padding-left: 0px;
  }
  .mono-heading {
    padding-left: 0px;
  }
  .first-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .new-norand {
    text-align: center;
    padding-top: 70px;
  }
  .mono-heading {
    text-align: center;
    font-size: 60px !important;
  }
  .btn-div {
    padding-left: 0px;
    margin-left: 0px;
  }
  .second-col {
    display: flex;
    flex-direction: column;

    align-items: center;
  }
  .norand-mono {
    padding-left: 0px;
    text-align: center;
  }
  .norand-para {
    padding-left: 0px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .btn-div {
    padding-left: 0px;
  }
}

.ThreeDMorphicContainer {
  /* background-image: url("./../../images/3DBackground.png");
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat; */
  /* padding-bottom: 40px; */
  position: relative;
}

.leftImg3MorPro {
  position: relative;
  width: 80%;
  max-width: 1200px;
}
.leftImg3Mor {
  width: 100%;
  max-width: 600px;
  object-fit: contain;
  margin-right: auto;
  margin-left: auto;
}
.innerDataContainer3D {
  padding-top: 119px;
  padding-left: 35px;
}

.d3MorphingTxt {
  font-family: "Chillax", sans-serif;
  line-height: 2em;
  font-weight: 400;
  font-size: 25px;
  color: #ffffff;
  padding-left: 10px;
  margin-bottom: 50px;
}
.tmTxt {
  position: absolute;
  margin-top: -13px;
  margin-top: -10px;
  font-size: 10px;
  margin-left: -20px;
}

.rightPartMorph {
  padding-top: 25px;
  font-size: 115px;
  color: #ffffff;
  font-family: "Chillax", sans-serif;
  font-style: normal;
  line-height: 0.4em;
  margin-bottom: 50px;
}

.txtNorand {
  font-weight: 200;
}
.txtMorphic {
  font-weight: 600;
}

.btnDisMor {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border: none;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5em;
  margin-top: 3em;
}

.btnDisMor:hover {
  background-color: #ff4203;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  color: #ffffff;
}

.buttonTopDiv {
  position: relative;
}

.leftPic3dMor {
  display: flex;
}

.newPicMorphos {
  position: relative;
  margin-top: -0.33em;
  height: 0.33em;
  top: 0.2em;
  left: 3.5em;
}

.newPic {
  position: relative;
  margin-top: -0.33em;
  height: 0.33em;
  top: 0em;
  left: 4.2em;
}

@media (max-width: 1320px) {
  .rightPartMorph {
    margin-left: 0px;
    font-size: 75px;
  }
}

@media (max-width: 991px) {
  .ThreeDMorphicContainer {
    height: auto;
  }
  .innerDataContainer3D {
    padding-left: 0px;
    padding-top: 80px;
    flex-direction: column-reverse;
  }
  .rightPartMorph {
    padding-top: 32px;
    line-height: 0.8em;
  }
  .rightPartMorph {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .newPic {
    margin-top: -0.33em;
    height: 0.33em;
    top: 0.1em;
    left: 2.5em;
  }
}

@media (max-width: 991px) {
  .ThreeDMorphicContainer {
    height: auto;
  }
}

@media (max-width: 481px) {
  .d3MorphingTxt {
    font-size: 15px;
  }
  .rightPartMorph {
    font-size: 60px;
  }
}
@media (max-width: 420px) {
  .rightPartMorph {
    font-size: 37px;
  }

  .rightPartMorph {
    padding-top: 0px;
  }
}

.freeShippingContainer {
  height: 270px;
  width: 100%;
  background-color: #ff4203;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.headingFreeShipping {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;

  color: #ffffff;
}

.btnArrowFreeShip {
  /* Body regular */

  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Dark mode (white) */

  color: #ffffff;
  background: none;
  border: none;
}

.btnArrowFreeShip:hover {
  color: #ffffff;
}

@media (max-width: 566px) {
  .headingFreeShipping {
    font-size: 30px;
  }
  .headingFreeShipping {
    text-align: center;
  }
}

.footerMainContainer {
  width: 100%;
  height: 210px;
  background-color: #222726;
  display: flex;
  justify-content: space-between;
  padding-left: 35px;
}
.footerNorandcol1 {
  width: 34%;
  display: flex;
  justify-content: space-around;
  align-items: top;
}
.footerNorandCol11 {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.footerNorandcol2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding-right: 25px;
}
.stayLoopTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;

  mix-blend-mode: normal;
  opacity: 0.4;
}
.emailTxt {
  fill: white;
  font-size: 24px;
  margin-right: -24px;
}
.inputEmailFooter {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 50px;
  width: 420px;
  padding-left: 30px;
  border: none;
  border-bottom: 3px solid #ffffff;
  width: 100%;
  color: white;
  background-color: inherit;
}

.inputEmailFooter:focus {
  border: none;
  border-bottom: 3px solid #ffffff;
}

.btnSubsFooter {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background: none;
  border: none;
  color: #ffffff;
  position: absolute;
  margin-left: -110px;
  margin-top: 10px;
  transition: color 0.3s;
}

.btnSubsFooter:hover {
  color: #ff4203;
  transition: color 0.3s;
}

.txtDataProtFooter {
  color: #ffffff;

  mix-blend-mode: normal;
  opacity: 0.4;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-top: 5px;
  width: 100%;
}
.topFooterNorand {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  mix-blend-mode: normal;
  opacity: 0.4;
}
.bottomContFooterNorand {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  transition: color 0.3s;
}

.bottomContFooterNorand:hover {
  color: #adadad;
  transition: color 0.3s;
}
.secondRowFooter {
  height: 120px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #222726;
  align-items: center;
  padding-left: 35px;
}
.socialIconFooterBack {
  height: 35px;
  width: 35px;
  background-color: #ffffff;
  color: #222726;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s !important;
}
.socialIconFooterBack:hover {
  background-color: #ff4203;
  color: #222726;
  transition: background-color 0.3s !important;
}
.socialIconsContainerFooter {
  display: flex;
  justify-content: space-evenly;
  width: 200px;
}
.socialIconFooter {
  font-size: 20px;
}
.thirdRowFooter {
  height: 80px;
  width: 100%;
  background-color: #222726;
  display: flex;
  justify-content: space-between;
  padding-left: 31px;
  padding-top: 15px;
  padding-right: 20px;
}
.thirdRRight {
  display: flex;
  width: 580px;
  justify-content: space-between;
}
.thirdRowContent {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.thirdRowContent:hover {
  color: #ff4203;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.footerSelect {
  width: 120px;
}
.flagImg {
  height: 30px;
  width: 30px;
  border-radius: 60px;
}
.thirdRowCol1 {
  width: 13%;
  display: flex;
  justify-content: space-between;
  width: 128px;
}

.footerMainContainer {
  --bs-gutter-x: 0em;
}
.secondRowFooter {
  --bs-gutter-x: 0em;
}
.selectFooter {
  background-color: #222726;
  border: none;
  color: #ffff;
  height: 39px;
}
.mainTopFooterForback {
  background-color: #222726;
}
@media (max-width: 1400px) {
  .thirdRRight {
    width: 422px;
  }
  .footerNorandImg {
    max-width: 350px;
  }
}
@media (max-width: 768px) {
  .footerMainContainer {
    justify-content: center;
    height: auto;
  }
  .footerNorandcol1 {
    width: 85%;
  }
  .footerNorandcol2 {
    width: 85%;
    margin-top: 50px;
  }
  .secondRowFooter {
    display: flex;
    justify-content: center;
    height: auto;
    padding-top: 40px;
  }
  .footerLogo {
    display: flex;
    justify-content: center;
  }
  .footerNorandcol1 {
    padding-top: 45px;
  }
  .footerMainContainer {
    padding-left: 0px;
  }
  .socialIconsContainerFooter {
    margin-top: 32px;
  }
  .thirdRowFooter {
    padding-top: 80px;
    padding-bottom: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .thirdRRight {
    margin-top: 20px;
  }
  .secondRowFooter {
    padding-left: 0px;
  }
  .footerNorandImg {
    width: 360px;
  }
}

@media (max-width: 461px) {
  .thirdRRight {
    width: 100%;
  }
  .thirdRowContent {
    font-size: 12px;
  }
}
@media (max-width: 370px) {
  .thirdRowContent {
    font-size: 10px;
  }
}

.main-bg {
  background-image: url(/static/media/learnmainbg.a0be6d96.png);
  background-size: cover;
}
.discover-heading {
  text-align: center;
  padding-top: 120px;
  padding-bottom: 60px;
  font-family: "Chillax";
  font-style: normal;
  font-weight: 200;
  font-size: 115px;
  line-height: 1em;
  color: #ffffff;
}

.discover-heading-bold {
  font-weight: 600;
}

.learn-first-col {
  background-color: #20ead6;
  padding-left: 0px;
  padding-right: 0px;
}
.learn-second-col {
  background-color: #ff4203;
  padding-left: 0px;
  padding-right: 0px;
}
.desktop-inst {
  padding-left: 10%;
  padding-top: 5rem;
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #222726;
}
.complex-heading {
  padding-left: 10%;
  padding-top: 2rem;
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  color: #222726;
}
.learn-btn-div {
  display: flex;

  margin-left: 45px;
  margin-top: 5rem;
  margin-bottom: -130px;
  position: relative;
  z-index: 1;
}
.learn-discover-btn {
  background-color: white;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #222726;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
  margin: 15px;
  margin-top: 60px;
  transition: box-shadow 0.5s;
}
.learn-discover-btn:hover {
  background-color: white;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.6);
  color: #222726;
}
.learn-manual-btn {
  background-color: inherit;
  padding: 13px 31px 13px 31px;
  width: auto;
  margin-left: 30px;
  height: 50px;
  border-radius: 25px;
  color: #222726;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
  margin-top: 60px;
  transition: box-shadow 0.5s;
}

.learn-manual-btn:hover {
  color: darkgrey;
}

.cutmono-img {
  object-position: center 100px;
  object-fit: cover;
  width: 100%;
  height: 542px;
}

.eurorack {
  padding-left: 90px;
  padding-top: 4rem;
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.ArrowFooter {
  padding-left: 10px;
}

.morphing-heading {
  padding-left: 90px;
  padding-top: 3rem;
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  color: #ffffff;
}
.morphos-btn-div {
  margin-left: 75px;
  margin-top: 5rem;
  display: flex;
  position: relative;
  z-index: 1;
}

.morphos-btn {
  background-color: white;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #222726;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
  margin: 15px;
  margin-top: 60px;
  transition: box-shadow 0.5s;
}

.morphos-btn:hover {
  background-color: white;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.6);
  color: #222726;
}

.face-cutimg {
  width: 100%;
  object-position: center 100px;
  object-fit: cover;
  margin-top: -130px;
  height: 542px;
}
@media only screen and (max-width: 1129px) {
}
@media only screen and (max-width: 991px) {
  .discover-heading {
    font-size: 80px;
    line-height: 60px;
  }
}

@media (max-width: 600px) {
  .discover-heading {
    font-size: 45px;
  }
  .desktop-inst {
    padding-left: 0px;
    text-align: center;
  }
  .complex-heading {
    padding-left: 0px;
    text-align: center;
  }
  .learn-btn-div {
    padding-left: 0px;
  }
  .learn-btn-div {
    margin-left: 0px;
  }
  .learn-first-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .morphing-heading {
    padding-left: 0px;
    text-align: center;
    font-size: 33px;
  }
  .morphos-btn-div {
    margin-left: 0px;

    justify-content: center;
  }

  .eurorack {
    text-align: center;
    padding-left: 0px;
  }
  .learn-first-col {
    height: 773px;
  }
}

/* .player-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  margin-bottom: 50px;
} */

.center-heading-tutorials {
  padding-top: 4rem;
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  color: #222726;
  text-align: center;
  margin-bottom: 1em;
}
.player-container {
  margin: auto;
  border-radius: 20px;
  overflow: hidden;
  width: 90%;
  height: auto;
  margin-bottom: 80px;
  max-width: 1080px;
}

.react-player {
  padding-top: 56.25%;
  position: relative;
}
.react-player > div {
  position: absolute;
  top: 0;
}

.youtube-grid {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  width: 95%;
  list-style: none;
  padding: 0;
  margin: auto;
  margin-bottom: 40px;
}

.youtube-grid img {
  max-width: 90%;
}

.youtube-grid > .card {
  margin: 1rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: none;
  background-color: white;
  margin: 0;
  padding: 0;
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.youtube-grid > .card > .card-title {
  color: #222726;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #222726;
  transition: color 0.5s;
}

.youtube-grid > .card:hover,
.youtube-grid > .card:focus,
.youtube-grid > .card:active {
  background-color: lightgray;
  transition: background-color 0.3s ease;
}

.creativityMonoContainer {
  height: auto;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.mainCreativity {
  padding-top: 131px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: 125px;
}
.p1Creativity {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Dark mode (white) */

  color: #ffffff;
}

.headerCreativityNorand {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 200;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: -0.25em;
  width: auto;
}

.headerCreativityMono {
  font-weight: 600;
}

.headerCreativityMonoMK2 {
  font-weight: 300;
  color: #ffffff;
}

.btnOrderNow {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-size: 1rem;
  font-weight: 400;
  margin-top: 60px;
  margin-top: -70px;
  margin-bottom: 78px;

  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

.btnOrderNow:hover {
  background-color: #ff4203;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.6);
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

.cutMonoImg {
  width: 80%;
  max-width: 900px;
}

.cutMonoImgMK2 {
  width: 100%;
  max-width: 1200px;
}

.arrowButtonMonoProduct {
  margin-left: 20px;
}
.euroIcon {
  fill: #fff;
  margin-left: 5px;
  margin-top: -3px;
}

@media (max-width: 1038px) {
  .mainCreativity {
    font-size: 102px;
  }
}

@media (max-width: 850px) {
  .mainCreativity {
    font-size: 70px;
  }
}
@media (max-width: 749px) {
  .btnOrderNow {
    margin-top: -49px;
  }
}

@media (max-width: 630px) {
  .p1Creativity {
    font-size: 20px;
  }
  .mainCreativity {
    font-size: 50px;
  }
}
@media (max-width: 547px) {
  .btnOrderNow {
    margin-top: -32px;
  }
}
@media (max-width: 448px) {
  .p1Creativity {
    font-size: 15px;
  }
  .mainCreativity {
    font-size: 40px;
  }
}

.btnOrderNowTab {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
}

.btnOrderNowTab:hover {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  color: #ffffff;
}

.tabMonoContainer {
  background-color: #222726;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.tabMonoFixed {
  position: fixed;
  top: 0;
  z-index: 9;
}

.tabTxtMonoTitle {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 20px;
  margin-right: 40px;
  /* identical to box height, or 150% */

  /* CTA (orange) */

  color: #ffffff !important;
  margin-bottom: 0px;
}

.rightIconPartMono {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0px;
  padding-left: 25px;
  align-items: flex-end;
  padding-right: 30px;
}

.tabTxtMono {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 12px;
  margin-right: 12px;
  text-decoration: none;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 0 !important;
  /* identical to box height, or 150% */

  /* CTA (orange) */

  color: #ffffff !important;
}
.txtTabMonoMain {
  display: flex;

  padding-left: 62px;
  justify-content: space-between;
  /* width: 1121px; */
  width: 64%;
}
.buttonMainOrderNow {
  padding-right: 30px;
}
.tabTxtMono.color {
  color: #ff4203 !important;
}

@media (max-width: 1200px) {
  .tabTxtMono {
    font-size: 13px;
  }
}

@media (max-width: 1080px) {
  .tabTxtMono {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .tabMonoFixed {
    top: auto;
    bottom: 0;
    z-index: 9;
    background-color: transparent;
    box-shadow: none;
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }

  .rightIconPartMono {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0px;
    padding-left: 25px;
    align-items: flex-end;
    padding-right: 30px;
    margin: auto;
  }
  .btnOrderNowTab {
    box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  }
}

.mainContainerModuleContext {
  height: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  justify-content: space-around;
  position: relative;
  z-index: 1;
  background-color: white;
}
.mainImg {
  width: 800px;
}
.moduleTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 24px;
  margin-bottom: 5px;
  /* or 112% */

  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}
.spTxtModule {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */

  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}
.firstRowHarmonic {
  text-align: right;
}

.parasFirstRowCont {
  text-align: right;
}
.topTextModulePic {
  display: flex;
  justify-content: space-between;
  padding-left: 62px;
  padding-top: 105px;
}
.bottomTextModulePic {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 131px;
}
.simDiscDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: -24px;
}

.discoverTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #222726;
  margin-bottom: 0px;
}

.discoverTxt:hover {
  color: #ff4203;
}

.secondPartTxtModule {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  text-align: center;
}
.spSecondParaModule {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  text-align: center;
  color: #20ead6;
}
.secondPartDivModule {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 180px;
  max-width: 800px;
}
.moduleMainArrowImg {
  padding-top: 30px;
  padding-bottom: 60px;
}
.mainPicPartModule {
  position: relative;
  display: flex;
  justify-content: center;
}
.leftPurseHarmonicPic {
  position: absolute;
  top: -81px;
  left: 291px;
}
.rightPurseHarmonicPic {
  position: absolute;
  top: -82px;
  right: 114px;
}
.peepTopleftModuleImg {
  position: absolute;
  left: 10px;
}
.circleTopModule {
  position: absolute;
  /* top: 160px; */
  /* left: -46px; */
  left: -43px;

  top: 141px;
}
.peepTopRightModuleImg {
  position: absolute;
  left: 8px;
}
.circleTopModule2 {
  position: absolute;
  top: 277px;
  left: -44px;
}
.bottomPurseHarmonicPic {
  position: absolute;
  bottom: 112px;
  left: 95px;
}
.peepTBottomRightModuleImg {
  position: absolute;
  left: 8px;
}
.circleBottomModule2 {
  position: absolute;
  bottom: -17px;
  left: -45px;
}
.summaryPicModule {
  padding-top: 105px;
}

.bottomPeepCirclContMod {
  position: absolute;

  bottom: 93px;
  left: 348px;
}
.upperPeepBottomMod {
  position: absolute;
  top: 100px;
  left: 52px;
}
.topFirstRowConText {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 120px;
}
@media (max-width: 900px) {
  .secondPartDivModule {
    padding-top: 150px;
  }
  .secondPartTxtModule {
    font-size: 30px;
  }
  .spSecondParaModule {
    font-size: 30px;
  }
  .summaryPicModule {
    width: 100%;
  }
}
@media (max-width: 810px) {
  /* .circleBottomModule2 {
        position: absolute;
        bottom: -17px;
        left: -45px;
        width: 66px;
    } */
  .topTextModulePic {
    justify-content: center;
    display: flex;

    padding-top: 105px;
    padding-left: 0px;
    justify-content: space-evenly;
  }
  .bottomTextModulePic {
    display: flex;
    padding-left: 30px;
    padding-right: 40px;
    justify-content: space-around;
    /* justify-content: space-between; */
    /* width: 842px; */
  }
  .moduleTxt {
    font-size: 12px;
    line-height: 10px;
    margin-bottom: 0;
  }
  .discoverTxt {
    font-size: 10px;
  }
  .mainImg {
    width: 500px;
  }
  .spTxtModule {
    font-size: 12px;
  }

  .circleTopModule2 {
    top: 206px;
    left: -29px;
    width: 66px;
  }
  .rightPurseHarmonicPic {
    top: -84px;
    right: 66px;
  }
  .circleTopModule {
    width: 66px;
    left: -28px;
    top: 124px;
  }
  .peepTopleftModuleImg {
    height: 122px;
  }
  .moduleCircleCont3 {
    width: 66px;
  }
  .leftPurseHarmonicPic {
    top: -80px;
    left: 182px;
  }
  .peepTopRightModuleImg {
    height: 200px;
  }
  .circleBottomModule2 {
    bottom: -8px;
    left: -26px;
    width: 66px;
  }
  .peepTBottomRightModuleImg {
    height: 130px;
    left: 3px;
  }
  .bottomPurseHarmonicPic {
    bottom: 68px;
    left: 220px;
  }
  .upperPeepBottomMod {
    height: 130px;
    left: 3px;
  }
  .upperPeepBottomMod {
    top: 60px;
    left: 29px;
  }
  .bottomPeepCirclContMod {
    bottom: 60px;
    left: 20px;
  }
  .simDiscDiv {
  }
  .topImgMain {
    display: flex;
    justify-content: center;
  }
  .topFirstRowConText {
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-bottom: 20px;
    padding-right: 0px;
  }
  .topTextModulePic {
    margin-left: 0;
    margin-right: 0;
  }
  .bottomTextModulePic {
    padding-right: 0;
  }
}
@media (max-width: 650px) {
  .secondPartDivModule {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .secondPartDivModule {
    padding-top: 100px;
  }
}

@media (max-width: 511px) {
  .topFirstRowConText {
    padding-right: 50px;
  }

  .mainImg {
    object-fit: cover;
    width: 350px;
  }
  .circleBottomModule2 {
    width: 50px;
  }
  .circleBottomModule2 {
    left: -21px;
  }
  .bottomPurseHarmonicPic {
    bottom: 33px;
    left: 38px;
  }
  .peepTBottomRightModuleImg {
    height: 120px;
    left: 0px;
  }
  .peepTopleftModuleImg {
    height: 100px;
    left: 2px;
  }
  .circleTopModule {
    top: 102px;
    left: -19px;
  }
  .circleTopModule {
    width: 50px;
  }
  .leftPurseHarmonicPic {
    left: 137px;
  }
  .circleTopModule2 {
    width: 50px;
  }
  .circleTopModule2 {
    top: 167px;
    left: -19px;
  }
  .peepTopRightModuleImg {
    height: 165px;
    left: 2px;
  }
  .moduleCircleCont3 {
    width: 50px;
  }
  .upperPeepBottomMod {
    top: 47px;
    left: 20px;
  }
  .upperPeepBottomMod {
    height: 120px;
  }
  .bottomPeepCirclContMod {
    bottom: 34px;
    left: 140px;
  }
  .simDiscDiv {
    max-width: 180px;
    padding-left: 40px;
    margin-right: 0px;
  }
}

@media (max-width: 425px) {
  .secondPartTxtModule {
    font-size: 20px;
  }
  .spSecondParaModule {
    font-size: 20px;
  }
}

.mainIntroContextModule {
  width: 100%;
  background-color: #222726;
  display: flex;

  flex-direction: column;
  align-items: center;
  margin-bottom: -260px;
}

.monoAnimContainer {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 50vh;
  -webkit-transform: translateY(-30%);
          transform: translateY(-30%);
  display: flex;
  margin-top: 400px;
  width: 100%;
  height: 100vh;
  max-height: 800px;
}

.svgContainer {
  position: absolute;
  right: 50vw;
  overflow: hidden;
}

.animComponent {
  position: absolute;
  left: -108px;
  top: -173px;
  width: 2363px;
}

.animComponentOsc {
  position: absolute;
  top: -320px;
  left: -350px;
  width: 800px;
}

.animText {
  text-align: left;
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  width: 480px;
  margin-left: 20px;
  color: #ffffff;
}

.animNumber {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  margin-left: 5vw;
  text-align: left;
  text-transform: capitalize;
  color: #ffffff;
}

.hideOverflow {
  overflow: hidden;
  margin-left: 50%;
  width: 100%;
  padding-top: 200px;
}

.slideInOut {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-right: 20px;
  transform: translateX(120%);
  -webkit-transform: translateX(120%);
}

.slideIn {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slideOut {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  0% {
    -webkit-transform: translateX(120%);
            transform: translateX(120%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateX(120%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(120%);
            transform: translateX(120%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(120%);
  }
}

.animTriggerFirst {
  height: 100px;
}
.animTrigger {
  height: 800px;
}
.lastAnimTrigger {
  height: 800px;
}

.introTxtModule {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Dark mode (white) */

  color: #ffffff;
  padding-top: 80px;
}
.contextualIntroHeadTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  line-height: 1em;

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  padding-top: 40px;
}
.monoIntroParaTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #ffffff;
  padding-top: 40px;
  width: 505px;
}
.spModuleTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 300;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Dark mode (white) */

  color: #ffffff;
}
.spMonoIntroPara {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #ffffff;
  padding-top: 60px;
}
.seeInActionTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  text-align: right;
  padding-right: 50px;

  /* Dark mode (white) */

  color: #ffffff;
}
.seeArrowDiv {
  display: flex;
  margin-left: auto;
  margin-right: 10vw;
  -webkit-transform: translateY(-300%);
          transform: translateY(-300%);
}

@media (max-width: 1080px) {
  .monoAnimContainer {
    -webkit-transform: translateY(-40%);
            transform: translateY(-40%);
    margin-top: 400px;
  }
  .animNumber {
    margin-top: 10px;
    margin-right: 10px;
  }
  .hideOverflow {
    padding-top: 0;
    margin-left: 0;
  }
  .animComponent {
    overflow: hidden;
  }
  .svgContainer {
    position: absolute;
    right: 25vw;
    overflow: hidden;
    margin-top: 150px;
  }
  .slideInOut {
    align-items: flex-start;
  }
  .animText {
    padding-left: 200px;
    width: 80%;
    line-height: 36px;
    font-size: 24px;
  }
  .animComponentOsc {
    top: -120px;
    left: -550px;
    width: 800px;
  }
  .seeArrowDiv {
    bottom: 315px;
    right: 11%;
  }
}
@media (max-width: 991px) {
  .contextualIntroHeadTxt {
    font-size: 75px;
  }
  .spModuleTxt {
    font-size: 75px;
  }
}
@media (max-width: 700px) {
  .animNumber {
    margin-top: 10px;
    margin-right: 10px;
  }
  .hideOverflow {
    margin-left: 0;
  }
  .animComponent {
    overflow: hidden;
    width: 1033px;
    left: -46px;
    top: -75px;
  }
  .monoAnimContainer {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
    margin-top: 400px;
  }
  .svgContainer {
    position: absolute;
    right: 25vw;
    overflow: hidden;
    margin-top: 120px;
  }
  .slideInOut {
    align-items: flex-start;
  }
  .monoSVG {
    width: 500px;
  }
  .animText {
    padding-left: 100px;
    width: 80%;
    line-height: 24px;
    font-size: 14px;
  }
  .animComponentOsc {
    top: -75px;
    left: -355px;
    width: 500px;
  }
}
@media (max-width: 600px) {
  .seeInActionTxt {
    font-size: 30px;
  }
  .seeArrowDiv {
    display: flex;
    right: 0px;
    left: 0px;

    justify-content: center;
  }
}
@media (max-width: 550px) {
  .monoAnimContainer {
    margin-top: 200px;
  }
  .contextualIntroHeadTxt {
    font-size: 50px;
  }
  .spModuleTxt {
    font-size: 50px;
  }
  .monoIntroParaTxt {
    width: 340px;
  }
  .animTrigger {
    height: 500px;
  }
  .lastAnimTrigger {
    height: 500px;
  }
}

.ThreeDMorphicContainerPro {
  background-image: url(/static/media/3DBackground.0ce9ce83.png);

  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.innerDataContainer3DPro {
  padding-top: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
}
.d3MorphingTxtPro {
  font-family: "Chillax", sans-serif;
  line-height: 28px;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  color: #ffffff;
  text-align: center;
}
.tmTxtPro {
  position: absolute;
  margin-top: -13px;
  margin-top: -10px;
  font-size: 10px;
  margin-left: -20px;
}

.btnDisMorPro {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
}

.btnDisMorPro:hover {
  background-color: #ff4203;
  color: #ffffff;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.8);
}

.norMorHeadingTxt {
  text-align: center;
  font-size: 115px;
  line-height: 1em;
  color: #ffffff;
  font-family: "Chillax", sans-serif;
  font-style: normal;
  font-weight: 600;
}

.txtNorandPro {
  font-weight: 200;
}

.leftPic3dMorPro {
  display: flex;
  justify-content: center;
  margin-bottom: -50px;
}
.imgArrowPro {
  margin-left: -42px;
}
.buttonTopDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

@media (max-width: 1300px) {
  .norMorHeadingTxt {
    font-size: 75px;
  }
}

@media (max-width: 885px) {
  .norMorHeadingTxt {
    font-size: 62px;
  }
}
@media (max-width: 650px) {
  .norMorHeadingTxt {
    font-size: 48px;
  }
  .d3MorphingTxtPro {
    font-size: 18px;
  }
  .leftImg3MorPro {
    width: 90%;
  }
}
@media (max-width: 521px) {
  .norMorHeadingTxt {
    font-size: 40px;
  }
  .imgArrowPro {
    margin-left: 0px;
  }
}
@media (max-width: 439px) {
  .norMorHeadingTxt {
    font-size: 34px;
  }
}

/* @import '~video-react/styles/scss/video-react';
@import "~video-react/styles/scss/video-react";  */

.mainVideoApplyMod > .video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  width: 611px !important;
  /* width: 800%; */
  height: 422px !important;
  padding: 0px !important;
  border-radius: 20px;
}

.mainVideoApplyMod > .video-react-icon-play-arrow:before,
.video-react .video-react-bezel .video-react-bezel-icon-play:before,
.video-react .video-react-play-control:before,
.video-react .video-react-big-play-button:before {
  background-color: #ff4203;
  /* height: 110px;
    width: 110px; */
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainVideoApplyMod > .video-react:hover .video-react-big-play-button,
.video-react .video-react-big-play-button:focus {
  border: none;
  background: transparent;
}

.paraApplyModTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 1.2em;
  /* or 107% */

  /* Light mode (dark grey) */

  color: #222726;
}

.txtPartApplyMod {
  width: 406px;
  height: 288px;
  margin-right: 8vw;
}

.mainContainerApplyingMod {
  display: flex;
  justify-content: flex-end;
  background-color: #ffffff;
  width: 85%;
  max-width: 1700px;
  height: 520px;
  padding-top: 50px;
  margin-bottom: 20px;
}
.picPartUserAppMod {
  display: flex;
}
.nameApplyModHolder {
  padding-left: 25px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Light mode (dark grey) */

  color: #222726;
}
.spCountryAppMod {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Light mode (dark grey) */

  color: #222726;
}
.mainVideoApplyMod {
  position: relative;
  left: 130px;
}
.mainApplyModule {
  width: 100%;
}
.video-react-button {
  border: none !important;
  background: none !important;
}

.video-react .video-react-control:before,
.video-react .video-react-big-play-button:before {
  width: 108%;
  height: 167%;
}
.video-react .video-react-control-bar {
  /* bottom: 20px; */
  padding-bottom: 10px;
  border-radius: 20px;
}
.video-react .video-react-big-play-button.video-react-big-play-button-center {
  top: 41%;
  left: 50%;
}

.video-js .vjs-big-play-button {
  width: 100px !important;
  height: 100px !important;
  border: none !important;
  background-color: #ff4203 !important;
  border-radius: 100% !important;
}
.vjs-icon-play:before,
.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before {
  /* margin-top: 25px; */

  /* font-size: 55px; */
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.vjs-button > .vjs-icon-placeholder:before {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}
.vjs-big-play-centered .vjs-big-play-button {
  top: 43% !important;
}
.video-js {
  border-radius: 20px !important;
}
.video-js .vjs-tech {
  border-radius: 20px !important;
}
.vjs-poster {
  border-radius: 20px;
}
.vjs-has-started .vjs-control-bar {
  border-radius: 20px;
}

@media (max-width: 1360px) {
  .txtPartApplyMod {
    margin-right: -60px;
  }
  .mainVideoApplyMod > .video-js > .vjs-poster {
    width: 100%;
  }
}

@media (max-width: 1295px) {
  .paraApplyModTxt {
    font-size: 30px;
  }
  .txtPartApplyMod {
    margin-left: 30px;
    height: auto;
  }
}

@media (max-width: 1083px) {
  .paraApplyModTxt {
    font-size: 30px;
    line-height: 39px;
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  .mainContainerApplyingMod {
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    padding-bottom: 40px;
  }
  .mainVideoApplyMod {
    position: unset;
  }
  .mainVideoApplyMod {
    width: 90%;
  }
  .mainVideoApplyMod > .video-react.video-react-fluid,
  .video-react.video-react-16-9,
  .video-react.video-react-4-3 {
    width: 100% !important;
  }
  .txtPartApplyMod {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .mainVideoApplyMod > .video-js {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .txtPartApplyMod {
    margin-right: 0px;
  }
}
@media (max-width: 450px) {
  .txtPartApplyMod {
    width: auto;
  }
  .paraApplyModTxt {
    padding-right: 10px;
  }
}

.mainContainerDigitBrain {
  background-repeat: no-repeat;

  background-size: cover;
  width: 100%;
  background-image: url(/static/media/digitaBrainMainPic.d345571c.png);
}
.digiBrainPara {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */
  width: 800px;
  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
  text-align: center;
}
.spTxtDigi {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #20ead6;
}
.paraArrowPartDigi {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
}

.backPicDSC04771 {
  height: 397px;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 991px) {
  .paraArrowPartDigi {
    padding-top: 50px;
    padding-bottom: 80px;
  }
  .mainContainerDigitBrain {
    height: auto;
  }
}
@media (max-width: 825px) {
  .digiBrainPara {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 450px) {
  .digiBrainPara {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .digiBrainPara {
    font-size: 30px;
  }
  .spTxtDigi {
    font-size: 30px;
  }
}

.mainContainerSimplePowerful {
  width: 100%;
  height: auto;
  background-color: #222726;
  display: flex;
  padding-bottom: 204px;
  height: auto;

  flex-direction: column;
  align-items: center;
}

ul {
  padding-left: 0;
  list-style-type: none;
}

.doItAllTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;
  padding-bottom: 57px;

  /* Dark mode (white) */

  color: #ffffff;
  padding-top: 80px;
}
.simplePowerfulTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 300;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Dark mode (white) */

  color: #ffffff;
  padding-bottom: 065px;
  max-width: 699px;
}
.mainParaSiimplePower {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #ffffff;
  width: 90%;
  max-width: 600px;
}

.txtPartSimplPow {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spYetPowerTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Dark mode (white) */

  color: #ffffff;
}
.mainParaSpTxtSimpPow {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #ffffff;
}
.mainPicSimplePower {
  padding-top: 169px;
  padding-bottom: 306px;
  width: 75%;
}
.mainPicPart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 80px;
}

.txtSimpPowFirstRow {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  /* Dark mode (white) */

  color: #ffffff;
  max-width: 500px;
}
.subTxtSimpPowFirstRow {
  /* Body regular */

  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
  max-width: 300px;
}
.subTxtSimpPowFirstRow > ul {
  padding-left: 0;
}

.subTxtSimpPowFirstRow > ul > li {
  list-style-type: none;
}

.imgageLeftSimp {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

#undoRedo {
  margin-right: 20px;
}
.txtParaRightSimp {
  padding-left: 48px;
  width: 500px;
}
.lineCopy1 {
  position: absolute;
  bottom: -151px;
}
.learnMoreTxtSimp {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Dark mode (white) */

  color: #ffffff;
  margin-left: 16px;
}
.step1Img2 {
  position: absolute;
  left: 14px;
  z-index: 35;
  -webkit-filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
.step1Img3 {
  position: absolute;
  left: 28px;
  z-index: 2;
  -webkit-filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
.step1Img4 {
  position: absolute;
  left: 22px;
  z-index: 0;
  -webkit-filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}

.step1Img {
  position: relative;
  z-index: 999;
  -webkit-filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
.group64Img {
  position: absolute;
  left: 70px;
  -webkit-filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
#cirlceLinePow1 {
  margin-left: 61px;
}
.lineCopySecond {
  position: absolute;
  bottom: -151px;
  z-index: 999;
  right: 64px;
}
#mainPicPart2 {
  margin-left: -46px;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}
#mainPicPart3 {
  margin-top: -40px;
  display: flex;
  justify-content: center;
  padding-right: 120px;
}
.mainPicPart2 {
  margin-top: -62px;
}
#lineCopy3 {
  position: absolute;
  bottom: -125px;
}
#mainPicPart5 {
  margin-top: 40px;
}
#mainPicPart6 {
  margin-top: 60px;
}
#mainPicPart7 {
  margin-top: 75px;
}
#mainPicPart6 {
}
.lowerDivSim {
  width: 80%;

  display: flex;

  flex-direction: column;
  align-items: center;
}
.upperDivSim {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.undoRedoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 429px;
}
@media (max-width: 1450px) {
  .lowerDivSim {
    width: 100%;
  }
}

@media (max-width: 1166px) {
  #lineCopy3 {
    top: 100px;
  }
  .lineCopy1 {
    position: absolute;
    bottom: -151px;
    height: 150px;
  }
  .firstInputSimpPow {
    width: 100px;
  }
  .step1Img {
    width: 100px;
  }
  .group64Img {
    width: 100px;
    left: 45px;
  }
  .step1Img3 {
    width: 100px;
  }
  .txtSimpPowFirstRow {
    font-size: 30px;
    line-height: 35px;
  }
  .subTxtSimpPowFirstRow {
    font-size: 12px;
  }
  .undoImg {
    width: 100px;
  }
  .redoImg {
    width: 100px;
  }
  .step1Img2 {
    width: 100px;
  }
  .cirlceLinePow1 {
    width: 72px;
  }
  .mainPicPart {
    padding-top: 50px;
    width: 100%;
  }
  #mainPicPart3 {
    margin-top: 20px;
    justify-content: center;
    padding-right: 0px;
  }
  #lineCopy1Id {
    height: 140px;
    bottom: -135px;
  }

  #endLineCopyID {
    height: 120px;
    bottom: -120px;
  }

  #lineCopy3 {
    height: 120px;
    bottom: -135px;
  }
  .undoRedoContainer {
    width: 251px;
  }

  #txtParaRightSimpId1 {
    padding-left: 50px;
  }
  #mainPicPart2 {
    margin-left: 0px;
  }
  .lineCopySecond {
    right: 20px;
    top: 100px;
  }
  .txtParaRightSimp {
    padding-left: 100px;
    width: 600px;
  }
  #txtParaRightSimp {
    padding-left: 24px;
  }
}

@media (max-width: 823px) {
  .simplePowerfulTxt {
    font-size: 70px;
    line-height: 90px;
  }
  .spYetPowerTxt {
    font-size: 70px;
    line-height: 90px;
  }
  .mainPicSimplePower {
    width: 95%;
  }

  .lowerDivSim {
    width: 80%;
  }
  #txtParaRightSimp {
    padding-left: 0px;
  }

  .upperDivSim {
    justify-content: center;
  }
  #mainPicPart3 {
    margin-left: -150px;
  }
  #mainPicPart2 {
    margin-left: -53px;
  }
}

@media (max-width: 751px) {
  .txtParaRightSimp {
    text-align: center;
    width: 100%;
    padding-left: 0;
  }
  .subTxtSimpPowFirstRow {
    max-width: 100%;
  }
  .txtSimpPowFirstRow {
    max-width: 100%;
  }
  .mainPicPart {
    flex-direction: column;
    padding-top: 0px;
  }
  #mainPicPart3 {
    margin-left: 0px;
  }
  .lowerDivSim {
    width: 80%;
  }
  .txtSimpPowFirstRow {
    font-size: 14px;
    line-height: 18px;
  }
  .subTxtSimpPowFirstRow {
    font-size: 10px;
    line-height: 16px;
  }

  #lineCopy1Id {
    height: 80px;
    bottom: -80px;
  }
  .learnMoreTxtSimp {
    font-size: 10px;
    line-height: 16px;
  }

  .upperDivSim {
    justify-content: center;
  }

  .lineCopy1 {
    bottom: -192px;
    height: 210px;
  }
  .lineCopySecond {
    bottom: -249px;
    height: 150px;
  }
  #lineCopy3 {
    bottom: -195px;
  }
  .cirlceLinePow1 {
    visibility: hidden;
  }
  #mainPicPart3 {
    margin-left: 0px;
  }
  #mainPicPart2 {
    margin-left: 0px;
  }
  #txtParaRightSimpId1 {
    padding-left: 0px;
  }
}

@media (max-width: 500px) {
  .simplePowerfulTxt {
    font-size: 50px;
    line-height: 65px;
  }
  .spYetPowerTxt {
    font-size: 50px;
    line-height: 65px;
  }
}

.mainContainer {
  display: flex;

  align-items: center;
  flex-direction: column;
}
.unlockTxtRandom {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
  padding-top: 90px;
}
.randomAnyTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 300;
  font-size: 125px;
  line-height: 1em;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Light mode (dark grey) */

  color: #222726;
  padding-bottom: 60px;
}
.spAnythingTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Light mode (dark grey) */

  color: #222726;
}
.mainParaTxtRandAny {
  width: 498px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
  padding-bottom: 120px;
}
.spMainParaRandomAny {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
}
.plusRandomImg {
  margin-top: -132px;
}

@media (max-width: 870px) {
  .mainParaTxtRandAny {
    padding-bottom: 80px;
  }

  .unlockTxtRandom {
    padding-top: 60px;
  }
}
@media (max-width: 821px) {
  .randomAnyTxt {
    font-size: 70px;
  }
  .spAnythingTxt {
    font-size: 70px;
  }
}
@media (max-width: 515px) {
  .mainParaTxtRandAny {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    font-size: 13px;
  }
  .randomAnyTxt {
    font-size: 50px;
  }
  .spAnythingTxt {
    font-size: 50px;
  }
}

.tabTxtRand1 {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ff4203;
  transition: color 0.5s;
}

.arrowImgSmall {
  margin-left: 10px;
}

.tabTxtRand {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222726;
  transition: color 0.5s;
}
.txtMainTabRand {
  padding-left: 31px;
  padding-right: 29px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.txtMainTabRandBottom {
  height: 4px;
  background: #222726;
  width: 97%;
  padding-left: 31px;
  padding-right: 29px;
  margin-bottom: 40px;
  display: flex;
}
.mainTabBarPgRand {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottomTxtRand {
  height: 4px;
  background-color: #222726;
  width: 100%;

  transition: background-color 0.5s;
}
.bottomTxtRand1 {
  height: 4px;
  background-color: #ff4203;
  width: 100%;
  transition: background-color 0.5s;
}
.tabDivLineTxt {
  width: 15%;
  cursor: pointer;
}
/* .bottomTxtRand1{
    height: 4px;
    background:  #FF4203;;

    width: 23%;
    margin-left: -32px;
}
.bottomTxtRand2{
    height: 4px;
    background:  #FF4203;

    width: 21.5%;
    margin-left: -32px;
}
.bottomTxtRand3{
    height: 4px;
    background:  #FF4203;;

    width: 20.5%;
    margin-left: -32px;
}
.bottomTxtRand4{
    height: 4px;
    background:  #FF4203;;

    width: 500px;
    margin-left: -32px;
} */
.pageRandDetail {
  width: 100%;
  padding-top: 96px;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.randLeftElements {
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 0;
  justify-content: center;
  padding: 0;
  opacity: 1;
  -webkit-opacity: 1;
}

.fadeIn {
  animation: fade-in 0.5s forwards;
  -webkit-animation: fade-in 0.5s forwards;
}

.fadeOut {
  animation: fade-out 0.5s forwards;
  -webkit-animation: fade-out 0.5s forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    -webkit-opacity: 0;
  }
  100% {
    -webkit-opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fade-out {
  0% {
    -webkit-opacity: 1;
  }
  100% {
    -webkit-opacity: 0;
  }
}

.oneTxt {
  padding-right: 60px;
  margin-top: 10px;
  margin-bottom: auto;
}
.pageRandTxtHeading {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  max-width: 400px;
  /* identical to box height, or 107% */

  /* Light mode (dark grey) */

  color: #222726;
}
.mainParaPagRand {
  /* Body regular */

  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Light mode (dark grey) */

  color: #222726;
  width: 407px;
}
.txtLearnPageRand {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Light mode (dark grey) */

  color: #222726;
}

.txtLearnPageRand:hover {
  color: #ff4203;
}

.randRightElements {
  padding: 0;
  margin: 0;
}

.randAnimContainer {
  height: 400px;
  width: 600px;
  background-color: #222726;
  border-radius: 20px;
  box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.25);
  margin: auto;
  margin-bottom: 40px;
}

.randAnimEle {
  position: relative;
  overflow: hidden;
  top: -40px;
  left: -70px;
  width: 750px;
}

@media (max-width: 620px) {
  .randAnimContainer {
    height: 240px;
    width: 360px;
  }

  .randAnimEle {
    position: relative;
    overflow: hidden;
    top: -20px;
    left: -48px;
    width: 450px;
  }
}

.oneTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */

  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}
.howItWorksTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
}
.howToWorkMainTabRand {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
}
.letTxtRandTab {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  /* Light mode (dark grey) */

  color: #222726;
}
.letsMakePart {
  display: flex;
  padding-left: 142px;
  padding-top: 199px;
  padding-bottom: 267px;
}
.downArrow {
  padding-right: 43px;
}
.howSWorkTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
}
.dropDownPointer {
  padding-top: 30px;
  z-index: 9;
}

.videorandomizer {
  /* width: 800%; */
  max-height: 562px;
  padding: 0px !important;
  border-radius: 20px;
  margin-top: -80px;
  max-width: 1000px;

  width: 90%;
  height: 49vw;
}

.howToWorkMainTabRand {
  width: 100%;
}

@media (max-width: 1400px) {
  .tabTxtRand {
    font-size: 16px;
  }
  .tabTxtRand1 {
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .letsMakePart {
    padding-left: 85px;
  }
  .tabTxtRand {
    font-size: 13px;
  }

  .tabTxtRand1 {
    font-size: 13px;
  }
  .randRightElements {
    padding-top: 50px;
  }
}
@media (max-width: 980px) {
  .bottomTxtRand {
    height: 3px;
  }
  .bottomTxtRand1 {
    height: 3px;
  }
  .tabTxtRand {
    font-size: 10px;
    margin-bottom: 6px;
  }

  .tabTxtRand1 {
    font-size: 10px;
    margin-bottom: 6px;
  }

  .pageRandDetail {
    padding-top: 40px;
  }
}

@media (max-width: 950px) {
  .letsMakePart {
    padding-left: 0px;
    padding-top: 147px;
    padding-bottom: 350px;
    justify-content: center;
  }
}
@media (max-width: 870px) {
  .txtMainTabRand {
    justify-content: center;
    align-items: center;
  }
  .tabDivLineTxt {
    width: 90%;
    padding-top: 10px;
    text-align: center;
  }
  .howToWorkMainTabRand {
    padding-top: 60px;
  }

  .tabDivLineFullTxt {
    display: none;
  }
  .tabTxtRand {
    font-size: 16px;
  }

  .tabTxtRand1 {
    font-size: 16px;
  }
}
@media (max-width: 619px) {
  .pageRandDetail {
    justify-content: center;
  }
  .mainParaPagRand {
    width: 80%;
    font-size: 14px;
  }
  .pageRandTxtHeading {
    font-size: 30px;
    line-height: 30px;
  }
  .oneTxt {
    margin-left: 5px;
    padding-right: 15px;
  }
  .letsMakePart {
    padding-left: 20px;
    padding-right: 20px;
  }
  .letTxtRandTab {
    font-size: 30px;
  }
  .tabTxtRand {
    font-size: 14px;
  }
  .tabTxtRand1 {
    font-size: 14px;
  }
}

.captureTacticMain {
  width: 100%;
  height: auto;
  background-image: url(/static/media/Gradient.c0290f3c.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.captureTxtMainPara {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  margin-top: 450px;
  /* or 107% */

  text-align: center;

  /* Dark mode (white) */

  color: #ffffff;
  width: 814px;
}
.spCaptureTact {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  text-align: center;

  /* Dark mode (white) */

  color: #20ead6;
}
.captureTacticMain {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.unionSmall {
  padding-top: 108px;
  padding-bottom: 130px;
}
.DSC04 {
  height: 397px;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 950px) {
  .captureTxtMainPara {
    padding-top: 250px;
  }
}
@media (max-width: 650px) {
  .captureTxtMainPara {
    padding-top: 200px;
  }

  .unionSmall {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}
@media (max-width: 832px) {
  .captureTxtMainPara {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 450px) {
  .spCaptureTact {
    font-size: 30px;
    line-height: 35px;
  }
  .captureTxtMainPara {
    font-size: 30px;
    line-height: 35px;
  }
}

.playButtonClau {
  background: white;
  color: white;
  border: none;
  width: 110px;
  height: 110px;
  border-radius: 100%;
  outline: none;
  text-transform: uppercase;
  transition: all 0.2s ease;
}

.playlist-item {
  color: #444;

  cursor: pointer;
  padding: 1em;
}

.playlist-item.selected {
  font-weight: bold;
}

.playlist-item:hover {
  background: rgba(0, 0, 0, 0.1);
}

.controls {
  /* padding: 1em; */
}

#waveform {
  width: 400px;
  margin-left: 15px;
  padding-top: 25px;
}
.playButtonClau svg path {
  stroke: #ff4203 !important;
}
.playIconReact {
  font-size: 37px;
  stroke: #ff4203 !important;
  fill: #ff4203;
}
.pauseIconReact {
  font-size: 37px;
  fill: #ff4203;
}
.mainContainerClaudeIns {
  max-height: 240px;
  width: 500px;
  background-color: #ff4203;
  border-radius: 20px;
  margin-top: 150px;
  position: relative;
  left: -200px;
  margin-left: 31px;
}

.musicAudioTop {
}

.mainAudioWave {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding-top: 46px;
  padding-left: 30px;
}
.paraTxtClauseIntro {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}
.birdRecTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}

.txtPartClaude {
  padding-top: 23px;
  padding-left: 60px;
}

.paraUsaClaude {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 1em;
  /* or 107% */

  /* Dark mode (white) */

  color: #ffffff;
}
.txtUsaClaude {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  padding-bottom: 120px;
  margin-left: -100px;
  margin-right: 50px;
}
.nameInsClaude {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}
.countryIns {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.picPartInsClaud {
  display: flex;
}
.imgClaude {
  margin-right: 25px;
  width: 80px;
}
.mainContainerClaudeInsTop {
  display: flex;
  justify-content: center;
  /* max-width: 1115px; */
  background-color: #222726;
  height: auto;
  width: 85%;
  max-width: 1800px;
  justify-content: flex-start;
  position: absolute;
  top: -280px;
}
.main {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.topClaudeMor > .main > .mainContainerClaudeInsTop {
  background: #ffffff;
}

.topClaudeMor
  > .main
  > .mainContainerClaudeInsTop
  > .txtUsaClaude
  > .paraUsaClaude {
  color: #222726;
}

.topClaudeMor
  > .main
  > .mainContainerClaudeInsTop
  > .txtUsaClaude
  > .picPartInsClaud
  > .nameInsClaude {
  color: #222726;
}

@media (min-width: 1800px) {
  .txtUsaClaude {
    margin-left: -40px;
  }
}

@media (max-width: 1277px) {
  .mainContainerClaudeIns {
    margin-left: 60px;
  }
}

@media (max-width: 1117px) {
  .mainContainerClaudeIns {
    margin-left: 80px;
  }
  .txtUsaClaude {
    margin-left: -133px;
  }
  .paraUsaClaude {
    font-size: 40px;
  }
}

@media (max-width: 950px) {
  .mainContainerClaudeInsTop {
    display: flex;
    justify-content: center;
    /* max-width: 1115px; */
    background-color: #222726;
    height: auto;
    width: 100%;
    justify-content: flex-start;
    position: absolute;
    /* top: -280px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mainContainerClaudeIns {
    margin-left: 0px;
    margin-top: 90px;
    left: 0px;
  }
  .txtUsaClaude {
    margin-left: 0px;
    margin-top: 90px;
  }
}
@media (max-width: 650px) {
  .mainContainerClaudeIns {
    width: 90%;
  }
  .mainAudioWave {
    padding-left: 25px;
  }
  .txtPartClaude {
    padding-left: 25px;
  }

  .playButtonClau {
    width: 80px;
    height: 80px;
  }
  #waveform {
    padding-top: 10px;
  }
  .txtUsaClaude {
    width: 90%;
    margin-left: 40px;
  }
  .paraUsaClaude {
    font-size: 30px;
    line-height: 30px;
  }
}

.mainContainerPureHarmonic {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.txtHowDoes {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
  padding-top: 170px;
}
.pureHarmonicTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 200;
  font-size: 125px;
  line-height: 1em;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Light mode (dark grey) */

  padding-top: 57px;
  color: #222726;
}
.underTheHoodTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
  width: 602px;
  padding-top: 65px;
}
.spTxtPureHar {
  font-weight: 600;
}
.purSynthSpe {
  padding-top: 162px;
}

.monoTxtStudio {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  /* Light mode (dark grey) */

  color: #222726;
}

.monoArrowDiv {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 120px;
  padding-right: 177px;
  padding-bottom: 50px;
}
.arrowLong {
  padding-left: 50px;
}
.purSynthSpe {
  width: 90% !important;
}

.subcontainerAnalogy {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.miniContainerAnaLogy {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.txtAnalogyPara {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
}
.mainContainerAnaLogMainPic {
  display: flex;

  padding-top: 150px;
  width: 100%;
  justify-content: space-evenly;
}
.learnMorePureTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Light mode (dark grey) */

  color: #222726;
}

.learnMorePureTxt:hover {
  color: #ff4203;
}

.analogyImg {
  font-size: 10px;
}
/* .mainContainerAnaLogMainPic{
    flex-direction: column;
} */
.top2OsciPics {
  position: relative;
}
#analogyImgId {
  position: absolute;
  left: -18px;
}
@media (max-width: 960px) {
  .mainContainerAnaLogMainPic {
    flex-direction: column;
  }

  .monoArrowDiv {
    padding-top: 130px;
  }
  .topMorphosArrowTZMF {
    padding-top: 130px;
  }
}
@media (max-width: 800px) {
  .pureHarmonicTxt {
    font-size: 70px;
  }
  .monoArrowDiv {
    padding-right: 0px;
    justify-content: center;
  }
  .monoTxtStudio {
    font-size: 30px;
  }
}

@media (max-width: 605px) {
  .underTheHoodTxt {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .purSynthSpe {
    width: 100% !important;
  }
}
@media (max-width: 415px) {
  .pureHarmonicTxt {
    font-size: 50px;
  }
  .arrowLong {
    padding-left: 10px;
  }
}

.mainFBANum {
  padding-top: 80px;
}
.frontBackBottomLine {
  height: 4px;
  width: 203px;
  background-color: #222726;
  transition: background-color 0.5s;
}
.frontBackTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 203px;
  /* identical to box height, or 150% */

  /* Light mode (dark grey) */

  color: #222726;
  transition: color 0.5s;
}
.frontBackAng {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 90%;
}
.numberFrontBack {
  display: flex;
  justify-content: center;
}
.imgMainFrontBack {
  display: flex;
  justify-content: center;
  max-width: 1480px;
  margin: auto;
}
.numberFrntTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}
.numberFrontBack {
  padding-top: 50px;
}
.imgMainFrontBack {
  padding-top: 50px;
}

.numberFrntTxt1 {
  display: none;
}
.frontBackTxt1 {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 203px;
  transition: color 0.5s;
  color: #ff4203;
}
.frontBackBottomLine1 {
  height: 4px;
  width: 203px;
  background-color: #ff4203;
  transition: background-color 0.5s;
}
.monoBack {
  width: 70%;
  padding-bottom: 100px;
}

.morphosBack {
  width: 40%;
  padding-bottom: 100px;
}

@media (max-width: 637px) {
  .frontBackAng {
    display: flex;

    flex-direction: column;
    justify-content: space-between;
  }
  .mainFBANum {
    display: flex;
    align-content: center;
    width: 100%;
    justify-content: space-around;
  }
  .monoBack {
    width: 95%;
  }
}

.txtTechSpec {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */
  padding-left: 87px;

  /* Dark mode (white) */

  color: #ffffff;
}

.technicalSpecHeader {
  display: flex;
  padding-top: 132px;
  padding-left: 146px;
  padding-bottom: 110px;
}
.mainTechSpec {
  background-color: #222726;
  width: 100%;
  height: auto;
  padding-bottom: 111px;
}
.topLineWhite {
  background: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.4;
  height: 2px;
  width: 85%;
}
.mainTechDetailPart {
  display: flex;
  align-items: center;
  flex-direction: column;

  height: auto;
}
.mediumTextTech {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */

  /* Dark mode (white) */

  color: #ffffff;

  mix-blend-mode: normal;
  opacity: 0.4;
}
.txtEndTechSpec {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  width: 407px;
  white-space: pre-wrap;
}
.firstTxtTechSpec {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */

  text-transform: capitalize;

  /* Dark mode (white) */

  color: #ffffff;
}

.textTechSpecDetailM {
  display: flex;
  justify-content: space-between;
  width: 85%;
  padding-top: 30px;
}
.pTopMediumTxt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pTopEndTxt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.w100Main {
  width: 58%;
}
.txtPad {
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
.none {
  display: none !important;
}

@media (max-width: 1141px) {
  .textTechSpecDetailM {
    width: 95%;
  }
  .topLineWhite {
    width: 95%;
  }
}
@media (max-width: 1003px) {
  .textTechSpecDetailM {
    width: 95%;
  }
  .topLineWhite {
    width: 95%;
  }
  .txtEndTechSpec {
    font-size: 12px;
  }
  .firstTxtTechSpec {
    font-size: 16px;
  }
  .mediumTextTech {
    font-size: 12px;
  }
  .w100Main {
    width: 72%;
  }
  .mainTechSpec {
    height: auto;
    padding-bottom: 100px;
  }
}
@media (max-width: 773px) {
  .textTechSpecDetailM {
    padding-top: 30px;
    flex-direction: column;
    align-items: center;
  }
  .txtPad {
    flex-direction: column;
    align-items: center;
  }
  .txtEndTechSpec {
    text-align: center;
  }
  .txtTechSpec {
    font-size: 25px;
  }
  .technicalSpecHeader {
    padding-left: 0px;
    justify-content: center;
  }
}

@media (max-width: 470px) {
  .txtTechSpec {
    font-size: 20px;
    padding-left: 20px;
  }
  .txtEndTechSpec {
    width: 100%;
  }
}

.expressiveCard > .mainApplyModule > .mainContainerApplyingMod{
    background-color:  #222726;;
}

.expressiveCard > .mainApplyModule > .mainContainerApplyingMod>.txtPartApplyMod > .paraApplyModTxt{
    color:#FFFFFF;

}

.expressiveCard > .mainApplyModule > .mainContainerApplyingMod>.txtPartApplyMod > .picPartUserAppMod > .nameApplyModHolder{
    color:#FFFFFF;

}
.expressiveCard > .mainApplyModule > .mainContainerApplyingMod>.txtPartApplyMod > .picPartUserAppMod > .nameApplyModHolder > .spCountryAppMod{
    color:#FFFFFF;

}
.mostStableMK2Txt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}

.mainHeadingMK2 {
  font-family: "Chillax";
  padding-top: 60px;
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Light mode (dark grey) */

  color: #222726;
}
.spMainHeadingMK2 {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 200;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Light mode (dark grey) */

  color: #222726;
}
.mainParaMK2 {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  padding-top: 60px;

  /* Light mode (dark grey) */

  color: #222726;
  max-width: 610px;
  margin: auto;
}
.mainContainerMK2 {
  /* display: flex;
    flex-direction: column;
    align-items: center;
     */
  padding-top: 50px;
  background-color: white;
}

.extemlyStableTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  /* Light mode (dark grey) */

  color: #222726;
}
.pageRandMK2 {
  /* Body regular */

  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Light mode (dark grey) */

  color: #222726;
  padding-top: 20px;
}
.moreTxtMK2 {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Light mode (dark grey) */

  color: #222726;
  padding-top: 20px;
}
.secondRowTxtPartMK2 {
  max-width: 407px;
}
.moreAboutTop {
  display: flex;

  align-items: center;
}
.picArrow {
  margin-left: 10px;
  margin-top: 5px;
}

.MK2SecondRow {
  display: flex;
  padding-top: 50px;
  justify-content: center;
}

.MK2VideoTop > .video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  /* width: 800%; */
  height: 422px;
  padding: 0px !important;
  border-radius: 20px;
  width: 611px;
}
.picPortionMainMK2 {
  display: flex;

  max-width: 1200px;
  margin: auto;
}
.txtMorphosStudioTZMF {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  text-align: right;

  /* Light mode (dark grey) */

  color: #222726;
}
.topMorphosArrowTZMF {
  display: flex;
  justify-content: flex-end;
}
.dropArrowMK2 {
  padding-left: 50px;
}
.topMorphosArrowTZMF {
  padding-right: 177px;
  padding-top: 225px;
  padding-bottom: 283px;
}
.learnTxtTop {
  display: flex;
  justify-content: center;
}

@media (max-width: 1453px) {
  .topMorphosArrowTZMF {
    padding-right: 110px;
  }
}

@media (max-width: 1163px) {
  .MK2VideoTop {
    margin-left: 20px;
  }
}

@media (max-width: 991px) {
  .MK2VideoTop > .video-js {
    width: 95%;
  }

  .topMorphosArrowTZMF {
    padding-bottom: 328px;
  }
  .pageRandMK2 {
    text-align: center;
  }
  .extemlyStableTxt {
    text-align: center;
    font-size: 30px;
  }
  .MK2VideoTop {
    margin-left: 0px;
  }
  .MK2SecondRow {
    flex-direction: column;
    align-items: center;
  }
  .MK2VideoTop > .video-react.video-react-fluid,
  .video-react.video-react-16-9,
  .video-react.video-react-4-3 {
    width: 85%;
  }
  .MK2VideoTop {
    width: 100%;
    display: flex;

    justify-content: center;
    padding-top: 90px;
  }

  .mainHeadingMK2 {
    font-size: 70px;
    line-height: 90px;
  }
  .spMainHeadingMK2 {
    font-size: 70px;
    line-height: 90px;
  }
  .monoMK2 {
    width: 100%;
  }
  .topMorphosArrowTZMF {
    padding-right: 0px;
    justify-content: center;
  }
  .txtMorphosStudioTZMF {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .mainHeadingMK2 {
    font-size: 45px;
    line-height: 70px;
  }
  .spMainHeadingMK2 {
    font-size: 45px;
    line-height: 70px;
  }

  .mainParaMK2 {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .MK2SecondRow {
    padding-top: 10px;
  }
  .MK2VideoTop > .video-react.video-react-fluid,
  .video-react.video-react-16-9,
  .video-react.video-react-4-3 {
    width: 95%;
  }
  .extemlyStableTxt {
    line-height: 35px;
    font-size: 30px;
  }
}

@media (max-width: 455px) {
  .dropArrowMK2 {
    padding-left: 2px;
  }
  .txtMorphosStudioTZMF {
    font-size: 25px;
  }
}

.MK2mainContainerSimplePowerful {
  width: 100%;
  height: auto;
  background-color: #222726;
  display: flex;
  padding-bottom: 204px;
  height: auto;

  flex-direction: column;
  align-items: center;
}

ul {
  padding-left: 0;
  list-style-type: none;
}

.MK2doItAllTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;
  padding-bottom: 57px;

  /* Dark mode (white) */

  color: #222726;
  padding-top: 80px;
}
.MK2simplePowerfulTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 300;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Dark mode (white) */

  color: #222726;
  padding-bottom: 065px;
  max-width: 699px;
}

.MK2firstInputSimpPow2 {
  max-width: 80%;
}

.MK2firstInputSimpPow3 {
  max-width: 80%;
  min-width: 300px;
}

.MK2firstInputSimpPow4 {
  max-width: 400px;
  min-width: 300px;
  padding-right: 10px;
}

.MK2mainParaSiimplePower {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #222726;
  width: 90%;
  max-width: 600px;
}

.MK2txtPartSimplPow {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MK2spYetPowerTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Dark mode (white) */

  color: #222726;
}
.MK2mainParaSpTxtSimpPow {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #222726;
}
.MK2mainPicSimplePower {
  padding-top: 169px;
  padding-bottom: 306px;
  width: 75%;
}
.MK2mainPicPart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 80px;
}

.MK2txtSimpPowFirstRow {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  /* Dark mode (white) */

  color: #222726;
  max-width: 500px;
}
.MK2subTxtSimpPowFirstRow {
  /* Body regular */

  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #222726;
  max-width: 300px;
}
.MK2subTxtSimpPowFirstRow > ul {
  padding-left: 0;
}

.MK2subTxtSimpPowFirstRow > ul > li {
  list-style-type: none;
}

.MK2imgageLeftSimp {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

#MK2undoRedo {
  margin-right: 20px;
}
.MK2txtParaRightSimp {
  padding-left: 48px;
  width: 500px;
}
.MK2lineCopy1 {
  position: absolute;
  bottom: -151px;
}
.MK2learnMoreTxtSimp {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Dark mode (white) */

  color: #222726;
  margin-left: 16px;
}
.MK2step1Img2 {
  position: absolute;
  left: 14px;
  z-index: 35;
  -webkit-filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
.MK2step1Img3 {
  position: absolute;
  left: 28px;
  z-index: 2;
  -webkit-filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
.step1Img4 {
  position: absolute;
  left: 22px;
  z-index: 0;
  -webkit-filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}

.MK2step1Img {
  position: relative;
  z-index: 999;
  -webkit-filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
.MK2group64Img {
  position: absolute;
  left: 70px;
  -webkit-filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.15));
}
#MK2cirlceLinePow1 {
  margin-left: 61px;
}
.MK2lineCopySecond {
  position: absolute;
  bottom: -151px;
  z-index: 999;
  right: 64px;
}
#MK2mainPicPart2 {
  margin-left: -46px;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}
#MK2mainPicPart3 {
  margin-top: -40px;
  display: flex;
  justify-content: center;
  padding-right: 120px;
}
.MK2mainPicPart2 {
  margin-top: -62px;
}
#MK2lineCopy3 {
  position: absolute;
  bottom: -125px;
}
#MK2mainPicPart5 {
  margin-top: 40px;
}
#MK2mainPicPart6 {
  margin-top: 60px;
}
#MK2mainPicPart7 {
  margin-top: 75px;
}
#MK2mainPicPart6 {
}
.MK2lowerDivSim {
  width: 80%;

  display: flex;

  flex-direction: column;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
  padding-bottom: 80px;
}
.MK2upperDivSim {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.MK2undoRedoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 429px;
}
@media (max-width: 1450px) {
  .MK2lowerDivSim {
    width: 100%;
  }
}

@media (max-width: 1166px) {
  #MK2lineCopy3 {
    top: 100px;
  }
  .MK2lineCopy1 {
    position: absolute;
    bottom: -151px;
    height: 150px;
  }
  .MK2firstInputSimpPow {
    width: 100px;
  }
  .MK2step1Img {
    width: 100px;
  }
  .MK2group64Img {
    width: 100px;
    left: 45px;
  }
  .MK2step1Img3 {
    width: 100px;
  }
  .MK2txtSimpPowFirstRow {
    font-size: 30px;
    line-height: 35px;
  }
  .MK2subTxtSimpPowFirstRow {
    font-size: 12px;
  }
  .MK2undoImg {
    width: 100px;
  }
  .MK2redoImg {
    width: 100px;
  }
  .MK2step1Img2 {
    width: 100px;
  }
  .MK2cirlceLinePow1 {
    width: 72px;
  }
  .MK2mainPicPart {
    padding-top: 50px;
    width: 100%;
  }
  #MK2mainPicPart3 {
    margin-top: 20px;
    justify-content: center;
    padding-right: 0px;
  }
  #MK2lineCopy1Id {
    height: 140px;
    bottom: -135px;
  }

  #MK2endLineCopyID {
    height: 120px;
    bottom: -120px;
  }

  #MK2lineCopy3 {
    height: 120px;
    bottom: -135px;
  }
  .MK2undoRedoContainer {
    width: 251px;
  }

  #MK2txtParaRightSimpId1 {
    padding-left: 50px;
  }
  #MK2mainPicPart2 {
    margin-left: 0px;
  }
  .MK2lineCopySecond {
    right: 20px;
    top: 100px;
  }
  .MK2txtParaRightSimp {
    padding-left: 100px;
    width: 600px;
  }
  #MK2txtParaRightSimp {
    padding-left: 24px;
  }
}

@media (max-width: 823px) {
  .MK2simplePowerfulTxt {
    font-size: 70px;
    line-height: 90px;
  }
  .MK2spYetPowerTxt {
    font-size: 70px;
    line-height: 90px;
  }
  .MK2mainPicSimplePower {
    width: 95%;
  }

  .MK2lowerDivSim {
    width: 80%;
  }
  #MK2txtParaRightSimp {
    padding-left: 0px;
  }

  .MK2upperDivSim {
    justify-content: center;
  }
  #MK2mainPicPart3 {
    margin-left: -150px;
  }
  #MK2mainPicPart2 {
    margin-left: -53px;
  }
}

@media (max-width: 751px) {
  .MK2txtParaRightSimp {
    text-align: center;
    width: 100%;
    padding-left: 0;
  }
  .MK2subTxtSimpPowFirstRow {
    max-width: 100%;
  }
  .MK2txtSimpPowFirstRow {
    max-width: 100%;
  }
  .MK2mainPicPart {
    flex-direction: column;
    padding-top: 0px;
  }
  #MK2mainPicPart3 {
    margin-left: 0px;
  }
  .MK2lowerDivSim {
    width: 80%;
  }
  .MK2txtSimpPowFirstRow {
    font-size: 14px;
    line-height: 18px;
  }
  .MK2subTxtSimpPowFirstRow {
    font-size: 10px;
    line-height: 16px;
  }

  #MK2lineCopy1Id {
    height: 80px;
    bottom: -80px;
  }
  .MK2learnMoreTxtSimp {
    font-size: 10px;
    line-height: 16px;
  }

  .MK2upperDivSim {
    justify-content: center;
  }

  .MK2lineCopy1 {
    bottom: -192px;
    height: 210px;
  }
  .MK2lineCopySecond {
    bottom: -249px;
    height: 150px;
  }
  #MK2lineCopy3 {
    bottom: -195px;
  }
  .MK2cirlceLinePow1 {
    visibility: hidden;
  }
  #MK2mainPicPart3 {
    margin-left: 0px;
  }
  #MK2mainPicPart2 {
    margin-left: 0px;
  }
  #MK2txtParaRightSimpId1 {
    padding-left: 0px;
  }
}

@media (max-width: 500px) {
  .MK2simplePowerfulTxt {
    font-size: 50px;
    line-height: 65px;
  }
  .MK2spYetPowerTxt {
    font-size: 50px;
    line-height: 65px;
  }
}

.tabTxtRand1 {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ff4203;
  transition: color 0.5s;
}

.arrowImgSmall {
  margin-left: 10px;
}

.tabTxtRand {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222726;
  transition: color 0.5s;
}
.txtMainTabRand {
  padding-left: 31px;
  padding-right: 29px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.txtMainTabRandBottom {
  height: 4px;
  background: #222726;
  width: 97%;
  padding-left: 31px;
  padding-right: 29px;
  margin-bottom: 40px;
  display: flex;
}
.mainTabBarPgRand {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottomTxtRand {
  height: 4px;
  background-color: #222726;
  width: 100%;

  transition: background-color 0.5s;
}
.bottomTxtRand1 {
  height: 4px;
  background-color: #ff4203;
  width: 100%;
  transition: background-color 0.5s;
}
.tabDivLineTxt {
  width: 15%;
  cursor: pointer;
}
/* .bottomTxtRand1{
    height: 4px;
    background:  #FF4203;;

    width: 23%;
    margin-left: -32px;
}
.bottomTxtRand2{
    height: 4px;
    background:  #FF4203;

    width: 21.5%;
    margin-left: -32px;
}
.bottomTxtRand3{
    height: 4px;
    background:  #FF4203;;

    width: 20.5%;
    margin-left: -32px;
}
.bottomTxtRand4{
    height: 4px;
    background:  #FF4203;;

    width: 500px;
    margin-left: -32px;
} */
.pageTabVideoDetail {
  width: 80%;
  padding-top: 96px;
  padding-bottom: 96px;
  display: flex;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.pageTabRowVideoDetail {
  width: 100%;
}

.randLeftElements {
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 0;
  justify-content: center;
  padding: 0;
  opacity: 1;
  -webkit-opacity: 1;
}

.fadeIn {
  animation: fade-in 0.5s forwards;
  -webkit-animation: fade-in 0.5s forwards;
}

.fadeOut {
  animation: fade-out 0.5s forwards;
  -webkit-animation: fade-out 0.5s forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    -webkit-opacity: 0;
  }
  100% {
    -webkit-opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fade-out {
  0% {
    -webkit-opacity: 1;
  }
  100% {
    -webkit-opacity: 0;
  }
}

.oneTxt {
  padding-right: 60px;
  margin-top: 10px;
  margin-bottom: auto;
}
.pageRandTxtHeading {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  max-width: 400px;
  /* identical to box height, or 107% */

  /* Light mode (dark grey) */

  color: #222726;
}
.mainParaPagRand {
  /* Body regular */

  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Light mode (dark grey) */

  color: #222726;
  width: 407px;
}
.txtLearnPageRand {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Light mode (dark grey) */

  color: #222726;
}

.txtLearnPageRand:hover {
  color: #ff4203;
}

.randRightElements {
  padding: 0;
  margin: auto;
}

.randVideoContainer {
  margin: auto;
  margin-bottom: 40px;
}

.randAnimEle {
  position: relative;
  overflow: hidden;
  top: -40px;
  left: -70px;
  width: 750px;
}

@media (max-width: 620px) {
  .randAnimContainer {
    height: 240px;
    width: 360px;
  }

  .randAnimEle {
    position: relative;
    overflow: hidden;
    top: -20px;
    left: -48px;
    width: 450px;
  }
}

.oneTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */

  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}
.howItWorksTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
}
.howToWorkMainTabRand {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
}
.letTxtRandTab {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  /* Light mode (dark grey) */

  color: #222726;
}
.letsMakePart {
  display: flex;
  padding-left: 142px;
  padding-top: 199px;
  padding-bottom: 267px;
}
.downArrow {
  padding-right: 43px;
}
.howSWorkTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
}
.dropDownPointer {
  padding-top: 30px;
  z-index: 9;
}

.TabVideo {
  /* width: 800%; */
  max-height: 400px;
  padding: 0px !important;
  border-radius: 20px;
  margin: auto;

  width: 90%;
  height: 49vw;
}

.howToWorkMainTabRand {
  width: 100%;
}

@media (max-width: 1400px) {
  .tabTxtRand {
    font-size: 16px;
  }
  .tabTxtRand1 {
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .letsMakePart {
    padding-left: 85px;
  }
  .tabTxtRand {
    font-size: 13px;
  }

  .tabTxtRand1 {
    font-size: 13px;
  }
  .randRightElements {
    padding-top: 50px;
  }
}
@media (max-width: 980px) {
  .bottomTxtRand {
    height: 3px;
  }
  .bottomTxtRand1 {
    height: 3px;
  }
  .tabTxtRand {
    font-size: 10px;
    margin-bottom: 6px;
  }

  .tabTxtRand1 {
    font-size: 10px;
    margin-bottom: 6px;
  }

  .pageRandDetail {
    padding-top: 40px;
  }
}

@media (max-width: 950px) {
  .letsMakePart {
    padding-left: 0px;
    padding-top: 147px;
    padding-bottom: 350px;
    justify-content: center;
  }
}
@media (max-width: 870px) {
  .txtMainTabRand {
    justify-content: center;
    align-items: center;
  }
  .tabDivLineTxt {
    width: 90%;
    padding-top: 10px;
    text-align: center;
  }
  .howToWorkMainTabRand {
    padding-top: 60px;
  }

  .tabDivLineFullTxt {
    display: none;
  }
  .tabTxtRand {
    font-size: 16px;
  }

  .tabTxtRand1 {
    font-size: 16px;
  }
}
@media (max-width: 619px) {
  .pageRandDetail {
    justify-content: center;
  }
  .mainParaPagRand {
    width: 80%;
    font-size: 14px;
  }
  .pageRandTxtHeading {
    font-size: 30px;
    line-height: 30px;
  }
  .oneTxt {
    margin-left: 5px;
    padding-right: 15px;
  }
  .letsMakePart {
    padding-left: 20px;
    padding-right: 20px;
  }
  .letTxtRandTab {
    font-size: 30px;
  }
  .tabTxtRand {
    font-size: 14px;
  }
  .tabTxtRand1 {
    font-size: 14px;
  }
}

.mainContainerAudioMonoB {
  background-color: #222726;
  display: flex;
  width: 85%;
  justify-content: flex-end;
  height: 650px;
}
.audioTopMainMBl {
  width: 612px;
  height: 272px;
  background-color: #ff4203;
  border-radius: 20px;
  margin-top: 194px;
  position: relative;
  left: 130px;
}
.surEverBurTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}
.surEverSpTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}
.topParaTxtAudioMB {
  padding-left: 60px;
  padding-top: 23px;
}
.mainParaAudioMonoB {
  /* Heading 2 semibold */

  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  /* Dark mode (white) */

  color: #ffffff;
  margin-right: -20px;
}
.surgenTxt {
  /* Body regular */

  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}
.ukTxtAudioMono {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}
.mainTopTextPartAudio {
  width: 406px;
  padding-top: 168px;
}

.picTopAudioSur {
  display: flex;
}
.txtSUAudio {
  padding-left: 25px;
}
@media (max-width: 1200px) {
  .mainParaAudioMonoB {
    font-size: 30px;
  }
  .mainTopTextPartAudio {
    padding-left: 30px;
  }
}
@media (max-width: 991px) {
  .mainContainerAudioMonoB {
    flex-direction: column;
    height: auto;
    width: 100%;
    align-items: center;
  }

  .mainContainerAudioMonoB {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .audioTopMainMBl {
    margin-top: 0px;
  }
  .audioTopMainMBl {
    left: 0px;
  }
  .mainTopTextPartAudio {
    padding-left: 0px;
    padding-top: 85px;
    padding-bottom: 85px;
  }
  .mainParaAudioMonoB {
    margin-right: 0px;
  }
  .audioTopMainMBl {
    width: 90%;
  }
}
@media (max-width: 427px) {
  .mainTopTextPartAudio {
    padding-left: 25px;
    padding-right: 25px;
    width: auto;
  }
}

.contextMorTxt1 {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Dark mode (white) */

  color: #ffffff;
}
.contextModMorHeading {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 300;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Dark mode (white) */

  color: #ffffff;
}
.contextModMorHeadingSp {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Dark mode (white) */

  color: #ffffff;
}
.mainParaTxtMorphCont {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #ffffff;
}
.mainParaTxtMorphContSp {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #ffffff;
}
.paraTxtCvInput {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  /* Dark mode (white) */

  color: #ffffff;
}
.txtButtLed {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}
.mainContainerContextualMorphos {
  background-color: #222726;
  height: auto;
}
.textPartConModMor {
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contextModMorHeading {
  padding-top: 57px;
}
.mainParaTxtMorphCont {
  padding-top: 60px;
  width: 610px;
}
.txtModParaMorPart {
  width: 550px;
  margin-left: 118px;
}
.picTextBottomConMod {
  display: flex;
  justify-content: center;
  padding-top: 160px;
  padding-bottom: 170px;
}
.txtButtLed {
  padding-top: 15px;
}
.dropArrowContMor {
  padding-top: 15px;
}

@media (max-width: 900px) {
  .textPartConModMor {
    padding-top: 80px;
  }

  .contextModMorHeading {
    font-size: 70px;
    line-height: 60px;
  }
  .contextModMorHeadingSp {
    font-size: 70px;
    line-height: 60px;
  }
  .mainParaTxtMorphCont {
    font-size: 12px;
  }
  .mainParaTxtMorphContSp {
    font-size: 12px;
  }
  .picTextBottomConMod {
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .txtModParaMorPart {
    padding-top: 60px;
    margin-left: 0px;
  }
}

@media (max-width: 647px) {
  .mainParaTxtMorphCont {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .txtModParaMorPart {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .paraTxtCvInput {
    text-align: center;
  }
  .txtButtLed {
    text-align: center;
  }
  .paraTxtCvInput {
    font-size: 30px;
    line-height: 35px;
  }
}
@media (max-width: 495px) {
  .contextModMorHeading {
    font-size: 45px;
    line-height: 55px;
  }
  .contextModMorHeadingSp {
    font-size: 45px;
    line-height: 55px;
  }
  .puamPicCont {
    width: 100%;
  }
}

.mainIntroMorphingModule {
  width: 100%;
  background-color: #222726;
  display: flex;

  flex-direction: column;
  align-items: center;
  margin-bottom: -260px;
}

.lastAnimTriggerMorphos {
  height: 700px;
}

.animSubtext {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}

.morphosAnimComponent {
  width: 1000px;
  margin-top: -200px;
  -webkit-transform: translateX(-20%);
          transform: translateX(-20%);
}

.hideOverflowMorphos {
  padding-top: 100px;
}

.morphosAnimContainer {
  margin-top: 250px;
  overflow: hidden;
}

.moprhosSvgContainer {
  position: absolute;
  overflow: hidden;
  left: 50%;
  -webkit-transform: translateX(-75%);
          transform: translateX(-75%);
}

@media (max-width: 1080px) {
  .mainIntroMorphingModule {
    margin-top: 50px;
    top: 150px;
  }

  .animSubtext {
    font-size: 14px;
    line-height: 18px;
  }
  .moprhosSvgContainer {
    margin-top: 240px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

@media (max-width: 700px) {
  .moprhosSvgContainer {
    margin-top: 100px;
  }
  .morphosAnimComponent {
    width: 500px;
    margin-top: 50px;
  }
  .morphosAnimContainer {
    -webkit-transform: translateY(-40%);
            transform: translateY(-40%);
    margin-top: 280px;
  }

  .animSubtext {
    font-size: 12px;
    line-height: 16px;
  }
}

.mainMorTab {
  background-color: #222726;
}

.randAnimEleMorpos {
  position: relative;
  overflow: hidden;
  width: 500px;
  left: 50px;
}

.mainMorTab > .txtMainTabRand > .tabDivLineTxt {
  width: 20%;
}

.mainMorTab > .txtMainTabRand > .tabDivLineTxt > .tabTxtRand {
  color: #ffffff;
}

.mainMorTab > .txtMainTabRand > .tabDivLineTxt > .bottomTxtRand {
  background: #ffffff;
}

.mainMorTab > .pageRandDetail > .randLeftElements > .oneTxt {
  color: #ffffff;
}

.mainMorTab
  > .pageRandDetail
  > .randLeftElements
  > .topDetailOnClick
  > .pageRandTxtHeading {
  color: #ffffff;
}

.mainMorTab
  > .pageRandDetail
  > .randLeftElements
  > .topDetailOnClick
  > .mainParaPagRand {
  color: #ffffff;
}
.mainMorTab
  > .pageRandDetail
  > .randLeftElements
  > .topDetailOnClick
  > .txtLearnPageRand {
  color: #ffffff;
}

.mainMorTab > .pageRandDetail > .randRightElements > .randAnimContainer {
  border: 1px solid #ffffff;
}

.mainMorTab > .howToWorkMainTabRand > .howSWorkTxt {
  color: #ffffff;
}

.mainMorTab > .letsMakePart > .letTxtRandTab {
  color: #ffffff;
}

@media (max-width: 870px) {
  .mainMorTab > .txtMainTabRand > .tabDivLineTxt {
    width: 90%;
  }
}

@media (max-width: 620px) {
  .randAnimEleMorpos {
    width: 350px;
    left: 0px;
  }
}

.headerCreativityMorphos {
  font-weight: 600;
}

.cutMorphosImg {
  width: 50%;
  max-width: 500px;
  margin-top: -30px;
}

.percentOff {
  position: absolute;
  margin-top: 120px;
  margin-left: 200px;
  color: #222726;
  font-family: "chillax";
  font-weight: 400;
  font-size: 18px;
}

.btnOrderNowMorphos {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-size: 1rem;
  font-weight: 400;
  margin-top: -50px;
  margin-bottom: 78px;
  transition: box-shadow 0.5s;
}

.morphoscrossed {
  text-decoration: line-through;
  color: #ff9e7e;
  margin: 5px;
}

.btnOrderNowMorphos:hover {
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.5s;
}

@media (max-width: 1280px) {
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityNorand {
    font-size: 102px;
  }
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityMorphos {
    font-size: 102px;
  }
}

@media (max-width: 1040px) {
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityNorand {
    font-size: 70px;
  }

  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityMorphos {
    font-size: 70px;
  }
  .cutMorphosImg {
    width: 60%;
    margin-top: -20px;
  }
}

@media (max-width: 749px) {
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityNorand {
    font-size: 50px;
  }

  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityMorphos {
    font-size: 50px;
  }

  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .btnOrderNow {
    margin-top: -49px;
  }
}

@media (max-width: 630px) {
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .p1Creativity {
    font-size: 20px;
  }
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityNorand {
    font-size: 40px;
  }
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityMorphos {
    font-size: 40px;
  }
  .cutMorphosImg {
    width: 70%;
    margin-top: -10px;
  }
}
@media (max-width: 547px) {
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .btnOrderNow {
    margin-top: -32px;
  }

  .percentOff {
    margin-left: 100px;
  }
}
@media (max-width: 448px) {
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .p1Creativity {
    font-size: 15px;
  }
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityNorand {
    font-size: 32px;
  }
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityMorphos {
    font-size: 32px;
  }
}

@media (max-width: 388px) {
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityMorphos {
    line-height: 60px;
  }
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .cutMonoImg {
    margin-top: -54px;
  }
}

.mainContainerVco {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: white;
}
.morhposVCOPic {
  padding-top: 104px;
}

.firstPicM {
  width: 650px;
}
.mainParaVco {
  padding-top: 150px;
  /* Heading 2 semibold */

  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  text-align: center;
  width: 814px;
}
.dropArrowVco {
  padding-top: 60px;
  padding-bottom: 117px;
}

.disTxtVco {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #222726;
  margin-bottom: 0px;
}

.disTxtVco:hover {
  color: #ff4203;
}
.spVcoMainPara {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  text-align: center;
  width: 814px;
  color: #20ead6;
}
.mainPicVCO {
  padding-top: 100px;
  /* width: 100%;
     justify-content: center;
     flex-direction: column;
     align-items: center; */
}
.d3TxtVcoUp {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */

  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}
.d3TxtVcoUpSp {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */

  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}
.firstRowVcoTxtUp {
  display: flex;
  justify-content: space-between;
}
.imgTopMorPRoMain {
  display: flex;
  justify-content: center;
  position: relative;
  width: 1000px;
}
.contextModTop {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 650px;
}
.topPeepCircleIconVco {
  position: absolute;
  left: 450px;
  top: -20px;
}
.touchHereCircle {
  position: absolute;
  top: 45px;
  left: -214px;
}
.circledLine1 {
  position: absolute;
  top: -60px;
  left: -160px;
}
.firstRowLeftTxt {
  text-align: right;
  margin-left: 80px;
}
.topPeepCircleIconVco1 {
  position: absolute;
  right: 250px;
  top: -100px;
}
.touchHereCircle1 {
  position: absolute;
  top: 250px;
  left: -150px;
}
.circledLine11 {
  position: absolute;
  top: 20px;
  left: -100px;
}
.topPeepCircleIconVco2 {
  position: absolute;
  bottom: 140px;
  right: 400px;
}
.touchHereCircle2 {
  position: absolute;
  top: -88px;
  left: -47px;
}
.circledLine2 {
  position: absolute;
  top: 40px;
  left: 5px;
}

@media (max-width: 1080px) {
  .imgTopMorPRoMain {
    width: 800px;
  }
  .firstRowVcoTxtUp {
    width: 800px;
  }
  .contextModTop {
    margin-left: 450px;
  }
}
@media (max-width: 825px) {
  .firstPicM {
    width: 500px;
  }
  .mainParaVco {
    width: auto;
    font-size: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .spVcoMainPara {
    font-size: 30px;
  }
  .morhposVCOPic {
    width: 97%;
  }

  .d3TxtVcoUp {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
  }
  .d3TxtVcoUpSp {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
  }

  .firstRowVcoTxtUp {
    width: 600px;
  }
  .imgTopMorPRoMain {
    justify-content: center;
    width: auto;
  }
  .mainPicVCO {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contextModTop {
    width: auto;
    align-items: unset;
  }
  .tzfmAnalogOsciTop {
    padding-left: 50px;
  }

  .topPeepCircleIconVco {
    position: absolute;
    left: 490px;
    top: -5px;
  }

  .topPeepCircleIconVco1 {
    position: absolute;
    right: 240px;
    top: -88px;
  }
  .topPeepCircleIconVco2 {
    position: absolute;
    bottom: 140px;
    right: 160px;
  }
}

@media (max-width: 600px) {
  .mainParaVco {
    font-size: 25px;
    line-height: 30px;
  }
  .spVcoMainPara {
    font-size: 25px;
    line-height: 30px;
  }
  .mainParaVco {
    padding-top: 130px;
  }

  .dropArrowVco {
    padding-top: 50px;
    padding-bottom: 40px;
  }
}

@media (max-width: 506px) {
  .touchHereCircle {
    width: 66px;

    left: -185px;
    top: 40px;
  }
  .topPeepCircleIconVco {
    top: 30px;
    left: 220px;
  }

  .touchHereCircle1 {
    top: 152px;
    left: -130px;
    width: 66px;
  }

  .firstPicM {
    width: 350px;
    object-fit: cover;
  }
  .circledLine11 {
    height: 140px;
  }
  .touchHereCircle2 {
    top: -20px;
    left: -22px;
    width: 66px;
  }
  .topPeepCircleIconVco1 {
    right: -50px;
    top: -72px;
  }
  .topPeepCircleIconVco2 {
    bottom: 140px;
    right: 240px;
  }
  .firstRowVcoTxtUp {
    width: 350px;
  }
  .firstRowLeftTxt {
    margin-left: 0px;
  }
  .tzfmAnalogOsciTop {
    padding-left: 0px;
    padding-bottom: 30px;
  }
  .contextModTop {
    margin-left: 120px;
  }
}

.mostStableTZFMTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}

.mainHeadingTZFM {
  font-family: "Chillax";
  padding-top: 60px;
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Light mode (dark grey) */

  color: #222726;
}
.spMainHeadingTZFM {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 200;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Light mode (dark grey) */

  color: #222726;
}
.mainParaTZFM {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  padding-top: 60px;

  /* Light mode (dark grey) */

  color: #222726;
  max-width: 610px;
  margin: auto;
}
.mainContainerTZFM {
  /* display: flex;
    flex-direction: column;
    align-items: center;
     */
}
.TZFMFirstRow {
  padding-top: 170px;
}

.extemlyStableTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  /* Light mode (dark grey) */

  color: #222726;
}
.pageRandTZFM {
  /* Body regular */

  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Light mode (dark grey) */

  color: #222726;
  padding-top: 20px;
}
.moreTxtTZFM {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Light mode (dark grey) */

  color: #222726;
  padding-top: 20px;
}
.secondRowTxtPartTZFM {
  max-width: 407px;
}
.moreAboutTop {
  display: flex;

  align-items: center;
}
.picArrow {
  margin-left: 10px;
  margin-top: 5px;
}

.TZFMVideoTop {
  margin-left: 100px;
}
.TZFMSecondRow {
  display: flex;
  padding-top: 174px;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}

.TZFMVideoTop > .video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  /* width: 800%; */
  height: 422px;
  padding: 0px !important;
  border-radius: 20px;
  width: 611px;
}
.picPortionMainTZFM {
  display: flex;

  max-width: 1200px;
  margin: auto;
}
.txtMorphosStudioTZMF {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  text-align: right;

  /* Light mode (dark grey) */

  color: #222726;
}
.topMorphosArrowTZMF {
  display: flex;
  justify-content: flex-end;
}
.dropArrowTZFM {
  padding-left: 50px;
}
.topMorphosArrowTZMF {
  padding-right: 177px;
  padding-top: 225px;
  padding-bottom: 283px;
}
.learnTxtTop {
  display: flex;
  justify-content: center;
}

@media (max-width: 1453px) {
  .topMorphosArrowTZMF {
    padding-right: 110px;
  }
}

@media (max-width: 1163px) {
  .TZFMVideoTop {
    margin-left: 20px;
  }
}

@media (max-width: 991px) {
  .TZFMVideoTop > .video-js {
    width: 95%;
  }

  .topMorphosArrowTZMF {
    padding-bottom: 328px;
  }
  .pageRandTZFM {
    text-align: center;
  }
  .extemlyStableTxt {
    text-align: center;
    font-size: 30px;
  }
  .TZFMVideoTop {
    margin-left: 0px;
  }
  .TZFMSecondRow {
    flex-direction: column;
    align-items: center;
  }
  .TZFMVideoTop > .video-react.video-react-fluid,
  .video-react.video-react-16-9,
  .video-react.video-react-4-3 {
    width: 85%;
  }
  .TZFMVideoTop {
    width: 100%;
    display: flex;

    justify-content: center;
    padding-top: 90px;
  }

  .mainHeadingTZFM {
    font-size: 70px;
    line-height: 90px;
  }
  .spMainHeadingTZFM {
    font-size: 70px;
    line-height: 90px;
  }
  .monoTZFM {
    width: 100%;
  }
  .topMorphosArrowTZMF {
    padding-right: 0px;
    justify-content: center;
  }
  .txtMorphosStudioTZMF {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .mainHeadingTZFM {
    font-size: 45px;
    line-height: 70px;
  }
  .spMainHeadingTZFM {
    font-size: 45px;
    line-height: 70px;
  }

  .mainParaTZFM {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .TZFMSecondRow {
    padding-top: 100px;
  }
  .TZFMVideoTop > .video-react.video-react-fluid,
  .video-react.video-react-16-9,
  .video-react.video-react-4-3 {
    width: 95%;
  }
  .extemlyStableTxt {
    line-height: 35px;
    font-size: 30px;
  }
}

@media (max-width: 455px) {
  .dropArrowTZFM {
    padding-left: 2px;
  }
  .txtMorphosStudioTZMF {
    font-size: 25px;
  }
}



.manual-main-content {
  margin-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.manual-nav {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  flex-wrap: nowrap;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  margin-top: 60px;
  padding-top: 10px;
  box-shadow: 1px 0px 8px -2px rgba(0, 0, 0, 0.5);
  width: 100%;
  max-width: 300px;
}

.manual-rm-padding {
  padding-left: 0;
  padding-right: 0;
}

#manual-nav-highlight {
  font-weight: 700;
}
.manual-img {
  width: 100%;
}
.download-link-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.download-link {
  font-family: "Chillax";
  color: #222726;
  font-weight: 400;
  font-size: 24px;
  padding-top: 25px;
  float: right;
  transition: color 0.3s;
}

.download-link:hover {
  transition: color 0.3s;
  color: #ff4203;
}

.row {
  margin: 0;
}
h2 {
  font-family: "Chillax";
  color: #ff4203;
  font-weight: 500;
  font-size: 48px;
  padding: 40px;
  margin-top: 40px;
}
h3 {
  font-family: "Chillax";
  color: #222726;
  font-weight: 400;
  font-size: 32px;
  padding-top: 40px;
  padding-bottom: 20px;
}
h4 {
  font-family: "Chillax";
  color: #ff4203;
  font-weight: 400;
  font-size: 20px;
  padding: 2px 0;
}
.manual-main-content > p {
  width: 80%;
  font-family: "Archia";
  font-weight: 400;
  font-size: 16px;
}

.manual-main-content > ol > li {
  padding-bottom: 8px;
}
.manual-main-content > ol {
  list-style: none;
  counter-reset: li;
  width: 80%;
  max-width: 700px;
}
.manual-main-content > ol > li::before {
  content: counter(li);
  color: #222726;
  display: inline-block;
  width: 20px;
  margin-left: -20px;
  font-family: "Archia";
  color: #222726;
  font-weight: 500;
  margin-left: -1.5em;
  margin-right: 0.5em;
  text-align: right;
  direction: rtl;
}

ol#panel-layout > li::before {
  content: counter(li, upper-latin);
  color: #222726;
  display: inline-block;
  width: 20px;
  margin-left: -20px;
}

ol#connector-list > li::before {
  content: counter(li);
  color: black;
  display: inline-block;
  width: 20px;
  margin-left: -20px;
}

.manual-main-content > ol > li {
  counter-increment: li;
}

.manual-main-content > ul {
  list-style: disc;
  padding-left: 10px;
  width: 80%;
  max-width: 700px;
}

.strong-1 {
  color: #222726;
  font-weight: 600;
}

.strong-2 {
  color: black;
  font-weight: 500;
}

.ps {
  background-color: white;
  height: calc(100vh - 60px);
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  overflow-y: auto;
  padding-top: 10px;
  box-shadow: 1px 0px 8px -2px rgba(0, 0, 0, 0.5);
  max-width: 250px;
}
nav {
  width: 100%;
}

.nav-item {
  font-family: "Chillax";
  color: rgba(53, 80, 75, 0.4);
  font-weight: 400;
  font-size: 14px;
}
.nav-link {
  padding: 0;
  color: black;
  line-height: 20px;
  padding-bottom: 3px;
}
.nav-link:hover {
  color: rgba(34, 39, 38, 0.4);
}

.manual-nav > .active {
  background-color: inherit;
  font-weight: 700;
}
.title-1 {
  font-size: 16px;
  color: #ff4203;
  font-weight: 500;
  padding-left: 0px;
  margin-top: 1px;
  margin-bottom: 1px;
  transition: color 0.3s;
}
.title-1:hover {
  transition: color 0.3s;
  color: rgba(255, 66, 3, 0.75);
}
.title-2 {
  padding-left: 10px;
  color: rgba(34, 39, 38, 1);
}
.title-2:hover {
  transition: color 0.3s;
  color: rgba(34, 39, 38, 0.75);
}
.title-3 {
  color: rgba(34, 39, 38, 0.8);
  padding-left: 20px;
  font-size: 14px;
  line-height: 18px;
}
.title-3:hover {
  transition: color 0.3s;
  color: rgba(34, 39, 38, 0.55);
}
.row-bubble {
  margin: 60px 0;
  align-items: stretch;
  justify-content: space-around;
}
.card-col {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}
.emphasis {
  font-style: italic;
}
.row-settings {
  justify-content: space-around;
}

.settings-col {
  min-width: 200px;
}
.param-enum {
  background-color: #222726;
  font-weight: 600;
  color: white;
  display: inline-block;
  min-height: 24;
  min-width: 24px;
  text-align: center;
  border-radius: 12px;
  margin: 3px;
}
.card {
  background-color: #222726;
  height: 100%;
  max-width: 500px;
  padding: 20px;
  border-radius: 20px;
}
.card-title {
  text-align: center;
  color: white;
  font-family: "Chillax";
  font-weight: 400;
  font-size: 32px;
}

.card-img {
  margin: 20px auto;
  max-width: 300px;
  display: block;
}

.card-text {
  font-family: "Archia";
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 0;
  padding-top: 10px;
  text-align: justify;
}
ul {
  list-style: disc;
  padding-left: 20px;
  width: 100%;
}
li {
  line-height: 1.5;
  margin-bottom: 5px;
  font-family: "Archia";
}

.retailers-section-1 {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.retailers-text {
  width: 498px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #222726;
  padding-bottom: 20px;
}

.retailers-section-2 {
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  max-width: 1280px;
  margin-bottom: 100px;
}

.retailers-section-2 > .retailers-col > a {
  text-decoration: none;
  color: #222726;
}

.retailers-section-2 > .retailers-col > a:hover {
  text-decoration: none;
  color: #ff4203;
}

@media screen and (max-width: 767px) {
  .retailers-section-2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.mainContainerContacts {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.headingContactUs {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 110px;

  color: #222726;
}

.iconcontact {
  fill: black;
  font-size: 24px;
  margin-right: -24px;
}

.inputTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  color: #222726;

  mix-blend-mode: normal;
  opacity: 0.4;
}
.inputFieldContact {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid;

  background-color: inherit;
  outline: none;
  padding-left: 10px;
  width: 600px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-left: 30px;
  color: #222726;
  resize: none;
}

.btnContactSubmit {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 78px;
  transition: box-shadow 0.5s;
}

.btnContactSubmit:hover {
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.5s;
}

/* .btnContactSubmit:hover {
    color: #733447;
} */

.topInputFieldContact {
  padding-top: 40px;
}

.arrowLeftBtnContact {
  margin-left: 20px;
}

.buttonTopContact {
  display: flex;
  justify-content: flex-end;
  /* width: 40%; */
  width: 600px;
}
@media (max-width: 650px) {
  .topInputFieldContact {
    width: 93%;
  }
  .inputFieldContact {
    width: 100%;
  }
  .buttonTopContact {
    width: 99%;
    padding-right: 10px;
  }
  .headingContactUs {
    font-size: 50px;
  }
}

.contactUsTopPage > .navbar {
  /* background: linear-gradient(0deg,#00A0A3,#D15F5B); */
  background-image: url(/static/media/headerBackgroudColor.e4e09818.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 991px) {
  .contactUsTopPage > .navbar {
    /* background: linear-gradient(0deg,#00A0A3,#D15F5B); */
    background-image: none;
    background-color: #222726 !important;
  }
  .contactUsTopPage > .navbar > .navbar-collapse {
    background-color: #222726 !important;
  }
}

.topPrivacyPol {
}

.topPrivacyPol > .navbar {
  /* background: linear-gradient(0deg,#00A0A3,#D15F5B); */
  background-image: url(/static/media/headerBackgroudColor.e4e09818.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.containerPrivacyForWidth {
  max-width: 700px;
  padding-bottom: 50px;
  padding-right: 10px;
  padding-left: 10px;
}

.mainContainerPrivacyPolicy {
  display: flex;
  padding-top: 100px;

  flex-direction: column;
  align-items: center;
  width: 100%;
}

.privacyMainHeader {
  font-weight: 600;
  font-size: 32px;
  font-family: "Archia";
  font-style: normal;
  text-align: center;
  color: #222726;
}
.subHeadingPrivacyPolicy {
  font-weight: 400;
  font-size: 24px;
  font-family: "Archia";
  font-style: normal;

  color: #222726;
  padding-left: 10px;
}

.subSubHeadingPrivacyPol {
  font-weight: 400;
  font-size: 20px;
  font-family: "Archia";
  font-style: normal;

  color: #222726;
}
#privacyMainHeader {
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: "Archia";
  font-style: normal;
}
#privacyMainHeader1 {
  padding-bottom: 20px;
}
.paraTxtPrivacyPol {
  font-size: 14px;
  font-weight: 400;
  color: #222726;
  font-family: "Archia";
  font-style: normal;
}
@media (max-width: 991px) {
  .topPrivacyPol > .navbar {
    /* background: linear-gradient(0deg,#00A0A3,#D15F5B); */
    background-color: #222726 !important;
    background-image: none;
  }

  .topPrivacyPol > .navbar > .navbar-collapse {
    background-color: #222726 !important;
  }
}

@media (max-width: 600px) {
  .containerPrivacyForWidth {
    padding-right: 10px;

    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}


.termsCondTop> .navbar{
    /* background: linear-gradient(0deg,#00A0A3,#D15F5B); */
    background-color:  #222726;

}

@media (max-width:991px) {
    .termsCondTop> .navbar{
        /* background: linear-gradient(0deg,#00A0A3,#D15F5B); */
        background-color:  #222726 !important;
        background:red;
    
    }
    .termsCondTop >.navbar > .navbar-collapse  {
    background-color:  #222726 !important;
    background: red;

    }  
    
}
.about-section-1 {
  padding-top: 50px;
  display: flex;
  max-width: 1080px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #222726;
}

.about-section-1-left {
  display: flex;
}

.about-section-1-mid {
  margin-left: 100px;
  max-width: 70%;
}

.about-sectoin-primitive {
  max-width: 250px;
}

.about-section-1-right-txt {
  text-align: center;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.morphos-floating-img {
  position: relative;
  object-fit: contain;
  width: 350px;
  left: 10vw;
  -webkit-transform: translateX(-75%);
          transform: translateX(-75%);
  top: -100px;
}

.hide-overflow {
  margin-top: 200px;
  overflow: hidden;
  flex-basis: 25%;
}
.mono-floating-img {
  position: relative;
  width: 600px;
}

.about-section-2 {
  display: flex;
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 1em;
  margin-bottom: 50px;
  align-items: center;
  justify-content: space-between;
}

.about-section-2-mid {
  max-width: 35%;
}

.about-sectoin-biglive {
  max-width: 350px;
}

.about-section-3 {
  padding-top: 50px;
  display: flex;
  max-width: 1080px;
  margin: 0 auto;
  align-items: top;
  justify-content: space-between;
  margin-bottom: 50px;
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #222726;
}

.about-section-3-mid {
  margin-left: 60px;
  max-width: 60%;
}

.about-section-3-left {
  display: flex;
  margin-left: 50px;
}

.about-section-4 {
  background-image: url(/static/media/3DBackground.0ce9ce83.png);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -150px;
  padding-top: 150px;
  padding-bottom: 50px;
  font-size: 125px;
}

.about-section-4-cards {
  max-width: 1350px;
  margin: auto;
  margin-top: 100px;
}

.about-section-4-card {
  max-width: 550px;
  background-color: white;
  border-radius: 25px;
  -webkit-filter: drop-shadow(0px 6px 35px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 6px 35px rgba(0, 0, 0, 0.25));
  display: flex;
  align-items: center;
  padding: 15px 40px;
  margin-bottom: 80px;
}

.about-section-4-2cards {
  margin: auto;
  justify-content: space-around;
  display: flex;
  max-width: 100%;
}
.card-pic {
  margin-right: 30px;
}
.about-section-4-card > .card-text {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #222726;
}

.card-text-title {
  font-weight: 600;
  font-size: 25px;
}

.about-section-5-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-text {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #222726;
  line-height: 1em;
  margin: 0;
  margin-top: 10px;
}

.about-section-5-cards {
  display: flex;
  margin: auto;
  justify-content: space-around;
  max-width: 1000px;
  margin-bottom: 100px;
}

.face-for-real {
  width: 200px;
}

.tiret-for-real {
  width: 10px;
}

.team-subtext {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 2em;
  color: #222726;
  margin: 0;
}

@media screen and (max-width: 1350px) {
  .card-text {
    font-size: 14px;
  }

  .about-section-4-card {
    max-width: 500px;
    margin-bottom: 40px;
  }
  .card-text-title {
    font-size: 18px;
  }
  .card-pic-for-real {
    width: 100px;
  }
}

@media screen and (max-width: 1080px) {
  .about-section-1 {
    flex-direction: column;
    align-items: center;
  }

  .about-section-2 {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .hide-overflow {
    display: none;
  }

  .about-section-2-mid {
    max-width: 75%;
    min-width: 250px;
  }

  .morphos-floating-img {
    display: none;
  }

  .about-section-1-left {
    max-width: 90%;
    justify-content: center;
    margin-bottom: 50px;
  }

  .about-section-1-mid {
    margin-left: 30px;
  }

  .about-section-1-right-txt {
    font-size: 14px;
  }

  .about-section-3 {
    flex-direction: column;
    align-items: center;
  }

  .about-section-3-left {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    text-align: center;
  }
  .about-section-3-mid {
    margin-left: 0;
  }
  .about-sectoin-biglive {
    display: none;
  }
  .about-section-4 {
    margin-top: 0px;
    padding-top: 80px;
  }
  .about-section-4-2cards {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .about-section-4-card {
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    padding: 15px 20px;
    max-width: 90%;
    text-align: center;
  }
  .card-pic {
    margin-right: 0px;
  }
  .card-text {
    text-align: center;
  }
  .about-section-5-cards {
    flex-direction: column;
  }
}

.download-container {
    display: flex;
    justify-content: center;
}
