.tabTxtRand1 {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ff4203;
  transition: color 0.5s;
}

.arrowImgSmall {
  margin-left: 10px;
}

.tabTxtRand {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222726;
  transition: color 0.5s;
}
.txtMainTabRand {
  padding-left: 31px;
  padding-right: 29px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.txtMainTabRandBottom {
  height: 4px;
  background: #222726;
  width: 97%;
  padding-left: 31px;
  padding-right: 29px;
  margin-bottom: 40px;
  display: flex;
}
.mainTabBarPgRand {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottomTxtRand {
  height: 4px;
  background-color: #222726;
  width: 100%;

  transition: background-color 0.5s;
}
.bottomTxtRand1 {
  height: 4px;
  background-color: #ff4203;
  width: 100%;
  transition: background-color 0.5s;
}
.tabDivLineTxt {
  width: 15%;
  cursor: pointer;
}
/* .bottomTxtRand1{
    height: 4px;
    background:  #FF4203;;

    width: 23%;
    margin-left: -32px;
}
.bottomTxtRand2{
    height: 4px;
    background:  #FF4203;

    width: 21.5%;
    margin-left: -32px;
}
.bottomTxtRand3{
    height: 4px;
    background:  #FF4203;;

    width: 20.5%;
    margin-left: -32px;
}
.bottomTxtRand4{
    height: 4px;
    background:  #FF4203;;

    width: 500px;
    margin-left: -32px;
} */
.pageRandDetail {
  width: 100%;
  padding-top: 96px;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.randLeftElements {
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 0;
  justify-content: center;
  padding: 0;
  opacity: 1;
  -webkit-opacity: 1;
}

.fadeIn {
  animation: fade-in 0.5s forwards;
  -webkit-animation: fade-in 0.5s forwards;
}

.fadeOut {
  animation: fade-out 0.5s forwards;
  -webkit-animation: fade-out 0.5s forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    -webkit-opacity: 0;
  }
  100% {
    -webkit-opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fade-out {
  0% {
    -webkit-opacity: 1;
  }
  100% {
    -webkit-opacity: 0;
  }
}

.oneTxt {
  padding-right: 60px;
  margin-top: 10px;
  margin-bottom: auto;
}
.pageRandTxtHeading {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  max-width: 400px;
  /* identical to box height, or 107% */

  /* Light mode (dark grey) */

  color: #222726;
}
.mainParaPagRand {
  /* Body regular */

  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Light mode (dark grey) */

  color: #222726;
  width: 407px;
}
.txtLearnPageRand {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Light mode (dark grey) */

  color: #222726;
}

.txtLearnPageRand:hover {
  color: #ff4203;
}

.randRightElements {
  padding: 0;
  margin: 0;
}

.randAnimContainer {
  height: 400px;
  width: 600px;
  background-color: #222726;
  border-radius: 20px;
  box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.25);
  margin: auto;
  margin-bottom: 40px;
}

.randAnimEle {
  position: relative;
  overflow: hidden;
  top: -40px;
  left: -70px;
  width: 750px;
}

@media (max-width: 620px) {
  .randAnimContainer {
    height: 240px;
    width: 360px;
  }

  .randAnimEle {
    position: relative;
    overflow: hidden;
    top: -20px;
    left: -48px;
    width: 450px;
  }
}

.oneTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */

  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}
.howItWorksTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
}
.howToWorkMainTabRand {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
}
.letTxtRandTab {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  /* Light mode (dark grey) */

  color: #222726;
}
.letsMakePart {
  display: flex;
  padding-left: 142px;
  padding-top: 199px;
  padding-bottom: 267px;
}
.downArrow {
  padding-right: 43px;
}
.howSWorkTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* identical to box height, or 107% */

  text-align: center;

  /* Light mode (dark grey) */

  color: #222726;
}
.dropDownPointer {
  padding-top: 30px;
  z-index: 9;
}

.videorandomizer {
  /* width: 800%; */
  max-height: 562px;
  padding: 0px !important;
  border-radius: 20px;
  margin-top: -80px;
  max-width: 1000px;

  width: 90%;
  height: 49vw;
}

.howToWorkMainTabRand {
  width: 100%;
}

@media (max-width: 1400px) {
  .tabTxtRand {
    font-size: 16px;
  }
  .tabTxtRand1 {
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .letsMakePart {
    padding-left: 85px;
  }
  .tabTxtRand {
    font-size: 13px;
  }

  .tabTxtRand1 {
    font-size: 13px;
  }
  .randRightElements {
    padding-top: 50px;
  }
}
@media (max-width: 980px) {
  .bottomTxtRand {
    height: 3px;
  }
  .bottomTxtRand1 {
    height: 3px;
  }
  .tabTxtRand {
    font-size: 10px;
    margin-bottom: 6px;
  }

  .tabTxtRand1 {
    font-size: 10px;
    margin-bottom: 6px;
  }

  .pageRandDetail {
    padding-top: 40px;
  }
}

@media (max-width: 950px) {
  .letsMakePart {
    padding-left: 0px;
    padding-top: 147px;
    padding-bottom: 350px;
    justify-content: center;
  }
}
@media (max-width: 870px) {
  .txtMainTabRand {
    justify-content: center;
    align-items: center;
  }
  .tabDivLineTxt {
    width: 90%;
    padding-top: 10px;
    text-align: center;
  }
  .howToWorkMainTabRand {
    padding-top: 60px;
  }

  .tabDivLineFullTxt {
    display: none;
  }
  .tabTxtRand {
    font-size: 16px;
  }

  .tabTxtRand1 {
    font-size: 16px;
  }
}
@media (max-width: 619px) {
  .pageRandDetail {
    justify-content: center;
  }
  .mainParaPagRand {
    width: 80%;
    font-size: 14px;
  }
  .pageRandTxtHeading {
    font-size: 30px;
    line-height: 30px;
  }
  .oneTxt {
    margin-left: 5px;
    padding-right: 15px;
  }
  .letsMakePart {
    padding-left: 20px;
    padding-right: 20px;
  }
  .letTxtRandTab {
    font-size: 30px;
  }
  .tabTxtRand {
    font-size: 14px;
  }
  .tabTxtRand1 {
    font-size: 14px;
  }
}
