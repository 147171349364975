.topPrivacyPol {
}

.topPrivacyPol > .navbar {
  /* background: linear-gradient(0deg,#00A0A3,#D15F5B); */
  background-image: url("./../../images/headerBackgroudColor.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.containerPrivacyForWidth {
  max-width: 700px;
  padding-bottom: 50px;
  padding-right: 10px;
  padding-left: 10px;
}

.mainContainerPrivacyPolicy {
  display: flex;
  padding-top: 100px;

  flex-direction: column;
  align-items: center;
  width: 100%;
}

.privacyMainHeader {
  font-weight: 600;
  font-size: 32px;
  font-family: "Archia";
  font-style: normal;
  text-align: center;
  color: #222726;
}
.subHeadingPrivacyPolicy {
  font-weight: 400;
  font-size: 24px;
  font-family: "Archia";
  font-style: normal;

  color: #222726;
  padding-left: 10px;
}

.subSubHeadingPrivacyPol {
  font-weight: 400;
  font-size: 20px;
  font-family: "Archia";
  font-style: normal;

  color: #222726;
}
#privacyMainHeader {
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: "Archia";
  font-style: normal;
}
#privacyMainHeader1 {
  padding-bottom: 20px;
}
.paraTxtPrivacyPol {
  font-size: 14px;
  font-weight: 400;
  color: #222726;
  font-family: "Archia";
  font-style: normal;
}
@media (max-width: 991px) {
  .topPrivacyPol > .navbar {
    /* background: linear-gradient(0deg,#00A0A3,#D15F5B); */
    background-color: #222726 !important;
    background-image: none;
  }

  .topPrivacyPol > .navbar > .navbar-collapse {
    background-color: #222726 !important;
  }
}

@media (max-width: 600px) {
  .containerPrivacyForWidth {
    padding-right: 10px;

    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
