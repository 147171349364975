.headerCreativityMorphos {
  font-weight: 600;
}

.cutMorphosImg {
  width: 50%;
  max-width: 500px;
  margin-top: -30px;
}

.percentOff {
  position: absolute;
  margin-top: 120px;
  margin-left: 200px;
  color: #222726;
  font-family: "chillax";
  font-weight: 400;
  font-size: 18px;
}

.btnOrderNowMorphos {
  background-color: #ff4203;
  padding: 13px 31px 13px 31px;
  width: auto;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Archia";
  font-size: 1rem;
  font-weight: 400;
  margin-top: -50px;
  margin-bottom: 78px;
  transition: box-shadow 0.5s;
}

.morphoscrossed {
  text-decoration: line-through;
  color: #ff9e7e;
  margin: 5px;
}

.btnOrderNowMorphos:hover {
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.5s;
}

@media (max-width: 1280px) {
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityNorand {
    font-size: 102px;
  }
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityMorphos {
    font-size: 102px;
  }
}

@media (max-width: 1040px) {
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityNorand {
    font-size: 70px;
  }

  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityMorphos {
    font-size: 70px;
  }
  .cutMorphosImg {
    width: 60%;
    margin-top: -20px;
  }
}

@media (max-width: 749px) {
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityNorand {
    font-size: 50px;
  }

  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityMorphos {
    font-size: 50px;
  }

  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .btnOrderNow {
    margin-top: -49px;
  }
}

@media (max-width: 630px) {
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .p1Creativity {
    font-size: 20px;
  }
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityNorand {
    font-size: 40px;
  }
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityMorphos {
    font-size: 40px;
  }
  .cutMorphosImg {
    width: 70%;
    margin-top: -10px;
  }
}
@media (max-width: 547px) {
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .btnOrderNow {
    margin-top: -32px;
  }

  .percentOff {
    margin-left: 100px;
  }
}
@media (max-width: 448px) {
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .p1Creativity {
    font-size: 15px;
  }
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityNorand {
    font-size: 32px;
  }
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityMorphos {
    font-size: 32px;
  }
}

@media (max-width: 388px) {
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .headerCreativityMorphos {
    line-height: 60px;
  }
  .topMoPro3dMorphin
    > .creativityMonoContainer
    > .mainCreativity
    > .cutMonoImg {
    margin-top: -54px;
  }
}
