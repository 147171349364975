#navbarCenterContent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  align-items: flex-end;
  padding-right: 160px;
}

.navbar-backdrop {
  min-height: 60px;
}
.navbar-no-backdrop {
  min-height: 60px;
}

.hide-header {
  display: none;
}

.newPicHeader {
  position: absolute;
  margin-left: -10px;
}
.centerContentTxtHeader {
  font-weight: 400 !important;
  font-style: normal !important;
  line-height: 30px !important;
  font-size: 16px !important;
  color: #ffffff !important;
  padding-left: 30px !important;
  margin-top: 5px;
  margin-bottom: 5px;

  font-family: "Archia";
  transition: color 0.3s !important;
}
.centerContentTxtHeader:hover {
  color: #ff4203 !important;
  transition: color 0.3s !important;
}

.headerLogo {
  padding-left: 25px;
  padding-bottom: 5px;
}
.rightIconPartHeader {
  padding-right: 20px;
}
.navbar-dark .navbar-toggler {
  margin-right: 20px;
}

.navbar-no-backdrop {
  background-color: rgba(34, 39, 38, 0);
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  transition: all 0.5s ease-in-out;
}

.navbar-backdrop {
  background-color: rgba(34, 39, 38, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transition: all 0.5s ease-in-out;
}

@media (max-width: 991px) {
  .rightIconPartHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0px;
    padding-left: 25px;
    align-items: flex-end;
    padding-right: 77px;
  }
  .responsive-navbar-nav {
    background-color: rgba(34, 39, 38, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  .navbar {
    background-color: rgba(34, 39, 38, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  .hide-header {
    display: flex;
  }
}
