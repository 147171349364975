.mainContainerModuleContext {
  height: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  justify-content: space-around;
  position: relative;
  z-index: 1;
  background-color: white;
}
.mainImg {
  width: 800px;
}
.moduleTxt {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 24px;
  margin-bottom: 5px;
  /* or 112% */

  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}
.spTxtModule {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  /* or 112% */

  text-transform: capitalize;

  /* Light mode (dark grey) */

  color: #222726;
}
.firstRowHarmonic {
  text-align: right;
}

.parasFirstRowCont {
  text-align: right;
}
.topTextModulePic {
  display: flex;
  justify-content: space-between;
  padding-left: 62px;
  padding-top: 105px;
}
.bottomTextModulePic {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 131px;
}
.simDiscDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: -24px;
}

.discoverTxt {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #222726;
  margin-bottom: 0px;
}

.discoverTxt:hover {
  color: #ff4203;
}

.secondPartTxtModule {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  text-align: center;
}
.spSecondParaModule {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  text-align: center;
  color: #20ead6;
}
.secondPartDivModule {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 180px;
  max-width: 800px;
}
.moduleMainArrowImg {
  padding-top: 30px;
  padding-bottom: 60px;
}
.mainPicPartModule {
  position: relative;
  display: flex;
  justify-content: center;
}
.leftPurseHarmonicPic {
  position: absolute;
  top: -81px;
  left: 291px;
}
.rightPurseHarmonicPic {
  position: absolute;
  top: -82px;
  right: 114px;
}
.peepTopleftModuleImg {
  position: absolute;
  left: 10px;
}
.circleTopModule {
  position: absolute;
  /* top: 160px; */
  /* left: -46px; */
  left: -43px;

  top: 141px;
}
.peepTopRightModuleImg {
  position: absolute;
  left: 8px;
}
.circleTopModule2 {
  position: absolute;
  top: 277px;
  left: -44px;
}
.bottomPurseHarmonicPic {
  position: absolute;
  bottom: 112px;
  left: 95px;
}
.peepTBottomRightModuleImg {
  position: absolute;
  left: 8px;
}
.circleBottomModule2 {
  position: absolute;
  bottom: -17px;
  left: -45px;
}
.summaryPicModule {
  padding-top: 105px;
}

.bottomPeepCirclContMod {
  position: absolute;

  bottom: 93px;
  left: 348px;
}
.upperPeepBottomMod {
  position: absolute;
  top: 100px;
  left: 52px;
}
.topFirstRowConText {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 120px;
}
@media (max-width: 900px) {
  .secondPartDivModule {
    padding-top: 150px;
  }
  .secondPartTxtModule {
    font-size: 30px;
  }
  .spSecondParaModule {
    font-size: 30px;
  }
  .summaryPicModule {
    width: 100%;
  }
}
@media (max-width: 810px) {
  /* .circleBottomModule2 {
        position: absolute;
        bottom: -17px;
        left: -45px;
        width: 66px;
    } */
  .topTextModulePic {
    justify-content: center;
    display: flex;

    padding-top: 105px;
    padding-left: 0px;
    justify-content: space-evenly;
  }
  .bottomTextModulePic {
    display: flex;
    padding-left: 30px;
    padding-right: 40px;
    justify-content: space-around;
    /* justify-content: space-between; */
    /* width: 842px; */
  }
  .moduleTxt {
    font-size: 12px;
    line-height: 10px;
    margin-bottom: 0;
  }
  .discoverTxt {
    font-size: 10px;
  }
  .mainImg {
    width: 500px;
  }
  .spTxtModule {
    font-size: 12px;
  }

  .circleTopModule2 {
    top: 206px;
    left: -29px;
    width: 66px;
  }
  .rightPurseHarmonicPic {
    top: -84px;
    right: 66px;
  }
  .circleTopModule {
    width: 66px;
    left: -28px;
    top: 124px;
  }
  .peepTopleftModuleImg {
    height: 122px;
  }
  .moduleCircleCont3 {
    width: 66px;
  }
  .leftPurseHarmonicPic {
    top: -80px;
    left: 182px;
  }
  .peepTopRightModuleImg {
    height: 200px;
  }
  .circleBottomModule2 {
    bottom: -8px;
    left: -26px;
    width: 66px;
  }
  .peepTBottomRightModuleImg {
    height: 130px;
    left: 3px;
  }
  .bottomPurseHarmonicPic {
    bottom: 68px;
    left: 220px;
  }
  .upperPeepBottomMod {
    height: 130px;
    left: 3px;
  }
  .upperPeepBottomMod {
    top: 60px;
    left: 29px;
  }
  .bottomPeepCirclContMod {
    bottom: 60px;
    left: 20px;
  }
  .simDiscDiv {
  }
  .topImgMain {
    display: flex;
    justify-content: center;
  }
  .topFirstRowConText {
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-bottom: 20px;
    padding-right: 0px;
  }
  .topTextModulePic {
    margin-left: 0;
    margin-right: 0;
  }
  .bottomTextModulePic {
    padding-right: 0;
  }
}
@media (max-width: 650px) {
  .secondPartDivModule {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .secondPartDivModule {
    padding-top: 100px;
  }
}

@media (max-width: 511px) {
  .topFirstRowConText {
    padding-right: 50px;
  }

  .mainImg {
    object-fit: cover;
    width: 350px;
  }
  .circleBottomModule2 {
    width: 50px;
  }
  .circleBottomModule2 {
    left: -21px;
  }
  .bottomPurseHarmonicPic {
    bottom: 33px;
    left: 38px;
  }
  .peepTBottomRightModuleImg {
    height: 120px;
    left: 0px;
  }
  .peepTopleftModuleImg {
    height: 100px;
    left: 2px;
  }
  .circleTopModule {
    top: 102px;
    left: -19px;
  }
  .circleTopModule {
    width: 50px;
  }
  .leftPurseHarmonicPic {
    left: 137px;
  }
  .circleTopModule2 {
    width: 50px;
  }
  .circleTopModule2 {
    top: 167px;
    left: -19px;
  }
  .peepTopRightModuleImg {
    height: 165px;
    left: 2px;
  }
  .moduleCircleCont3 {
    width: 50px;
  }
  .upperPeepBottomMod {
    top: 47px;
    left: 20px;
  }
  .upperPeepBottomMod {
    height: 120px;
  }
  .bottomPeepCirclContMod {
    bottom: 34px;
    left: 140px;
  }
  .simDiscDiv {
    max-width: 180px;
    padding-left: 40px;
    margin-right: 0px;
  }
}

@media (max-width: 425px) {
  .secondPartTxtModule {
    font-size: 20px;
  }
  .spSecondParaModule {
    font-size: 20px;
  }
}
