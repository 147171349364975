.contextMorTxt1 {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */

  text-align: center;
  text-transform: capitalize;

  /* Dark mode (white) */

  color: #ffffff;
}
.contextModMorHeading {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 300;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Dark mode (white) */

  color: #ffffff;
}
.contextModMorHeadingSp {
  font-family: "Chillax";
  font-style: normal;
  font-weight: 600;
  font-size: 125px;
  line-height: 110px;
  /* or 88% */

  text-align: center;
  text-transform: uppercase;

  /* Dark mode (white) */

  color: #ffffff;
}
.mainParaTxtMorphCont {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #ffffff;
}
.mainParaTxtMorphContSp {
  font-family: "Archia";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Dark mode (white) */

  color: #ffffff;
}
.paraTxtCvInput {
  font-family: "Archia";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  /* or 107% */

  /* Dark mode (white) */

  color: #ffffff;
}
.txtButtLed {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Dark mode (white) */

  color: #ffffff;
}
.mainContainerContextualMorphos {
  background-color: #222726;
  height: auto;
}
.textPartConModMor {
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contextModMorHeading {
  padding-top: 57px;
}
.mainParaTxtMorphCont {
  padding-top: 60px;
  width: 610px;
}
.txtModParaMorPart {
  width: 550px;
  margin-left: 118px;
}
.picTextBottomConMod {
  display: flex;
  justify-content: center;
  padding-top: 160px;
  padding-bottom: 170px;
}
.txtButtLed {
  padding-top: 15px;
}
.dropArrowContMor {
  padding-top: 15px;
}

@media (max-width: 900px) {
  .textPartConModMor {
    padding-top: 80px;
  }

  .contextModMorHeading {
    font-size: 70px;
    line-height: 60px;
  }
  .contextModMorHeadingSp {
    font-size: 70px;
    line-height: 60px;
  }
  .mainParaTxtMorphCont {
    font-size: 12px;
  }
  .mainParaTxtMorphContSp {
    font-size: 12px;
  }
  .picTextBottomConMod {
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .txtModParaMorPart {
    padding-top: 60px;
    margin-left: 0px;
  }
}

@media (max-width: 647px) {
  .mainParaTxtMorphCont {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .txtModParaMorPart {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .paraTxtCvInput {
    text-align: center;
  }
  .txtButtLed {
    text-align: center;
  }
  .paraTxtCvInput {
    font-size: 30px;
    line-height: 35px;
  }
}
@media (max-width: 495px) {
  .contextModMorHeading {
    font-size: 45px;
    line-height: 55px;
  }
  .contextModMorHeadingSp {
    font-size: 45px;
    line-height: 55px;
  }
  .puamPicCont {
    width: 100%;
  }
}
