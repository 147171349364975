.mainIntroMorphingModule {
  width: 100%;
  background-color: #222726;
  display: flex;

  flex-direction: column;
  align-items: center;
  margin-bottom: -260px;
}

.lastAnimTriggerMorphos {
  height: 700px;
}

.animSubtext {
  font-family: "Archia";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}

.morphosAnimComponent {
  width: 1000px;
  margin-top: -200px;
  transform: translateX(-20%);
}

.hideOverflowMorphos {
  padding-top: 100px;
}

.morphosAnimContainer {
  margin-top: 250px;
  overflow: hidden;
}

.moprhosSvgContainer {
  position: absolute;
  overflow: hidden;
  left: 50%;
  transform: translateX(-75%);
}

@media (max-width: 1080px) {
  .mainIntroMorphingModule {
    margin-top: 50px;
    top: 150px;
  }

  .animSubtext {
    font-size: 14px;
    line-height: 18px;
  }
  .moprhosSvgContainer {
    margin-top: 240px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 700px) {
  .moprhosSvgContainer {
    margin-top: 100px;
  }
  .morphosAnimComponent {
    width: 500px;
    margin-top: 50px;
  }
  .morphosAnimContainer {
    transform: translateY(-40%);
    margin-top: 280px;
  }

  .animSubtext {
    font-size: 12px;
    line-height: 16px;
  }
}
